.modal-pms-mapping {
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $padding-base;
  }

  .fa-file-pdf {
    color: $na-primary;
    margin-right: $padding-base;
    font-size: 15px;
  }

  .btn-neutral-outline {
    transition: color 0.3s;

    .loader-wrapper svg .svg-spin-icon {
      fill: #ccc;
    }

    &:hover {
      color: $na-primary;
    }
  }
}

.pms-mapping-lists {
  display: flex;

  &__col {
    flex: 1;

    &-centered {
      table.list-table {
        tr {
          &:first-child {
            td {
              height: 45px;
            }
          }
        }

        td {
          border: none;
          padding: 10px 17px;
        }
      }

      .fa-link {
        transform: rotate(45deg);
        font-size: 17px;
      }
    }
  }

  table.list-table {
    border: none !important;
  }

  .list-header {
    height: 45px;
  }

  table.list-table tr td {
    padding: 10px 20px;
    height: 55px;
  }
}

.modal .modal-pms-mapping .modal-body .naTabs .tab-content {
  margin: 0;
}
