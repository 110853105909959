@font-face {
  font-family: 'ui-grid';
  src: url(../../../fonts/ui-grid.woff) format('woff'),
       url(../../../fonts/ui-grid.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'ui-grid';
    src: url('${font-path}ui-grid.svg?12312827#ui-grid') format('svg');
  }
}
*/

[class^="ui-grid-icon"]:before, [class*=" ui-grid-icon"]:before {
  font-family: "ui-grid";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.ui-grid-icon-blank::before {
  width: 1em;
  content: ' ';
}

.ui-grid-icon-plus-squared:before {
  content: '\c350';
}

.ui-grid-icon-minus-squared:before {
  content: '\c351';
}

.ui-grid-icon-search:before {
  content: '\c352';
}

.ui-grid-icon-cancel:before {
  content: '\c353';
}

.ui-grid-icon-info-circled:before {
  content: '\c354';
}

.ui-grid-icon-lock:before {
  content: '\c355';
}

.ui-grid-icon-lock-open:before {
  content: '\c356';
}

.ui-grid-icon-pencil:before {
  content: '\c357';
}

.ui-grid-icon-down-dir:before {
  content: '\c358';
}

.ui-grid-icon-up-dir:before {
  content: '\c359';
}

.ui-grid-icon-left-dir:before {
  content: '\c35a';
}

.ui-grid-icon-right-dir:before {
  content: '\c35b';
}

.ui-grid-icon-left-open:before {
  content: '\c35c';
}

.ui-grid-icon-right-open:before {
  content: '\c35d';
}

.ui-grid-icon-angle-down:before {
  content: '\c35e';
}

.ui-grid-icon-filter:before {
  content: '\c35f';
}

.ui-grid-icon-sort-alt-up:before {
  content: '\c360';
}

.ui-grid-icon-sort-alt-down:before {
  content: '\c361';
}

.ui-grid-icon-ok:before {
  content: '\c362';
}

.ui-grid-icon-menu:before {
  content: '\c363';
}

.ui-grid-icon-indent-left:before {
  content: '\e800';
}

.ui-grid-icon-indent-right:before {
  content: '\e801';
}

.ui-grid-icon-adjust-hue5:before {
  content: '\ea61';
}
