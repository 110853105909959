@media print {

  booking-show-template-v2,
  voucher-order-report-view-template-v2,
  voucher-report-view-template-v2 {
    .booking-detail--wrapper {
      .panel {
        margin-bottom: 10px;
      }
    }

    .booking-detail--subheader {
      * {
        margin: 0 !important;
        padding: 0 !important;
      }

      .col {
        padding: 10px;

        &:nth-child(2) {
          margin: 0 5px !important;
        }

        .row {
          display: flex;
          padding: 5px 0;

          &:first-child {
            padding-bottom: 5px !important;
          }

          &:last-child {
            padding-top: 5px !important;
          }

          > div:first-child {
            flex: 1;
          }

          .col-md-8 {
            width: 60%;
            padding: 0;
          }

          .col-md-4 {
            width: 40%;
            text-align: right;
            padding: 0;
          }
        }
      }
    }

    .booking-detail--header {
      margin-bottom: 5px;
      margin-top: -30px;
      padding: 5px;

      .row {
        display: flex;
        align-items: center;
        margin: 10px !important;

        .col {
          & > * {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }

      hr {
        margin: 0;
      }

      .panel-footer {
        padding: 0 5px;
      }
    }

    .booking-detail--info {
      position: relative;

      na-inline-notification {
        position: absolute;
        top: 10px;
        right: 0;
      }

      > .col-sm-4 {
        display: inline-block;
        vertical-align: top;
        width: 48%;
        padding: 0;
      }

      .payment-card-holder {
        width: 100% !important;
        border-bottom: 1px solid #000;
      }

      .row {
        display: inline-flex;
        align-items: center;
        width: 100%;
        border-radius: 8px;
        padding: 3px;
        margin: 0 15px !important;

        .col-sm-4 {
          padding: 0;
          width: 35%;
        }

        .col-sm-8 {
          width: 65%;
          font-style: italic;
        }
      }

      [class*='col-'] {
        position: static;
      }

      .card-design {
        width: 100%;
        height: auto;
        padding: 10px;
        text-align: left;
        margin: 10px 15px 0;
        display: flex;
        align-items: center;
        overflow: visible;

        &__details {
          display: none;
        }

        &__number,
        &__owner,
        &__expiry {
          position: static;
          white-space: nowrap;
        }

        &__owner {
          display: flex;
          align-items: center;
          padding-right: 20px;
          margin-top: -5px;
        }

        &__number-inner {
          font-size: 16px;
        }

        &__dots {
          font-size: 30px;
          letter-spacing: -2px;
        }

        &__expiry {
          display: flex;
          align-items: center;
          padding-left: 20px;
          margin-top: -5px;

          span {
            padding-right: 10px;
            font-size: 14px !important;
          }
        }
      }
    }

    na-dd-menu,
    .fa-pen-to-square,
    .card-design__flag,
    .card-design__logo,
    p:empty,
    .dropempty,
    .package a,
    #notesAL > .col-sm-12 .row,
    .h-space,
    na-text-page,
    na-button-list,
    .booking-detail__terms-conditions > h2 {
      display: none;
    }

    .booking-detail--info h2,
    .booking-detail--items > h2,
    .booking-detail--items ~ div > h2,
     na-section div > h2,
    .booking-detail__terms-conditions-for-print h2 {
      margin: 15px 0;
      padding: 10px 0 5px;
      font-size: 18px !important;
      text-decoration: underline;
    }

    na-section {
      margin-bottom: 0;

      .na-section__title h2 {
        margin: 0 0 10px !important;
        padding: 0 !important;
      }

      .na-section__content {
        .col-sm-12 {
          padding: 0;
        }
      }
    }

    h5 {
      padding: 0;
    }

    .table {
      margin-bottom: 5px;
    }

    .list-table td {
      padding: 3px 10px !important;
    }

    .h-space-big {
      margin: 5px 0;
    }

    a[href]:after {
      content: none;
    }

    .panel-default {
      padding: 10px !important;
    }

    .booking-detail--items {
      .panel.panel-default .label {
        padding: 5px;
        border-color: #f5f5f5;
        border-width: 0 0 1px;
        margin: 0;
      }

      hr {
        margin: 5px 0;
      }
    }

    .booking-detail__extra {
      border: 1px solid #f5f5f5 !important;
      padding: 10px;
      margin: 0 4px;
      width: calc(33.3% - 8px);
    }

    .booking-detail__terms-conditions-for-print {
      display: block !important;
      margin-bottom: 10px;

      p {
        padding-bottom: 0;
        margin-bottom: 0;
      }

      br {
        display: none;
      }

      h2 {
        margin-bottom: 0;
      }
    }
  }

  voucher-report-view-template-v2 {
    .voucher-details__to,
    .voucher-details__from,
    .voucher-details__message {
      .box-shadow {
        padding: 0;
      }

      label {
        padding: 5px;
      }
    }

    .voucher-details__message {
      margin-top: 10px;
    }
  }

  voucher-order-report-view-template-v2 {
    .booking-detail--items {
      .panel-default {
        .row:first-child {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &::before,
          &::after {
            content: none;
          }

          .price {
            margin-top: -5px;

            > * {
              margin: 0 5px;
              padding: 0;
            }
          }
        }

        label {
          margin: 4px 0;
        }

        hr {
          margin: 10px 0;
        }
      }
    }
  }

  .modal-dialog {
    visibility: hidden !important;
  }
}