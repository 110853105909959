

.md-chips,
md-chips {
  display: flex;
  min-width: 100%;
}

md-chips {
  &.na-invalid.ng-dirty {
    .md-chips {
      border-color: #dd2c00;
    }
  }
}

.md-chips {
  font-size: 14px;
  padding: 0 0 4px 8px;
  font-family: 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif;
  box-shadow: none !important;
  border: 1px solid #ccc;
  width: 97%;
  border-radius: 3px;
  flex-grow: initial;
  transition: .3s;

  &.md-focused {
    border-color: #000;
  }

  .md-chip-input-container {
    margin-top: 4px;
  }
}

.md-chips md-chip {
  background: #e0e0e0;
  margin-bottom: 5px;
}

.chips {
  &__tip-holder {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: baseline;
    margin: 2px 0 9px;
  }

  &__tip {
    justify-self: end;
    color: #757575;
  }

  &__error {
    color: #DB5D59;
    font-size: 13px;
    opacity: 0; 
    margin-top: -5px;  
    transition: .2s; 

    &.visible {
      opacity: 1;
      margin-top: 0;
    }
  }
}