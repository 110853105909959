//log out modal

.loading-modal.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;

  .loader-icon {
    margin: 0;
  }

  > .modal-body {
    p {
      text-align: center;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .loader-wrapper {
      position: relative;
      margin: 0;
    }
  }
}
