.advance-list-button {
  padding-right: 0;
}

.list-action {
  margin-bottom: $spacing-base;

  @include respond-to(xsmall) {
    .btn {
      margin-bottom: 10px;
    }
  }
}

na-header-list {
	.list-header {
		>.row {
			margin: 0 !important;
		}
	}
}


.list-header {
  background-color: #EBEBEB;
  padding: $spacing-base*1.4 $spacing-base*2;
  margin-top: -1px;
  position: relative;
  bottom: -1px;

  $icon-size: 14px;
  $icon-padding: 0;

  .btn-sort {
    text-transform: uppercase;
    font-size: 11px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    top: -2px;

    .label {
      color: $text-color;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0;
      margin-right: 5px;
      position: relative;
      top: 0;

      strong {
        font-size: 11px;
      }
    }

    i {
      font-size: 16px;
      margin-right: 12px;
      vertical-align: text-bottom;
      line-height: $icon-size;
      position: relative;
      top: 2px;
    }
  }

  .list-config {
    i {
      margin-left: 5px;
      font-size: 1.2em;
      cursor: pointer;

			&:hover {
				@include theme-color;
			}
		}
	}

	.list-config-filters {
		display: inline-block;
		position: relative;
		top: 1px;


		i {
			display: inline-block;
			font-size: $icon-size;
			line-height: $icon-size;
			padding: 10px 5px;
			margin: -10px -5px;
		}

		.filter-counter {
			right: -7px;
			top: -5px;
			position: absolute;
			display: inline-block;
			width: 15px;
			line-height: 14px;
			height: 15px;
			background-color: $brand-primary;
			color: #fff;
			border-radius: 50%;
			text-align: center;
			font-size: 8px;
			font-weight: bold;
			padding: 0 2px 0 0;
			pointer-events: none;
			@include theme-bg;
		}
	}

	.list-config-search {
		display: inline-block;
		position: relative;
		width: 35%;

		i {
			display: inline-block;
			font-size: $icon-size;
			line-height: $icon-size;
			padding: 10px;
			margin: -10px;
			margin-right: 5px;
		}

		&.open {
			.input-search {
				width: 0;
				opacity: 0;
			}
		}

		.input-search {
			background-color: darken($gray-lighter, 4%);
			position: absolute;
			top: -10px;
			right: 45px;
			font-size: 1em;
			border: 1px solid transparent;
			border-top: 1px solid $gray-lighter;
			border-bottom: 1px solid $gray-lighter;
			line-height: 39px;
			padding: 0 11px;
			width: 100%;
			opacity: 1;
			height: 40px;
			border-radius: $border-radius;

			@include anim;

			&:focus {
				outline: none;
				background-color: #fff;
			}
		}
	}
}

.list-filter {
  margin-top: 1px;
  background-color: $gray-lighter;

  @include anim;

  position: relative;

  &:before {
    content: '';
    top: 0;
    left: 0;
    display: block;
    right: 0;
    height: 6px;
    position: absolute;

    @include gradient-ver(#000, 0.08, #000, 0); }

  > .row {
    padding-top: $spacing-base*1.4 !important;
    padding-bottom: $spacing-base*1.4 !important;
  }

  .list-filter-col {
    float: left;
    margin-right: 10%;

    li {
      // prevent jumping filters when click reset
      min-height: 30px;
      min-width: 82px;
    }
  }

  label {
    font-size: 0.8em;

    &.filter-label {
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-size: 11px;
    }
  }

  ul {
    margin: 0;

    li {
      label {
        font-weight: normal;
        font-size: 0.9em;
      }
    }
  }
}
