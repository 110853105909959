
/* headlines with lines */
body .decorated {
  text-align: left;
  opacity: 1;
  font-size: 0.9em;
  color: #000;
  position: relative;
  z-index: 0;
  font-weight: bold;
}

.decorated > span {
  display: inline-block;
  background: #fff;
  padding-right: 7px;
  z-index: 1000000;
}

na-section {
  display: block;
  margin-bottom: 25px;
}
