$iconSize: 4vw;

@keyframes float {
  0% {
    position: relative;
    top: 0;
  }

  50% {
    position: relative;
    top: -10px;
  }

  100% {
    position: relative;
    top: 0;
  }
}

na-doc {
  display: block;
  cursor: pointer;
}

#mediaLibrary {
  @include respond-to(fromxsmall) {
    position: relative;
    z-index: 1;
  }

  .na-media-library__header {
    .breadcrumbs {
      position: relative;
      bottom: -9px;
    }
  }

  na-item-collection {
    display: block;
    clear: both;
    padding-top: 15px;
  }

  .na-breadcrumb {
    li {
      display: inline-block;
      font-weight: bold;

      a {
        font-weight: normal;

        &:after {
          font: var(--fa-font-regular);
          content: '\f105';
          color: $gray;
          margin: 4px 7px;
          font-size: 14px;
          vertical-align: bottom;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  na-item,
  .na-item {
    width: 100%;
    display: inline-block;
    margin-right: 17px;
    position: relative;
    margin-bottom: 25px;
    overflow: hidden;
    border: 1px solid $border-color;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;

    @include anim;

    &:hover {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      border: 1px solid #ccc;

      .info {
        border-top: 1px solid #ccc;
      }

      i {
        opacity: 0.3;
      }
    }

    > div {
      border-radius: 2px;
      border: 1px solid transparent;
      cursor: pointer;

      @include anim;

      &:before,
      &:after {
        @include anim-custom(all, 100ms, ease);
      }

      &:before {
        content: 'a';
        position: absolute;
        left: -46px;
        top: -48px;
        width: 55px;
        height: 55px;
        background-color: $na-tertiary;
        transform: rotate(45deg);
        z-index: 0;
      }

      &:after {
        font: var(--fa-font-regular);
        content: '\f00c';
        position: absolute;
        left: -16px;
        top: -17px;
        font-size: 16px;
        width: 20px;
        height: 20px;
        z-index: 10;

        @include theme-color;
      }

      &.itemSelected {
        border: 1px solid transparent;
        border-color: $na-tertiary;

        &:before {
          left: -29px;
          top: -29px;
        }

        &:after {
          left: 3px;
          top: 2px;
        }
      }
    }

    .loader-wrapper {
      width: 100%;
      bottom: 50% !important;
    }

    .image-dimensions {
      font-size: 9px;
      top: 0;
      position: absolute;
      left: 38%;
    }

    .thumbnail {
      border: 1px solid red;
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
    }

    .item-thumbnail {
      color: inherit;
    }

    input {
      padding: 10px;
      height: inherit;
      border-width: 1px;
      border-style: solid;
      border-color: $na-primary !important;
      color: $na-primary;
      border-radius: 0 0 3px 3px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      right: 0;

      &::selection {
        @include theme-bg;

        color: #fff;
      }
    }

    > div,
    .item-body {

      img {
        margin: 0;
        position: absolute;
        top: 38%;
        right: 50%;
        transform: translate(50%, -50%);
        max-width: 140px;
      }

      i {
        font-size: $iconSize;
        width: 100%;
        text-align: center;
        opacity: 0.2;
        display: block;
        position: absolute;
        top: 20%;

        @include anim;

        .na-media-library__popup & {
          font-size: 44px;
        }
      }

      .item-footer,
      .info {
        width: 100%;
        display: block;
        margin: 0;
        padding: 10px;
        position: relative;
        border-top: 1px solid $border-color;
        cursor: default;

        .title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 11px);
          display: block;
          text-align: left;
        }

        .count {
          position: absolute;
          top: 10px;
          right: 15px;
          font-weight: bold;
        }
      }
    }
  }

  na-doc,
  na-folder,
  .item-body,
  .item-newfolder {

    > div,
    .item-thumb {
      height: 0;
      padding-bottom: 66%;
      display: block;

      @include respond-to(fromxsmall) {
        padding-bottom: 76%;
      }
    }
  }

  .item-newfolder {
    .item-footer {
      min-height: 40px;
      padding: 10px;

      .title {
        position: relative;
        top: -39px;
        text-transform: uppercase;
        font-weight: bold;
        opacity: 0.4;
      }
    }
  }

  .na-media-upload {
    padding: 3% 0% 5%;
    border: 1px dashed darken($border-color, 50%);
    display: block;
    margin-bottom: $spacing-base*3 !important;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;

    @include anim;

    .na-media-upload__msg {
      h2 {
        font-size: 26px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 8px;
        padding: 0;
      }

      span {
        font-size: 12px;
      }

      i {
        display: block;
        clear: both;
        font-size: 40px;
        opacity: 0.5;
      }
    }

    .na-media-upload__input {
      margin-top: $spacing-base*2;

      label {
        cursor: pointer;
      }

      input {
        display: none;
      }
    }

    &.dragover {
      background-color: #fff;
      border-color: #000;
      box-shadow: inset 0 0 15px 6px rgba(0, 0, 0, 0.08);

      .na-media-upload__msg {
        i {
          opacity: 1;
          animation: float 2s ease infinite;

          @include theme-color;
        }
      }
    }
  }
}

// media library

.na-media-library__popup {
  padding: $spacing-base*3;
  min-height: 150px;

  @include respond-to(xsmall) {
    padding: $spacing-base;
  }
}

.deleteFile {
  font-size: 20px;
}

//hide extra space for company logo media library input
.company__logo {
  na-media-library-popup {
    .na-media-library-popup__input + .row {
      display: none;
    }
  }
}

span .item-thumbnail > img {
  margin-bottom: 22px;
}
