.tiles {
  $tile-footer-height: 40px;

  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: -25px 0 0 -10px;
  color: #000 !important;

  .tiles-item {
    background: white;

    @include anim;

    transform: translate(0, 0);
    border: 1px solid $gray-light;
    border-radius: $border-radius*2;
    cursor: pointer;
    display: inline-block;
    margin: 25px 0 0 25px;
    flex-grow: 1;
    height: 250px;
    width: calc(100% * (1/4) - 25px - 1px);
    max-width: calc(100% * (1/4) - 25px - 1px);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0);
    z-index: 1;

    &:hover {
      transform: translate(0, -3px);
      border: 1px solid darken($gray-light, 10%);
      box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.1);
    }

    .label-new {
      text-align: center;
      padding: 7px 15px 6px;
      margin: 0 auto;
      display: inline-block;
      color: #000;
      overflow: hidden;
      width: auto;
      background-color: $na-primary;
      border-radius: 20px;
      font-weight: 600;
      line-height: 11px;
      font-size: 11px;

      &.delete {
        background-color: $gray !important;
      }
    }

    .tiles-main {
      position: absolute;
      top: 0;
      bottom: $tile-footer-height;
      width: 100%;
      padding: $spacing-base*2 $spacing-base $spacing-base;
      text-align: center;
      border-bottom: 1px solid $gray-light;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    .tiles-system {
      display: block;
      font-size: 26px;
      line-height: 1;
      font-weight: 600;
      color: #000;
      width: 100%;

      &:after {
        @include anim;

        margin: $spacing-base auto $spacing-base*0.5;
        height: 3px;
        display: block;
        background-color: $na-primary;
        width: 25px;
        position: relative;
        content: '';
      }
    }

    .tiles-systemcode {
      display: block;
      font-size: 16px;
      line-height: 2;
      font-weight: 600;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .tiles-footer {
      position: absolute;
      bottom: 0;
      width: 100%;

      > div {
        width: 45%;
        float: left;
        padding: 10px 12px;
        height: $tile-footer-height;

        uib-tooltip-popup {
          z-index: 99999999 !important;
        }
      }

      .tiles-company {
        width: 55%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
      }

      img {
        width: 100%;
        max-width: 70px;
        height: auto;
      }

      .tiles-dots {
        @include anim;

        font-size: 27px;
        opacity: 0.5;
        position: relative;
        top: -8px;

        &:hover {
          opacity: 1;
        }
      }
    }

    .tiles-expiry {
      position: absolute;
      bottom: 9px;
      left: 0;
      right: 0;
      font-size: 12px;
      opacity: 0.6;
      text-align: center;
      padding: 0 3px;
    }

    @media (max-width: 1280px) {
      width: calc(100% * (1/3) - 25px - 1px);
      max-width: calc(100% * (1/3) - 25px - 1px);
    }

    @media (max-width: 920px) {
      width: calc(100% * (1/2) - 25px - 1px);
      max-width: calc(100% * (1/2) - 25px - 1px);
    }

    @media (max-width: 768px) {
      width: calc(100% * (1/3) - 25px - 1px);
      max-width: calc(100% * (1/3) - 25px - 1px);
    }

    &.tiles-add {
      background-color: $gray-lighter;

      .tiles-add-label {
        position: absolute;
        top: 50%;
        font-size: 16px;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: -50px;
        opacity: 0.6;

        &:before {
          content: '\f067';
          font: var(--fa-font-regular);
          font-weight: normal;
          font-size: 50px;
          clear: both;
          display: block;
        }
      }
    }
  }
}
