body {
  &.blankpage {
    #content {
      margin: 0;
      padding-top: 45px;
    }

    .content-inner {
      min-height: 100vh;
    }
  }

  &.content-narrow {
    @include respond-to(fromxsmall) {
      width: 100% !important;
      overflow: hidden;
    }
  }
}

#container__master {
  @include respond-to(fromxsmall) {
    padding: 50px 15px 25px !important;
    background-color: #fff;
  }
}

.header-actions {
  @include theme-border-menu-top;
  @include anim(200ms, ease);

  position: fixed;
  height: $sidebar-top;
  padding: 13px 100px 13px 19px;
  z-index: 104;
  right: 0;
  top: 0;
  left: $sidebar-medium;
  margin-right: 0;
  display: flex;
  justify-content: space-between;
  background: #fff;

  @include respond-to(fromxsmall) {
    padding: 0;
    border: none;
    position: relative;
    top: auto;
    left: 0;
    margin: 0 -15px;
    height: auto;
    flex-direction: column-reverse;
  }

  @media (min-width: 992px) {
    box-shadow: 3px 0 6px rgba(0, 0, 0, 0.15);
  }

  .header-actions__buttons {
    @include respond-to(fromxsmall) {
      box-shadow: 3px 0 6px rgba(0, 0, 0, 0.15);
    }
  }

  > na-app-breadcrumbs {
    flex: none;
    margin-right: $spacing-base*2;
    position: absolute;
    margin-left: 1px;

    @include respond-to(fromxsmall) {
      position: static;
    }

    na-breadcrumbs {
      @include respond-to(fromxsmall) {
        display: block;
        padding: 10px 0 30px;

        ol li {
          margin: 5px 0 0;

          &:first-child {
            margin-left: 15px;
          }
        }
      }

      @include respond-to (landscape) {
        padding: 10px 0;
      }
    }
  }

  > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > na-container-search-bar {
      flex: 1 0 300px;

      @include respond-to(fromxsmall) {
        flex: auto;
      }
    }
  }
}

#click-overlay {
  display: none;
  z-index: 800;

  @include anim(200ms, ease);

  @media (max-width: 768px) {
    display: block;

    &.ng-enter,
    &.ng-leave {
      transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
    }

    &.ng-leave,
    &.ng-enter.ng-enter-active {
      opacity: 1;
    }

    &.ng-enter,
    &.ng-leave.ng-leave-active {
      opacity: 0;
    }
  }
}

/* Top Bar - mobile/tablet*/
#topbar {
  display: none;
  height: $sidebar-top;
  background: white;
  border-bottom: 1px solid darken($gray-lighter, 10%);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  @include respond-to(fromxsmall) {
    z-index: 902;
    display: block;
  }

  .ico-menu {
    padding: 18px 16px 10px;
    display: inline-block;
    cursor: pointer;

    i {
      font-size: 17px;
    }
  }
}

.page-title {
  display: none;
}

//Cookie Message
$sidebars: $sidebar-medium + $sidebar-small;

div.cc-window {
  width: 100%;
  bottom: 10px !important;
  z-index: 999999;
  background: #3D3D3D;
  margin: 0 0 0 #{$sidebar-medium + $spacing-base*2};
  max-width: calc(100% - #{$sidebar-medium + $spacing-base*4}) !important;
  flex-direction: row !important;
  justify-content: space-between;
  border-radius: 0 !important;
  align-items: center;
  font-family: $font-family-sans-serif;
  font-size: 13px;
  transition: 300ms ease;

  @include respond-to(fromxsmall) {
    margin: 0;
    max-width: none !important;
  }

  @media (max-width: 1024px) {
    // max-width: calc(100% - #{$sidebar-medium + $spacing-base*4}) !important;
  }

  a.cc-btn {
    @extend .btn;
    @extend .btn-secondary;

    body & {
      &:hover {
        background-color: $na-secondary-darker !important;
        border-color: $na-secondary-darker !important;
      }
    }
  }

  body.content-narrow & {
    width: calc(100% - #{$sidebars + $spacing-base*4});
    max-width: calc(100% - #{$sidebars + $spacing-base*4}) !important;
  }

  body.has-actionbar & {
    bottom: 100px !important;
  }

  .cc-message {
    margin: 0;
  }

  .cc-link {
    &:hover {
      color: white;
    }
  }
}

.cc-revoke.cc-bottom {
  transform: translateY(200%) !important;
  opacity: 0;
  visibility: hidden;
}

.cookie-privacy-modal {
  .modal-header {
    position: relative;

    .modal-title {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 18px;
      color: #3d3d3d;
      font-size: 15px;
      font-weight: 700;

      i {
        cursor: pointer;
        font-size: 20px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 100%;
        border-left: 1px solid #ddd;

        &:before {
          content: "✖";
        }
      }
    }
  }

  .modal-body {
    padding: $spacing-base*2;
    color: #43515E;
    font-size: 13px;

    p {
      padding: 0;
    }

    h3 {
      font-size: 15px;
    }
  }
}

.margin-modal {
  margin-top: 100px;
}

.dark-backdrop {
  background: black;
}

#sidebar {
  width: $sidebar-medium;
  font-size: 14px;
  background: $sidebar-background;
  position: fixed;
  padding: 0;
  top: 0;
  bottom: 0;
  border-right: 1px solid $gray-border;
  box-shadow: 0 $sidebar-top 6px rgba(0, 0, 0, 0.15);
  z-index: 850;
  margin: 0;

  @include anim(200ms, ease);

  @media (max-width: 992px) {
    left: -$sidebar-width-mobile;
    z-index: 903;
    width: $sidebar-width-mobile;

    &.leftOn {
      left: 0;
    }
  }

  .na-brand {
    height: $sidebar-bottom;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $gray-lighter;
    text-align: center;
    overflow: hidden;

    @include anim(400ms, cubic-bezier(0.19, 1, 0.22, 1));

    &:hover {
      height: 70px;

      svg {
        opacity: 1;

        path {
          fill: $gray;
        }

        .netaffinity,
        .love-hotels {
          opacity: 1;
        }

        .netaffinity {
          path {
            fill: #383838;
          }
        }

        .symbol {
          path {
            fill: #FFD216;
          }
        }

        .love-hotels {
          path {
            fill: #383838;
          }
        }
      }

      &:after {
        left: 320px;
      }
    }

    &:after {
      @include anim(800ms, ease);

      width: 50px;
      height: 160px;
      content: " ";
      background-color: rgba(255, 255, 255, 0.6);
      left: -60px;
      top: -25px;
      position: absolute;
      transform: rotate(-25deg);
    }

    svg {
      margin: 14px auto;
      display: block;
      width: 85px;
      height: 41px;
      opacity: 0.5;

      @include anim(300ms, ease);

      path {
        fill: $gray;

        @include anim(300ms, ease);
      }

      .netaffinity {
        @include anim(1000ms, ease);

        opacity: 0;
      }

      .love-hotels {
        @include anim(2000ms, ease);

        opacity: 0;
      }
    }
  }
}

/* Sidebar */
.cookie-privacy {
  width: 100%;
  z-index: 3;
  height: $sidebar-bottom;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: $gray-lighter;
  display: flex;
  align-items: center;
  justify-content: center;

  .cookie-privacy__link {
    color: $gray-icon !important;
    font-size: 11px;
    cursor: pointer;

    &:not(:last-child) {
      &:after {
        content: "•";
        margin: 0 10px;
        font-size: 18px !important;
        position: relative;
        line-height: 0;
        top: 3px;
        color: $gray-icon !important;
      }
    }

    &:hover,
    &:active,
    &:focus {
      font-weight: normal !important;
      color: darken($gray-icon, 4) !important;
      text-decoration: none;

      &:after {
        color: $gray-icon !important;
      }
    }
  }
}

/* Table content inside modals DivTable.com */
.divTable {
  display: table;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #3d3d3d;
}

.divTableRow {
  display: table-row;

  &:last-child .divTableCell {
    border-bottom-width: 1px;
  }
}

.divTableCell {
  border: 1px solid $border-color;
  border-collapse: collapse;
  display: table-cell;
  padding: 10px;
  border-width: 1px 1px 0 0;

  &:first-child {
    border-left-width: 1px;
  }

  &.divTableHeader {
    font-weight: bold;
  }
}

.divTableBody {
  display: table-row-group;
}

#content {
  margin-left: $sidebar-medium;
  position: relative;
  z-index: 900;

  @include anim(300ms, cubic-bezier(0.19, 1, 0.22, 1));
  background-color: #fff;

  @include respond-to(fromxsmall) {
    margin-left: 0;
  }

  //max size on the main content area
  @media (min-width: 1324px) {
    .content-inner {
      max-width: $container-max-width;
      position: relative;
      margin: 0 auto;
    }
  }

  //with both sidebar open
  &.content-narrow {
    margin-right: $sidebar-small;

    @media (max-width: 1280px) {
      margin-right: $sidebar-small;
    }

    @media (max-width: 767px) {
      margin-right: 0;
    }

    @media (min-width: 991px) {
      .header-actions {
        margin-right: $sidebar-small;

        @include anim(400ms, cubic-bezier(0.19, 1, 0.22, 1));

        padding-right: $spacing-base*2;
      }
    }
  }

  .language-system {
    padding: 0 20px;
    z-index: 1025;
    line-height: 2;
    color: black;
    float: right;
    position: relative;
    font-size: $font-size-sm;
  }

  .view-widget:before {
    content: "\f06e";
    display: inline-block;
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    position: relative;
    top: 1px;
    width: auto;
    font-size: 15px;
    margin-right: $spacing-base;

    @include respond-to(fromxsmall) {
      font-size: 13px;
      margin-right: 5px;
    }
  }

  a.view-widget {
    text-decoration: none;
    font-size: $font-size-sm !important;
    padding: $spacing-base 15px;
    border-radius: 5px;
    margin: 0 $spacing-base 0 15px;
    position: relative;
    color: #000;
    border: 1px solid #dcdcdc;
    transition: 300ms ease;
    white-space: nowrap;

    @include respond-to(fromxsmall) {
      font-size: $font-size-sm !important;
      padding: 8px;
      margin: $spacing-base $spacing-base+5 $spacing-base $spacing-base;
    }
  }

  a.view-widget:hover {
    color: $primary-color;
  }

  .content-inner {
    padding: 85px $spacing-base*4 120px;
    min-height: 90vh;

    @include respond-to(fromxsmall) {
      padding: 0;
    }

    body.login & {
      padding-top: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    body.has-actionbar & {
      padding-bottom: $spacing-base*17;
    }

    > h1,
    > h2 {
      margin-bottom: $spacing-base;

      @media (max-width: 768px) {
        position: fixed;
        top: 0;
        z-index: 100;
        left: 50px;
        pointer-events: none;
        font-size: 18px;
        font-weight: 400 !important;
        padding-top: 13px;
      }
    }
  }

  .topbar {
    padding: 0;
    height: 55px;
    position: fixed;
    right: 0;
    z-index: 0;
    width: 98%;
    pointer-events: none;
  }

  .panel {
    .panel-heading {
      background-color: $sidebar-background;

      h2,
      h3 {
        padding: 0;
        margin: 0;
        font-weight: 400;
      }
    }
  }

  @media (max-width: 768px) {
    .main-title {
      position: fixed;
      top: 0;
      z-index: 100;
      left: 30px;
      pointer-events: none;

      h1 {
        font-size: 18px;
        font-weight: 400 !important;
      }
    }
  }
}

.top-controls {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  width: $sidebar-small;
  height: $sidebar-top;

  @include anim(400ms, cubic-bezier(0.19, 1, 0.22, 1));
  @include theme-border-menu-top;

  @include respond-to(fromxsmall) {
    padding: 13px 0;
  }

  &.null {
    right: -$sidebar-medium + 105;
    width: $sidebar-medium;
    border: 0;

    @include respond-to(fromxsmall) {
      width: 265px;
      right: -$sidebar-medium + 112;
    }
  }

  .item-control {
    float: left;
    font-size: 17px;
    line-height: 1em;
    height: $sidebar-top;
    width: 53px;
    padding: 16px;
    box-sizing: border-box;
    cursor: pointer;
    pointer-events: auto;
    display: inline-block;

    @include anim(300ms, ease);

    text-align: center;
    border-bottom: 2px solid transparent !important;
    color: black;

    @include respond-to(fromxsmall) {
      width: auto;
      height: auto;
      padding: 5px 10px;
    }

    &:hover {
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);

      @include theme-color;
    }

    i {
      @include anim(300ms, ease);
    }

    .top-controls.null & {
      opacity: 1 !important;
    }

    &.selectedPanel {
      border-color: $na-primary;
      position: relative;
      opacity: 1;

      @include theme-border;
    }

    &.notify {
      span.notify-count {
        border-radius: 50% !important;
        background-color: $secondary-color;
        display: block;
        color: #fff;
        position: absolute;
        left: 28px;
        top: 26px;
        width: 16px;
        height: 16px;
        z-index: 10;
        text-align: center;
        font-size: 10px;
        font-weight: bold;
        line-height: 1.6em;

        @include theme-bg;
      }
    }
  }

  .btn-close-panel {
    padding: 13px 16px;
    font-size: 17px;
    position: relative;
    top: 2px;
    left: 0;
    cursor: pointer;
    opacity: 0.5;

    @include anim(300ms, ease);

    pointer-events: auto;
    color: black;

    &:hover {
      color: $na-primary;
      opacity: 1;
    }

    @include respond-to(fromxsmall) {
      padding: 0 10px;
    }
  }
}

.slide-sidebar-wrapper {
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  pointer-events: none;
  z-index: 950;

  &.null {
    z-index: 903;
  }

  .slide-sidebar {
    pointer-events: auto;
    width: $sidebar-small;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: $sidebar-background;
    position: absolute;
    box-shadow: 0 $sidebar-top 6px  rgba(0, 0, 0, 0.15);
    border-left: 1px solid $gray-border;

    @include anim(400ms, cubic-bezier(0.19, 1, 0.22, 1));

    &.null {
      right: -#{$sidebar-small+15} !important;

      @include anim(400ms, cubic-bezier(0.19, 1, 0.22, 1));
    }

    .panel {
      position: absolute;
      top: 44px;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      background: none !important;
      pointer-events: none;
      margin: 0;

      &.activePanel {
        opacity: 1;
        pointer-events: auto;

        @include anim(300ms, ease);
      }

      h3 {
        font-size: 16px !important;
        padding: 0;
        font-weight: 600;
      }
    }
  }

  .user-heading {
    padding: 24px 18px 11px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    @include respond-to(fromxsmall) {
      padding: 24px 10px;
    }

    i {
      color: $gray-light;
      margin-right: 12px;
    }

    h3 {
      font-size: 18px;
      margin: 0;
    }

    @media (max-width: 1280px) {
      .btn {
        padding: 2px 6px;

        i {
          display: none;
        }
      }
    }
  }

  .user-body {
    .btn-neutral-outline {
      &:hover {
        background-color: #fff !important;

        @include theme-border;
        @include theme-color;
      }
    }
  }

  .btn-logout {
    background-color: transparent !important;
    font-weight: normal;
    padding: 8px 8px 6px !important;
    border: 1px solid $gray;
    color: $text-color;

    @include anim;

    i {
      color: $text-color;
    }

    &:hover {
      background-color: #fff !important;

      @include theme-border;
      @include theme-color;

      i {
        @include theme-color;
      }
    }
  }

  /* #panel-user */
  .menu-user,
  .menu-support {
    margin-top: 5px;

    li {
      a {
        color: $gray-dark;
        text-decoration: none;
        padding: 10px 18px;
        display: block;
        font-size: 14px;

        @include anim(300ms, ease);

        &:hover {
          background-color: $gray-lighter;
        }

        i {
          margin-right: 12px;
        }
      }
    }
  }

  .menu-user {
    li {
      text-align: center;

      .btn {
        display: inline-block;
        font-size: 12px;
      }
    }
  }

  .support-heading {
    padding: 24px 18px 11px;

    h3 {
      font-size: 18px;
      margin: 0;
    }
  }



  /* #panel-support */

  .notify-heading {
    padding: 25px 20px 11px;
  }

  .notify-body {

    .notify-list {
      font-size: $font-size-base;
      background-color: $gray-lighter;
      line-height: 1.8em;
      border-top: 1px solid darken($gray-lighter, 10%);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      position: absolute;
      margin-right: -1px;
      top: 5px;
      bottom: 0;
      overflow-y: auto;

      li {
        padding: 16px 14px 14px;
        border-right: 3px solid darken($gray-lighter, 10%);
        border-bottom: 1px solid darken($gray-lighter, 10%);
        color: #666;
        line-height: 1.3em;
        opacity: 0.7;
        cursor: pointer;

        @include anim(400ms, ease);

        @media (max-width: 1280px) and (min-width: 769px) {
          font-size: 12px;
        }

        span {
          color: #000;
          font-weight: 700;
        }

        &.unread {
          border-right: 3px solid $secondary-color;
          opacity: 1;
          background-color: #fff;
          box-shadow: 1px 0 1px rgba(0, 0, 0, 0.5);
          border-bottom: 0;

          @include theme-border;

          &:hover {
            border-right: 3px solid $secondary-color;
          }
        }

        .date {
          font-size: 8px;
          margin-top: 6px;
          opacity: 1;
          text-transform: uppercase;
          font-weight: 600 !important;
          color: $gray;
        }

        &:hover {
          opacity: 1;
          background-color: #fff;
          border-right: 3px solid $gray-light;
        }
      }
    }
  }



  /* #slide-sidebar */
}

/* #slide-sidebar-wrapper */

.tab-pane {
  color: black;
}
