.grid-header {
  margin-top: $spacing-base;
  padding: 0 $spacing-base;
  background: $gray-0;
  border: 1px solid $gray-300;
  color: $gray-900;
  cursor: default;

  @include respond-to(xsmall) {
    font-size: $font-size-sm;
  }

  > div,
  &__default,
  &__float,
  &__bands {
    position: relative;
    display: flex;
    align-items: center;
    padding: $spacing-base 0;

    i {
      color: $gray-600;
      margin-right: $spacing-base;
    }

    .rate-type-config {
      pointer-events: none;

      i {
        pointer-events: initial;
        cursor: pointer;
        transition: color 0.4s ease-in-out;

        &:hover {
          color: $gray-900;
        }
      }

      &:first-of-type {
        i {
          margin-left: $spacing-base;
        }
      }
    }
  }

  > div:not([class]),
  > div[class=""],
  > div.ng-scope:not([class*="grid-header__"]) {
    padding: 0;
  }

  + div div.div-load {
    div.loader-wrapper {
      width: 100%;
      text-align: center;
      top: 10px;
    }
  }
}

.grid-header__default {
  flex-wrap: wrap;
  max-width: calc(100% - 150px);

  > span {
    display: flex;
    align-items: center;
  }

  na-field-component {
    margin: 0 $spacing-base;
    display: block;

    //select inside field component
    .dropdown.dropdown-select {
      border-width: 0;

      > a {
        background: transparent;
        padding: 0;
        color: $gray-900 !important;
        line-height: initial;

        span {
          right: inherit;
          top: inherit;
          font-size: 0.65em;
          position: relative;
          color: $gray-600;
        }
      }
    }
  }
}

.grid-header__actions {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  a {
    font-size: $font-size-sm;
    color: $gray-600 !important;
    text-decoration: underline;
  }

  button.btn {
    padding: 0 13px;
    height: 30px;
  }

  button.btn:not(.btn-neutral-outline) {
    background: $na-primary;
    color: white !important;
    border: 0;

    &:hover {
      background: $na-primary-darker !important;
    }
  }

  > *:not(:last-child) {
    margin-right: $spacing-base;
  }

  button.load .loader-wrapper {
    top: 0 !important;
    background: inherit;
    border-radius: 3px;
  }
}

.grid-header__title {
  flex: 1;
}

.grid-header__back {
  padding-right: $spacing-base;
  margin-right: $spacing-base;
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    width: 1px;
    height: calc(100% + #{$spacing-base*2});
    background: $gray-300;
    position: absolute;
    right: 0;
    top: -$spacing-base;
  }

  i {
    transition: color 0.4s ease-in-out;
  }

  &:hover {
    i {
      color: $gray-900;
    }
  }
}

//border-positioning
.grid-header + .row,
#rates-grid-form + .row,
.header-row + .data-row {
  position: relative;
  top: -1px;
}

// Availability Grid Only
na-availability-template {
  .grid-header__default {
    > span:only-child {
      margin-left: $spacing-base;
    }
  }
}
