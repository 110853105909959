/* stylelint-disable-next-line no-invalid-position-at-import-rule */
.header-row {
  z-index: 101;
}

.to-date {
  background-color: #fff;
  position: absolute;
  top: 36px;
  z-index: 100;
  display: table-cell;
  width: 160px;
  font-weight: 400;
  height: 37px;
  vertical-align: middle;
  padding: 4px 10px;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  font-size: 13px;
  cursor: pointer;
}

.month-container { /* stylelint-disable-line */
  width: 100%;
  position: relative;
}

.cell-day {
  vertical-align: middle;
  font-size: 16px;
}

.cell-day span {
  line-height: 12px;
  display: block;
  font-size: 10px;
  width: 49px;
  margin-top: 2px;
  margin-bottom: -4px;
}

.cell-empty {
  height: 36px
}

.room-expand {
  text-align: center;
  display: block;
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
  cursor: pointer;
  float: left;
}

.cell-room-subcat {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 15px;
  cursor: pointer;
}

.cell-room {
  text-align: left;
  line-height: 15px;
  cursor: pointer;
  font-size: 11px;
}

.room-name {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.whole-row-room-name {
  display: inline-block;
  white-space: nowrap;
  border-top: 1px solid lightgray;
  border-left: 1px solid lightgray;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 36px;
  width: 100%;
  padding-top: 10px;
  padding-left: 8px;
  line-height: 15px;
  padding-right: 10px;
}

na-horizontal-grid .cell-data {
  vertical-align: middle;
  text-align: center;
  font-weight: 400;
  line-height: 15px;
  background: white;
  overflow: hidden;
  white-space: nowrap;
  min-width: 50px;
}

na-horizontal-grid .cell-data-group-no-border {
  border-left: none
}

na-horizontal-grid .cell-data a {
  padding: 9px 0;
  display: block;
  color: #666;
  text-decoration: none;
  transition: all 200ms ease;
  cursor: pointer;
}

na-horizontal-grid .cell-data a:hover {
  background-color: #f5f5f5;
  text-decoration: none;
  box-shadow: inset 0 0 1px 1px #666;
}

na-horizontal-grid .cell-data a:focus {
  text-decoration: none !important;
  outline: 0 !important;
}

[contenteditable]:focus {
  outline: 0 solid transparent;
  border: 1px solid #666 !important
}

.disabled-cell-data:not(.availability-closeout) {
  pointer-events: none;
  background-color: white;
  color: #2F2F2F;
  text-decoration: none;
}

.disabled-cell-data a {
  color: #c7c3c3 !important;
}

.months-row {
  width: 905px;
  position: relative;
  height: 36px;
  padding: 8px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  border-left: 1px solid #e5e5e5;
  z-index: 1000;
}


na-horizontal-grid span.alt,
.room-name span.alt {
  float: left;
  width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

na-horizontal-grid span.price-symbol {
  float: right;
}

.month-name-row:after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

na-horizontal-grid a.disabled {
  color: #c7c7c7 !important;
}

na-horizontal-grid a.slim {
  height: 17px;
  display: block;
  margin: 0;
  padding: 0 !important;
}

na-horizontal-grid a.dropdown-grid {
  padding: 0 !important;
}

na-horizontal-grid .grid-navigation {
  line-height: 36px;
  font-size: 26px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.rate-cell a {
  overflow: hidden;
}

.hide-rate-cell {
  opacity: 0;
}

.rate-cell,
.row-days {
  width: 50px !important;
  height: 36px !important;
}

// Rate Band Switch
na-rate-band-switch,
na-rate-float-switch {
  .label-text {
    padding-left: 30px;
    padding-right: 10px;
    display: block;

    .icon-button {
      padding-left: 5px;
    }
  }
}

na-channels-grid-button-switcher {
  display: flex;
  max-width: 100%;
  flex-direction: row;

  button.btn {
    white-space: normal;
  }
}

.data-row .cell-room.cell-room-group+div>.cell-room-subcat.draggable {
  padding: 0;
}

.data-row .cell-room.cell-room-group+div>.cell-room-subcat>i.fa-reorder {
  font-size: 13px;
}