// fancy label
$fancy-label-success: #DAF4D9;
$fancy-label-warning: #FFF9C5;
$fancy-label-danger: #FFDCDC;

.fancy-label {
  border-radius: 4px;
  padding: 10px 12px;
  color: #333;
  background: #ccc;
  font-weight: 600;
  font-size: 14px;
  text-align: center;

  &.fancy-label--success {
    background-color: $fancy-label-success;
  }

  &.fancy-label--warning {
    background-color: $fancy-label-warning;
  }

  &.fancy-label--danger {
    background-color: $fancy-label-danger;
  }

  span {
    display: inline-block;
    width: 80px;
  }
}
