// here you will find the colors that will be replaced and used for the whole system soon.

$gray-0: #F8F9FA;
$gray-100: #F1F3F5;
$gray-200: #EAEDEF;
$gray-300: #DEE2E6;
$gray-400: #CFD4DA;
$gray-500: #ADB5BD;
$gray-600: #868E96;
$gray-700: #495057;
$gray-800: #343A40;
$gray-900: #21252A;

$dates-height: 52px;
$cell-height: 37px;
$cell-min-width: 65px;
