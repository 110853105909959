//
// Tooltips
// --------------------------------------------------

// Base class
.tooltip {
  // Light tooltip
  &.tooltip-light {
    opacity: 1;

    //.tooltip-arrow {
    //  display: none;
    //}

    .tooltip-arrow {
      position: relative;
      border: none;
      text-decoration: none;
      border-radius: 2px;
      padding: 0;
      box-shadow: 1px 5px 0 5px #fff, 9px 5px 0 5px #fff;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -5px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #ddd;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: -4px;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 9px solid transparent;
        border-bottom-color: white;
      }
    }

    .tooltip-inner {
      background: transparent;
      max-width: 400px;
      padding: 0 8px;
    }
  }
}
