.ui-grid-footer-panel-background {
  @include gradient($headerBackgroundColor, $headerGradientStart, $headerGradientStop);
}

$topPanelRadius: $gridBorderRadius - $gridBorderWidth;

.ui-grid-footer-panel {
  position: relative;
  border-bottom: 1px solid $borderColor; // #D4D4D4
  border-top: 1px solid $borderColor;
  overflow: hidden; // Disable so menus show up
  font-weight: bold;

  // @include gradient($headerBackgroundColor, $headerGradientStart, $headerGradientStop);
  @extend .ui-grid-footer-panel-background;

  @include border-radius($topPanelRadius, 0, 0, $topPanelRadius);
}

.ui-grid-grid-footer {
  float: left;
  width: 100%;
}

.ui-grid-footer-viewport {
  overflow: hidden; // Disable so menus show up
}

.ui-grid-footer-canvas {
  position: relative;

  // Clearfix for floating header cells
  &:before,
  &:after {
    content: "";
    display: table;
    line-height: 0;
  }

  &:after {
    clear: both;
  }
}

.ui-grid-footer-cell-wrapper {
  position: relative;
  display: table;
  box-sizing: border-box;
  height: 100%;
}

.ui-grid-footer-cell-row {
  display: table-row;
}

.ui-grid-footer-cell {
  overflow: hidden;
  // position: relative; // NOTE: removing so border is visible
  background-color: inherit;
  border-right: $gridBorderWidth solid;
  border-color: $borderColor;
  box-sizing: border-box;
  display: table-cell;

  &:last-child {
    border-right: 0;
  }
}

