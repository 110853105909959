na-menu .menu {
  position: absolute;
  top: $sidebar-top;
  bottom: $sidebar-bottom;
  overflow-y: auto;
  left: 0;
  right: 0;
  @include anim;
  @include scrollbar;

  &.submenu-open{
    @include respond-to(fromtablet) {
      overflow: hidden;
    }
  }

  na-menu-group {
    >ul {
      >li {
        >a {
          overflow: hidden;

          &:after {
            font: var(--fa-font-light);
            content: '\f061';
            position: absolute;
            right: -30px;
            font-size: 18px;
            opacity: 0;
            @include anim;
          }

          &:hover {
            &:after {
              top: 18px;
              right: 20px;
              opacity: 0.2;
              color: #000;
            }
          }
        }
      }
    }
  }

  na-menu-head {
    pointer-events: none;
  }

  na-menu-item {
    li a {
      &:before {
        margin-right: 20px !important;
      }
    }
  }

  hr {
    margin-bottom: 0;
    border: 0;
    height: 1px;
    background: #ddd;

    &+.bi__external_reports {
      display: inline-block;
      margin-top: 15px;
    }
  }

  ul {
    margin-top: 0;
  }

  li {
    display: block;
    line-height: 24px;
    margin-left: 14px;

    &.heading {
      &:hover {
        background: none !important;
      }

      a {
        font-size: 11px;
        display: block;
        font-weight: 500;
        text-transform: uppercase;
        cursor: default;
        color: $gray-dark;
        pointer-events: none;

        &:hover {
          background: none !important;

        }
      }

    }

    &:hover,
    &.active {
      a:before {
        @include theme-color;
      }
    }

    &.open {
      i {
        transform: rotate(90deg);
      }
    }

    &.disabled {
      pointer-events: none;

      a {
        color: $gray  !important;

        &:before {
          color: $gray;
        }
      }
    }
  }
  //outside a - main list with icons;
  a {
    display: block;
    color: black;
    text-decoration: none;
    padding: 15px;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    line-height: 1.4;
    @include anim;

    @include respond-to(xsmall) {
      font-size: 14px;
      padding: 12px 0;
    }

    @media (max-width: 1024px) {
      padding-right: 10px;
    }

    &:before {
      @include anim;
    }

    i {
      float: right;
      font-size: 18px;
    }

    &:hover {
      color: $na-primary;

      &:before {
        @include theme-color;
      }
    }
  }

  .sub-menu {
    position: fixed;
    width: #{$sidebar-medium - 1};
    left: -#{$sidebar-medium};
    top: $sidebar-top;
    bottom: $sidebar-top;
    padding-bottom: $sidebar-top;
    background: #fff;
    z-index: 10;
    overflow-x: auto;
    @include anim;
    @include scrollbar;
    
    @include respond-to(fromxsmall) {
      width: 268px;
      left: -270px;
      box-shadow: 0 0 2px #ccc;
    }
    
    .leftOn & {
      left: -#{$sidebar-medium};
    }

    li {
      a {
        font-size: $font-size-base;
        padding: 10px;
        font-weight: 400;

        &:hover {
          color: $na-primary;
        }
      }

      &.active {
        a {
          font-weight: bold;
        }
      }

      &.disabled {
        a {
          color: $gray;

          &:before {
            color: $gray;
          }
        }
      }

      ul li a {
        font-size: 13px;
      }
    }

    .sub-menu__heading {
      font-size: 20px;
      padding: 5px 0;
      font-weight: 500;
      margin: $spacing-base*2 $spacing-base*2 $spacing-base $spacing-base*2+5;
      color: #000 !important;
      line-height: 1;
      position: relative;
      display: flex;
      align-items: center;

      @include respond-to(xsmall) {
        font-size: 18px;
      }


      &:before {
        @include theme-color;
        font-size: 24px;
        width: auto;

        @include respond-to(xsmall) {
          font-size: 21px;
          margin-right: 8px;
        }
      }

      span {
        cursor: pointer;
        opacity: 0.24;
        @include anim;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 0;

        i {
          font-size: 20px;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
  
  na-menu-group-submenu {
    li {
      ul {
        li {
          a {
            border-left: 2px solid $gray-light;
            padding-left: 15px !important;

            &:hover {
              color: #000;
            }

          }

          &.active {
            a {
              color: #000;
            }
          }
        }
      }
    }
  }
}

//open sub-menu only when sidebar is active on mobile (has class .leftOn) and desktop (.leftOff)
@include respond-to(fromxsmall) {
  .leftOn {
    na-menu nav.menu ul.active li .sub-menu {
      left: 0;
    }
  }
}

@media (min-width: 992px) {
  .leftOff {
    na-menu nav.menu ul.active li .sub-menu {
      left: 0;
    }
  }
}


/* nav */

/* Icons */
nav.menu {
  >ul {
    margin-top: 10px !important;
  }
}  

  .sub-menu__heading:before,
  div[class^='na-menu-item__'] li>a:before,
  na-menu-item[class^='na-menu-item__'] li>a:before,
  ul[class^='na-menu-group__'] li>a:before {
    font: var(--fa-font-light);
    color: $border-color-dark;
    margin-right: $spacing-base*2;
    font-size: 20px;
    vertical-align: bottom;
    width: 20px;
    display: inline-block;
    text-align: center;
  }

  .sub-menu__heading:before {
    margin-right: 13px;
    color: $na-primary-darker;
  }
  

  //dashboard
  .na-menu-item__Dashboard>li>a:before {
    content: '\f009';
  }

  .na-menu-item__Settings>li>a:before,
  .na-menu-group__system_settings>li>a:before {
    content: '\f013';
  }

  .na-menu-group__branding>li>a:before {
    content: '\f618';
  }

  .na-menu-group__emailsVoucher>li>a:before {
    content: '\f1fa';
  }

  .na-menu-group__delivery>li>a:before {
    content: '\f674';
  }

  .na-menu-group__widget>li>a:before {
    content: '\f6bf';
  }

  .na-menu-item__frontend>li>a:before {
    content: '\f108';
  }

  .na-menu-item__bookingengine>li>a:before {
    content: '\f1c0';
  }

  .na-menu-item__channelmanager>li>a:before {
    content: '\f6a3';
  }

  .na-menu-item__channelengine>li>a:before {
    content: '\f509';
  }

  .na-menu-item__CMS>li>a:before {
    content: '\f245';
  }

  .na-menu-item__Companies>li>a:before {
    content: '\f1ad';
  }

  .na-menu-item__Billing>li>a:before {
    content: '\f1ec';
  }

  .na-menu-item__Users>li>a:before {
    content: '\f0c0';
  }

  .na-menu-item__Translations>li>a:before {
    content: '\f1ab';
  }

  .na-menu-item__termsService>li>a:before,
  .na-menu-item__termsAgreement>li>a:before,
  .na-menu-item__termsPrivacy>li>a:before {
    content: '\f505';
  }

  .na-menu-group__health_monitor>li>a:before {
    content: '\f21e';
  }

  .na-menu-group__retargeting>li>a:before {
    content: '\f648';
  }

  .na-menu-group__pre_post_email>li>a:before {
    content: '\f658';
  }

  .na-menu-group__bi>li>a:before {
    content: '\f201';
  }

  .na-menu-group__notifications>li>a:before {
    content: '\f0f3';
  }

  .na-menu-group__wedding >li>a:before {
    content: '\f81b';
  }

  .na-menu-group__corporate >li>a:before {
    content: '\f0b1';
  }


  // pibe
  .na-menu-group__accommodation {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f236';
    }
  }

  .na-menu-group__vouchers {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f663';
    }
  }

  .na-menu-group__products {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f07a';
    }
  }

  .na-menu-group__sales {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f290';
    }
  }

  .na-menu-group__reviews {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f10e';
    }
  }

  .na-menu-group__transactions {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f09d';
    }
  }

  .na-menu-group__payments {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f53a';
    }
  }

  .na-menu-group__deposit {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f4c0';
    }
  }

  .na-menu-group__taxes {
    >li>a:before,
    .sub-menu__heading:before {
      font-family: "Font Awesome Kit" !important;
      content: '\e001';
    }
  }

  .na-menu-group__discount {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f02c';
    }
  }

  .na-menu-group__upgrades {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f0aa';
    }
  }

  .na-menu-group__extras {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f217';
    }
  }

  .na-menu-group__tariff_policies {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\e4f0';
    }
  }

  .na-menu-group__legal {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f515';
    }
  }

  .na-menu-group__widget {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f6bf';
    }
  }

  .na-menu-group__reports {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f46c';
    }
  }

  .na-menu-group__diagnostics {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f0f1';
    }
  }

  .na-menu-group__migration {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f021';
    }
  }

  .na-menu-group__statistics {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f080';
    }
  }

  .na-menu-group__connections {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f6ff';
    }
  }

  .na-menu-group__channels {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f5ed';
    }
  }

  .na-menu-group__settings {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f013';
    }
  }

  .na-menu-group__wedding {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f81b';
    }
  }

  .na-menu-group__corporate {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f0b1';
    }
  }

  .na-menu-group__testfrontend {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f108';
    }
  }

  .na-menu-group__preview {
    >li>a:before,
    .sub-menu__heading:before {
      content: '\f06e';
    }
  }
