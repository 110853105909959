#closeOutByToggles {
  & .toggleIcon {
    margin-top: 10px;
  }
}

.voucher-pdf__box {

  $tile-footer-height: 60px;

  display: flex;
  flex-wrap: wrap;
  color: #000;
  margin: 0 -30px;

  .tiles-item {
    @include anim;
    @include fx-elevate(3px, 2px, 0.1);

    transform: translate(0, 0);
    border: 1px solid $gray-light;
    border-radius: $border-radius*2;
    cursor: pointer;
    display: inline-block;
    margin: 25px 30px 0;
    height: 330px;
    width: calc(33.3% - 60px);
    z-index: 1;

    .tiles-main {
      position: absolute;
      top: 0;
      bottom: $tile-footer-height;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid $gray-light;

      img {
        width: 100%;
        height: 100%;
      }

      &:hover {
        .templates-actions {
          opacity: 1;
        }
      }
    }

    .tiles-footer {
      position: absolute;
      bottom: 0;
      width: 100%;

      > div {
        width: 45%;
        float: left;
        padding: 20px;
        height: $tile-footer-height;

        uib-tooltip-popup {
          z-index: 99999999 !important;
        }
      }

      .tiles-company {
        width: 55%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
      }

      .tiles-dots {
        @include anim;

        font-size: 27px;
        opacity: 0.5;
        position: relative;
        top: -8px;

        &:hover {
          opacity: 1;
        }
      }
    }

    .tiles-expiry {
      position: absolute;
      bottom: 9px;
      left: 0;
      right: 0;
      font-size: 12px;
      opacity: 0.6;
      text-align: center;
    }

    @media (max-width: 1280px) {
      width: calc(100% * (1/2) - 25px - 1px);
      max-width: calc(100% * (1/2) - 25px - 1px);
    }

    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
    }

    &.tiles-add {
      background-color: $gray-lighter;

      .tiles-add-label {
        position: absolute;
        top: 50%;
        font-size: 16px;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: -50px;
        opacity: 0.6;

        &:before {
          font: var(--fa-font-regular);
          content: '\f067';
          font-weight: normal;
          font-size: 50px;
          clear: both;
          display: block;
        }
      }
    }
  }

  // .tiles-item
}

.template-pdf {
  text-align: right;

  .active {
    float: right;
    background: $na-primary;
    padding: 3px 10px;
    border-radius: 50px;
    text-transform: uppercase;
    color: #fff;
    font-size: 10px !important;
    display: flex;
    align-items: center;
    font-weight: 700;
    width: 75px;

    i {
      padding-right: 5px;
    }
  }
}

.templates-actions {
  position: absolute;
  top: 0;
  background: rgba(black, 0.5);
  width: 100%;
  height: 100%;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  border-radius: 6px 6px 0 0;

  .button {
    padding: 10px 25px;
    height: auto;
    text-align: center;
    min-width: auto;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #ddd;
    margin: 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;

    &:hover {
      background: $na-primary;
      border-color: $na-primary;
      color: #fff;
      text-decoration: none;
    }
  }

  #applyTemplate {
    width: 98px;
  }
}

.voucher-pdf-modal {
  .modal-body {
    //improve the view for the landscape voucher
    overflow-x: hidden;
    overflow-y: auto;

    //this will come back when we have the footer on the modal. and the navigation for multiple templates.
    //background: $status-null;
    .voucher {
      background: white;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
    }

    .voucher--template2 {
      //improve the view for the landscape voucher
      transform: scale(0.735);
      transform-origin: top left;
    }
  }
}
