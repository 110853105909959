.welcome-message {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 210px);

  .welcome__illustration {
    width: 50%;

    &:not(.welcome__illustration--voucher) {

      svg {
        width: 90%;

        /*Animations*/

        #Group_47 {
          opacity: 0.1;

          * {
            opacity: 1;
          }
        }

        #Ellipse_1 {
          stroke: white;
          animation: circle linear 2s forwards;
        }

        #Group_15,
        #Group_21,
        #Group_29,
        #Group_46,
        #Group_43,
        #Group_36 {
          animation: entrance linear 1.5s forwards;
        }

        #Group_21 {
          transform: translate(150px, 0);
        }

        #Group_36 {
          transform: translate(-115px, -56px);
        }

        #Group_46 {
          transform: translate(-100%, 100%);
        }

        #Group_43 {
          transform: translate(-100%, 0);
        }

        #Group_15 {
          transform: translate(115px, -56px);
        }

        #Group_29 {
          transform: translate(130%, 130%);
        }

        #Group_48 {
          transform: scale(1.25);
          transform-origin: 50% 50%;
          animation: entrance ease-in 1.5s forwards;
        }

        @keyframes circle {
          from {
            stroke-width: 50px;
          }

          to {
            stroke-width: 0;
          }
        }

        @keyframes entrance {
          from {}

          to {
            transform: translate(0);
          }
        }
      }
    }

    #Ellipse_1 {
      stroke: white;
      animation: circle linear 1s forwards;
    }

    @keyframes circle {
      from {
        stroke-width: 50px;
      }

      to {
        stroke-width: 0;
      }
    }

    &.welcome__illustration--voucher {
      #Group_29 {
        transform: rotate(10deg) translate(-100px, -220px);
        animation: turn ease-in-out 850ms forwards;
        transform-origin: top left;
      }

      @keyframes turn {
        from {
          transform: rotate(15deg) translate(-100px, -220px);
        }

        to {
          transform: rotate(10deg) translate(-100px, -220px);
        }
      }
    }
  }

  .welcome__text {
    width: 50%;
    color: black;

    p {
      line-height: 2;
    }

    ol {
      list-style-type: decimal;
      margin-left: 15px;
      color: $na-primary;

      li {
        margin-bottom: 18px;
        display: list-item;
        align-items: center;

        a:focus {
          color: $na-primary;
          text-decoration: none;
        }
      }
    }

    ul {
      li {
        margin-bottom: 18px;
        display: flex;
        align-items: center;

        &:before {
          @extend .far;

          content: '\f00c';
          margin-right: 5px;
        }
      }
    }
  }

  .welcome__title {
    margin-bottom: 20px;

    h1 {
      color: black;
      font-size: 30px;

      span {
        color: #707070;
        display: block;
        font-size: 20px;
        font-weight: 400;
      }

      strong {
        margin: 5px 0;
        display: block;
      }
    }

    &:after {
      content: "";
      width: 100px;
      height: 2px;
      background: $na-primary;
      display: block;
    }
  }
}
