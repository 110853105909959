/*
* RTL Styles
*/

.ui-grid[dir=rtl] {

  .ui-grid-header-cell,
  .ui-grid-footer-cell,
  .ui-grid-cell {
    float: right !important;
  }

  .ui-grid-column-menu-button {
    position: absolute;
    left: 1px;
    top: 0;
    right: inherit;
  }

  .ui-grid-cell:first-child,
  .ui-grid-header-cell:first-child,
  .ui-grid-footer-cell:first-child {
    border-right: 0;
  }

  .ui-grid-cell:last-child, .ui-grid-header-cell:last-child {
    border-right: $gridBorderWidth solid $borderColor;
    border-left: 0;
  }

  .ui-grid-header-cell:first-child .ui-grid-vertical-bar,
  .ui-grid-footer-cell:first-child .ui-grid-vertical-bar,
  .ui-grid-cell:first-child .ui-grid-vertical-bar {
    width: 0;
  }

  .ui-grid-menu-button {
    z-index: 2;
    position: absolute;
    left: 0;
    right: auto;
    background: $headerBackgroundColor;
    border: $gridBorderWidth solid $borderColor;
    cursor: pointer;
    min-height: 27px;
    font-weight: normal;
  }

  .ui-grid-menu-button .ui-grid-menu {
    left: 0;
    right: auto;
  }

  // Position filter-cancel button on the left for rtl grids
  .ui-grid-filter-container .ui-grid-filter-button {
    right: initial;
    left: 0;

    [class^="ui-grid-icon"] {
      right: initial;
      left: 10px;
    }
  }
}
