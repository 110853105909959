//Dashboard modals
.dashboard__modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .dashboard__modal-data {
    margin-left: $spacing-base*2;
    flex: 1;

    .chart__info {

      // Add colour to the dot
      @for $i from 1 through $chartColorsLength {
        &:nth-of-type(#{$chartColorsLength}n+#{$i}) {
          .chart__header > p:before {
            background-color: nth($chartColors, $i);
          }
        }
      }
    }
  }

  .chart__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    > p {
      &:before {
        content: '';
        margin-right: $spacing-base;
        width: 10px;
        height: 10px;
        background: white;
        border-radius: 50%;
      }
    }
  }

  .chart__header > p,
  p.chart__header {
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 0;
  }

  .chart__info {
    margin-bottom: $spacing-base*2;
  }

  //Most valuable and most popular modal
  na-stats-list {
    display: flex;
    padding: 0 5%;

    .dashboard__system-main {
      display: none;
    }

    .dashboard__system-chart {
      flex: 0 1 50%;
    }

    .dashboard__system-data {
      width: auto;
    }
  }
}