na-phone-field {
  .iti {
    width: 100%;

    &__country {
      padding: $spacing-base;
    }

    &__selected-dial-code {
      color: #757575;
    }
  }

  input[type=tel] {
    @extend .form-control
  }
}