.location-tab {
  .title-link {
    color: $gray-medium;
    font-size: 12px;
  }

  .field-wrapper {
    margin-bottom: 25px;
  }

  h2 {
    display: inline-block;
    width: 100%;
    margin-top: 0 !important;
  }
}

.dp-places-map-input {
  width: 70% !important;
}

.field-wrapper__address {
  position: relative;

  input.field-address {
    padding: 0 185px 0 20px;
    font-size: 16px;
    line-height: 1.4;
    min-height: 44px;
    height: 44px;
    resize: none;
    width: 100%;
    border: 1px solid #ccc;
    text-overflow: ellipsis;
    border-radius: $border-radius;
  }

  .button--findaddress {
    border-radius: $global-radius;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 14px;
    z-index: 1;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    background: $na-primary;
    padding: 6px 10px 6px 8px;
    color: #fff;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: #fff;
    }

    i {
      font-size: 12px;
      line-height: 0;
      position: relative;
      top: 1px;
    }
  }
}
