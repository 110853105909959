//Charts

//colours on charts

$chartColors: #8B00EF, 
              #F217BB, 
              #FAA2E4, 
              #FDD0F1, 
              #FEC750, 
              #FFE9B9, 
              #FFF4DC, 
              #1CDADA,
              #A4F0F0,
              #D1F8F8,
              #307FE2,
              #ACCCF3,
              #D5E5F9;

@for $i from 1 through length($chartColors) {
  $chartColors: append($chartColors, darken(nth($chartColors, $i), 12));
}

$chartColorsLength: length($chartColors);

//Dot on custom tooltip
.apexcharts-tooltip .apexcharts-tooltip-custom {

  // Add colour to the dot
  @for $i from 1 through $chartColorsLength {
    &:nth-of-type(#{$chartColorsLength}n+#{$i}) {
      span.apexcharts-tooltip-marker {
        background-color: nth($chartColors, $i);
      }
    }
  }

  //general style for the dot
  span.apexcharts-tooltip-marker {
    top: 1px;
  }
}

//Borders
.apexcharts-canvas {
  > svg {
    border-radius: $border-radius-lg;
  }
}

// Tooltip for charts
.apexcharts-tooltip-text .apexcharts-tooltip-y-group {
  padding: 6px 0 2px;
}

.apexcharts-tooltip.apexcharts-active {
  .apexcharts-tooltip-series-group {
    display: flex;
  }
}

//spacing issues
.apexcharts-area:first-child {
  transform: translate(0, 2px);
}

// style for most popular charts
.chart__info .legend {
  list-style-type: none;
  padding: 0;

  .legend__item {
    position: relative;
    display: flex;
    padding-top: 0;

    // Add colour to the dot
    @for $i from 1 through $chartColorsLength {
      &:nth-of-type(#{$chartColorsLength}n+#{$i}) {

        [type="checkbox"]:not(:checked) + span:before,
        [type="checkbox"]:checked + span:before {
          background-color: nth($chartColors, $i);
        }
      }
    }
  }

  .legend__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    > * {
      margin: 0;
      padding: 0;

      &:last-child {
        flex: 1 0 auto;
        text-align: right;
        margin-left: $spacing-base;
        display: flex;
        flex-direction: column;

        .data__value {
          span:nth-child(2) {
            font-size: 0.8em;
            opacity: 0.5;
          }
        }

        .data__variation {
          margin-top: $spacing-base*0.5;
        }
      }
    }

    a > p {
      @include text-overflow-mixin($font-size, 1.3, 2);
    }
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  [type="checkbox"]:not(:checked) + span,
  [type="checkbox"]:checked + span {
    position: relative;
    padding-left: 1.2em;
    cursor: pointer;
    margin: 0;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + span:before,
  [type="checkbox"]:checked + span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
  }
}