.main-content li {
  list-style: inside disc;
  padding: 3px;
}

.main-content ol li {
  list-style: inside decimal;
  padding: 3px;
}

/** Please add following to sass properly */
#menu-list {
  background: rgba(80, 80, 80, 0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* stylelint-disable-next-line no-duplicate-selectors */
.sr-only {
  display: none !important;
}

.dropempty {
  min-height: 42px;
  display: table-row-group;
}

.policy-edit-page {
  na-checkbox {
    display: inline-block;
  }
}

.mce-fullscreen {
  z-index: 10000 !important;
}

//cc-detail-modal on booking details
.view-cc-details-modal {
  text-align: center;

  iframe {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-height: 300px;
  }
}


.companytypesvg {
  width: 32px;
  height: 32px;
}

.mce-toolbar {
  position: -webkit-sticky !important; // required for Safari
  position: sticky !important;
  top: 0; // required as well.
}

a.manual-edit {
  color: #009afd !important;
}

.uib-datepicker table {
  margin-top: -5px;
}

.uib-daypicker tr.uib-weeks.ng-scope td:nth-child(1),
th.text-center:nth-child(1) {
  display: none;
}

.uib-daypicker th:nth-child(2) {
  column-span: 5;
}

.bulk-list {
  float: left;
  width: 100%;
  height: auto;
  margin: 0 auto;
}

.bulk_list_wrapper {
  float: left;
  width: 100%;
  height: auto;

  .bulk-label-list {
    li {
      .input-group-btn {
        display: flex;
        width: auto;
      }

      .input-group-bulk-rate .input-group-btn:last-child > .btn {
        margin-left: 0;
      }

      .input-group .form-control {
        width: 90px;
        margin-right: 0;
      }
    }
  }
}

.bulk-label-list label {
  width: 40%;
  line-height: 37px;
  text-align: right;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;

  .label-text {
    line-height: 20px;
    padding-right: 30px;
  }
}

.bulk-label-list .label-checkbox-right {
  width: 55%;
  float: none;

  @include respond-to(xsmall) {
    width: 100%;
  }
}

.list-header-label {
  padding-bottom: 20px;
}

.bulk-label-list li {
  padding-bottom: 10px;
  float: left;
  overflow: auto;
  width: 100%;
}

.bulk-label-list input {
  width: 90px;
  float: left;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
}

ul.bulk-label-list {
  padding-top: 25px;
  margin: 0;
  overflow: auto;
  width: 100%;

  @media(max-width: 992px) {
    width: 70% !important;
  }
}

.bulk-rate {
  float: left !important;
  position: absolute;
}

.input-group-bulk-rate {
  width: auto !important;
}

.input-group-bulk-rate .input-group-addon {
  width: auto;
  min-width: 160px;
}

.input-group-bulk-rate .input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  height: auto;
  min-height: 32px;
  margin-left: 1px;
  width: 80px;
  z-index: 2;
  position: relative;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

  .input-group-bulk-rate .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .btn-group {
    min-height: 35px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {

  .input-group-bulk-rate .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .btn-group {
    min-height: 35px;
  }
}

na-rates-bulk-template {
  .bulk-label-list li {
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.avoid-clicks {
  pointer-events: none;
}

.value-from-pool {
  color: #c7c7c7 !important;
}


.link-loading {
  opacity: .2;
}

span.availlink {
  float: left;
  position: absolute;
  top: 100%;
  left: 90%;
  font-size: 16px;
  opacity: 0.8;
  cursor: pointer;
}

span.altlabel {
  color: #333;
  width: 86%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-align: right;
  font-size: 10px;
}


// General updates for translations
#btn-viewVoucher {
  margin-right: 15px;
}

.list-pagination {
  .row {
    @include respond-to(xsmall) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.add-voucher-page {
  @include respond-to(xsmall) {
    na-delivery-table {
      .col-md-6 {
        padding: 0;
      }
    }
  }

  .param-checkbox:first-child {
    margin-top: 0;
  }
}

#guaranteedBtn,
#prePaymentWrapper,
#saveAccountAccessBtn {
  margin-top: 30px;
}

payment-provider-integration-template {
  label .label-text {
    line-height: 1.4;
    margin-left: 10px;
    padding-left: 30px;
  }

  .checkbox {
    label .label-text {
      margin-left: 0;
    }
  }

  .list-table .dropdown.dropdown-select {
    @include respond-to(fromxsmall) {
      display: block;
    }
  }

  .input-group-btn {
    .input-group-btn-item {
      @include respond-to(fromxsmall) {
        width: calc(33% - 3px) !important;
        margin: 0 5px 0 0 !important;
        padding: 0 5px;
      }
    }
  }
}

.panel {
  &.panel-checkbox {
    border-color: $gray;
    border-radius: 6px;
    padding: 20px;

    @include respond-to(xsmall) {
      padding: 10px 0;
    }

    &__header {
      display: flex;
      align-items: center;
    }
  }
}

na-bookings-list-template,
na-gibe-bookings-list-template,
voucher-report-list-template {
  .ui-grid-header-cell {

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-color: transparent;
    }
  }
}

voucher-order-report-list-template {
  .ui-grid-header-cell {

    &:nth-child(1),
    &:nth-child(2) {
      border-color: transparent;
    }
  }
}

voucher-redemption-report-list-template {
  .ui-grid-row {
    .ui-grid-cell {
      &:nth-child(3) {
        > div {
          padding: 4px 14px;
        }
      }

      a {
        text-decoration: underline;
      }
    }
  }
}

na-vouchers-report-info-panel {
  .recipient-email {
    color: #000;
  }

  .fa-pen-to-square {
    color: $na-primary;
    margin-left: 3px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: darken($na-primary, 20%);
    }
  }
}

na-channel-manager-template,
na-pms-template {

  p,
  .btn-neutral-outline {
    color: #000;
  }
}

.choose-language-field {
  margin-top: $spacing-base*3;
  margin-bottom: $spacing-base;
}

na-deposit-template {
  .input-group-btn > .btn {
    color: #000;
    font-weight: bold;
  }
}

.edit-package {
  &__other-settings {
    .alert-message {
      display: block;
      margin: -10px 0 0 0;
      padding-top: 0;

      i {
        font-size: 12px;
        top: 0;
      }
    }
  }

  &__other-settings-pre-payment {
    .field-wrapper {
      margin: 10px 0;
    }
  }
}

na-gibe-minstay-template {

  .cell-room-subcat.room-with-prepayment,
  .cell-room.room-with-pre-payment {
    background-color: #f7f7f7;
    color: #ccc;
  }

  .fa-credit-card {
    font-size: 14px !important;
    width: 37px;
    display: inline-block;
    line-height: 37px;
    text-align: center;
  }
}

na-gibe-minstay-template,
na-gibe-rates-template,
na-gibe-availability-template,
na-availability-template,
na-rates-template {
  @include respond-to(xsmall) {
    na-labeled-dropdown {
      .dropdown-menu {
        left: auto;
        right: 0;
      }
    }

    na-gibe-availability-tariff-dropdown,
    na-availability-tariff-dropdown {
      display: block;
      width: 100%;
    }
  }
}

na-package-category-template {
  .list-filter .dropdown-select {
    margin: 0;

    @include respond-to(xsmall) {
      & > .dropdown-custom {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

//alignment for resize icon in textarea
.mce-tinymce .mce-container-body .mce-resizehandle {
  right: -2px;
  bottom: 18px;
}

ibe-embed-code-template {
  .accordion-part__label {
    margin-left: 0 !important;
  }
}

// adjustments for containers alighnments on mobile
@include respond-to(fromxsmall) {

  #systemListAL,
  #companyListAL,
  #userListAL,
  #billingListAL,
  #occupantListAL,
  #inventoryListAL,
  #tariffListAL,
  #tariffGroupAL,
  #emailListAL,
  #prePostEmailsListAL,
  #discountListAL,
  #upgradeListAL,
  #extraListAL,
  #extraGroupAL,
  #customFieldsListAL,
  #uspListAL,
  #customMessagesListAL,
  #voucherSystemListAL,
  #voucherListAL,
  #voucherGroupAL,
  na-translations,
  na-bookings-list-template,
  voucher-order-report-list-template,
  voucher-report-list-template {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  na-translations,
  na-bookings-list-template,
  voucher-order-report-list-template,
  voucher-report-list-template {
    display: block;
  }

  #translationPage {
    display: block;
    overflow-x: auto;

    .ui-grid-menu-button {
      right: 20px;
    }

    .ui-grid-header-cell .sort-icons {
      position: relative;
      z-index: 10;
    }
  }

  na-availability-template {
    .grid-control {
      flex-wrap: wrap;

      > * {
        margin-bottom: 20px;
      }
    }
  }
}