.marketing-admin {
  &__rescuemetrics {
    .marketing-admin__form {
      display: block;
      line-height: 2;

      @include respond-to(fromtablet) {
        display: table;

        > * {
          display: table-cell;
        }
      }
    }

    input[type="text"] {
      max-width: 190px;
      width: 190px;
    }

    .panel-checkbox__content {
      @include respond-to(xlarge) {
        padding: 0 15px;
      }
    }
  }

  .field-wrapper {
    margin-bottom: 18px;
  }

  .panel.panel-checkbox {
    padding: 23px;
  }

  .panel-checkbox__header {
    .field-wrapper {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 0;

      label {
        margin: 0;
      }
    }

    img {
      height: 30px;
    }
  }

  .panel-checkbox__content {
    margin-top: 30px;
  }

  &__form {
    .select-test {
      max-width: 247px;
    }
  }

  &__select-tip {
    margin-bottom: 5px;
    color: $gray-text;
    font-size: 12px;
  }

  form .container .row {
    margin: 0 0 18px !important;
  }

  .btn-neutral-outline {
    color: #000;
    border-color: #000;
    min-width: 157px;
    height: 39px;

    &.btn-load.load {
      background: #ccc !important;
    }
  }

  .input-holder {
    position: relative;

    .field-message {
      max-width: 190px;
    }
  }
}
