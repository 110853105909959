.daySelector {
  .cell {
    display: inline-block;
    background-color: $bg-alt;
    width: calc(100% / 7);
    min-width: 64px;

    &.selected {
      a {
        padding: 11px 12px 11px 28px;
        background-color: #fff;
        position: relative;
        box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
        border: 1px solid #999;
        z-index: 0;
        color: #000;
        text-decoration: none;

        &:before {
          opacity: 1;
          left: 10px;
        }
      }
    }

    &.disabled {
      a {
        @extend .disabled;

        opacity: 0.3;
      }
    }
  }

  a {
    @include anim;
    display: block;
    color: #999;
    min-width: 64px;
    padding: 11px 10px;
    text-align: center;
    border: 1px solid $bg-alt;
    margin-left: -1px;
    cursor: pointer;
    font-size: 14px;
    position: relative;

    &:hover {
      text-decoration: none;
      color: #000 !important;
    }

    &:before {
      @include anim;
      @include theme-color;
      @include scale(1.2, 1.2);

      font: var(--fa-font-regular);
      content: ' \f00c';
      opacity: 0;
      left: 35px;
      position: absolute;
      line-height: 20px;
    }
  }
}

.multiday-container {
  float: right;

  .multiday {
    margin: 0 5px;
    cursor: pointer;
  }

  span.selected {
    color: $na-primary;
  }
}