.bi-page {
  padding-top: $spacing-base*2;

  & > .row {
    @include respond-to(xsmall) {
      display: flex;
      align-items: center;
    }
  }

  .row {
    margin-bottom: $spacing-lg*0.5;


    @include respond-to(fromtablet) {
      margin-bottom: $spacing-lg;
    }
  }

  &__actions-row {
    @include respond-to(xsmall) {
      margin-left: auto;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 15px;

    @include respond-to(fromxsmall) {
      margin-bottom: $spacing-base;
    }

    @include respond-to(fromtablet) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: 0;
    }
  }

  // TODO: duplicated with _dashboard.scss calendar styles
  .bi-page__select {
    padding-top: $spacing-base*0.5;

    .dropdown.dropdown-select {
      > a {
        > span {
          top: $spacing-base;
          right: $spacing-base;
          color: $text-color;

          &:before {
            font: var(--fa-font-solid);
            content: '\f078';
          }
        }
      }
    }

    .open > .dropdown-menu {
      right: -1px;
      left: auto;
      border: 0;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

      &.dropdown-menu-right {
        right: auto;
        left: -1px;
      }

      .multiselect li a {
        background: white;

        &.selected-singleSelect {
          background: $na-primary;
          border: 0;
        }

        &:not(.selected-singleSelect) {
          &:hover {
            background: $bg-alt;
          }
        }
      }
    }
  }

  // TODO: duplicated with _dashboard.scss calendar styles
  &__calendar-wrapper {
    border: 1px solid $border-color;
    background-color: #fff;
    padding: $spacing-base $spacing-lg*0.5 $spacing-lg*0.5;
    border-radius: $border-radius-lg;

    @include respond-to(fromxsmall) {
      margin-bottom: $spacing-base*2;
    }

    @extend .bi-page__select;

    .multiselect li a {
      padding: 9px 30px 9px 12px !important;
    }

    .dropdown-actions__selected-dates {
      font-weight: bold;
    }

    @include respond-to(xsmall) {
      ob-daterangepicker .picker-dropdown-container .picker {
        position: absolute !important;
        top: 110px !important;
        transform: translateX(-50%) !important;

        .date-range {
          box-shadow: none !important;
          padding: 10px 20px;
        }
      }

      date-range-picker .calendar:first-child {
        margin: 0;
      }

      .dropdown > .dropdown-custom {
        min-width: 310px;

        .dropdown-body {
          min-width: 0;
          max-height: none;
        }
      }

      .dropdown-actions__selected-dates {
        grid-area: dates;
        text-align: center;
      }

      .btn-neutral-outline {
        grid-area: cancel;
      }

      .btn-primary {
        grid-area: apply;
      }

      .dropdown-actions {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 10px;
        grid-template-areas:
          "dates dates"
          "cancel apply";
      }
    }

    @include respond-to(small) {
      margin-bottom: $spacing-lg*0.5;

      ob-daterangepicker .picker-dropdown-container .picker {
        text-align: center;
      }
    }

    @include respond-to(fromtablet) {
      .dropdown > .dropdown-custom {
        min-width: fit-content;

        .dropdown-body {
          display: flex;
          flex-direction: column;
          max-height: none;
        }
      }

      .multiselect {
        min-width: 300px;
      }

      ob-daterangepicker .picker-dropdown-container .picker {
        height: auto;
        position: static;
        border-radius: 0 !important;
        border-top: none;
        border-right: none;
        border-color: #f7f7f7;
      }

      date-range-picker {
        display: flex;
        align-items: center;
      }
    }

    @include respond-to(xlarge) {
      .dropdown > .dropdown-custom {
        .dropdown-body {
          flex-direction: row;
        }
      }
    }
  }

  &__calendar-label {
    display: inline-block;
    font-weight: semibold;
    font-size: $font-size-md;
    margin-bottom: $spacing-base;
  }

  &__choice-btns {
    @include respond-to(xsmall) {
      width: 100%;
      padding-top: $spacing-base;
    }
  }

  .na-grid-select {
    margin: 0;

    @include respond-to(fromxsmall) {
      > div {
        display: flex;
      }
    }

    .cell {
      &:after {
        left: 15px;
      }

      &.col-md-4 {
        width: 50%;

        @include respond-to(fromtablet) {
          width: auto;
        }
      }

      &.selected {
        a {
          padding-right: $spacing-base;
          padding-left: 35px;
        }
      }

      a {
        text-align: center;
        padding: 12px $spacing-base;

        &:hover {
          box-shadow: inset 0 0 4px $gray-medium;
        }
      }
    }
  }

  .dropdown {
    .dropdown-toggle {
      position: relative;
      padding-left: 20px;
      min-height: 40px;
      color: $gray-darker;

      // &:before {
      //   content: '\f073';
      //   font: var(--fa-font-light);
      //   position: absolute;
      //   left: 16px;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   font-size: $font-size-md;
      // }
    }

    .dropdown-body {
      @include respond-to(fromxsmall) {
        max-height: 300px;
      }

      @include respond-to(xlarge) {
        flex-direction: row-reverse;
      }
    }
  }

  .range-actions-holder {
    display: flex;
    flex-direction: column;
  }

  .dropdown-actions {
    padding: $spacing-lg*0.5 $spacing-base;

    @include respond-to(fromxsmall) {
      padding: $spacing-base $spacing-base $spacing-base*2;
      display: block;

      .dropdown-actions__selected-dates {
        padding: $spacing-base 0;
      }
    }
  }

  ob-daterangepicker .picker-dropdown-container .picker {
    position: static !important;

    @include respond-to(fromxsmall) {
      transform: none !important;
    }
  }

  &__email-notification {
    display: flex;
    align-items: center;
    margin-right: $spacing-base*2;
    color: #000;
    text-decoration: none;
    transition: 0.3s;
    padding: $spacing-base 0;

    @include respond-to(fromtablet) {
      padding: 0;
    }

    &:hover {
      text-decoration: none;

      .fa-file-export {
        text-decoration: none;

        &:before {
          color: $na-primary;
        }
      }
    }
  }

  .fa-file-export {
    margin-right: $spacing-base;
    color: $gray-dark;
    font-size: 20px;
    transition: 0.3s;
  }

  @include respond-to(fromtablet) {
    .dropdown-custom {
      min-width: fit-content;

      .dropdown-body {
        display: flex;
        // flex-direction: column;
        flex-direction: row-reverse;
        max-height: none;
      }
    }

    .multiselect {
      min-width: 300px;
      // padding-bottom: $spacing-base;
    }

    ob-daterangepicker .picker-dropdown-container .picker {
      height: auto;
      position: static;
      border-radius: 0 !important;
      border-top: none;
      border-right: none;
      border-color: #f7f7f7;
    }

    date-range-picker {
      display: flex;
      align-items: center;
    }
  }

  @include respond-to(xlarge) {
    .dropdown > .dropdown-custom {
      &.custom-date-selected {
        min-width: 576px;
      }
    }
  }
}
