.edit-company {
  color: black;

  &__country,
  &__city {
    md-autocomplete-wrap {
      border: 1px solid #ccc;
      cursor: pointer;
      position: relative;
      box-shadow: none;
      border-radius: 2px;

      &:after {
        content: "\e252";
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        font-size: 1.1rem;
        right: 13px;
        top: 15px;
        position: absolute;
        color: #666;
      }

      button {
        position: absolute !important;
        right: 0;
        top: 0;
        bottom: 0;
        background: #fff !important;
        z-index: 1;

        md-icon {
          transform: translate3d(-50%, -50%, 0) scale(.7) !important;
        }
      }

      input:not(.md-input) {
        color: #666;
        padding: 0 13px;
        font-family: Segoe UI, Roboto, Helvetica Neue, sans-serif;
      }
    }
  }

  .fa-location-dot-slash {
    padding-right: 10px;
  }

  label {
    color: $gray-dark;
  }
}

// general style updates for md-autocomplete
body .md-virtual-repeat-container.md-autocomplete-suggestions-container {
  min-width: 280px !important;
  max-width: 280px !important;
  z-index: 900;

  .md-autocomplete-suggestions {

    .md-autocomplete-suggestion {
      background-color: #f5f5f5;
      color: #666;
      padding: 0 30px 0 12px;

    }
  }

  .fa-location-dot {
    padding-right: 10px;
  }

  .fa-location-dot-slash {
    padding-right: 7px;
  }
}
