na-minstay-template {
  .data-row {
    .cell-data {
      &.manual-edit {
        position: relative;

        &:before {
          content: "";
          width: 8px;
          height: 8px;
          position: absolute;
          top: 0;
          right: 0;
          background-image: linear-gradient(45deg, transparent 49%, $gray-300 50%, $gray-300 100%);
        }
      }
    }
  }
}

na-closeout,
na-minstay-template {
  .data-row {
    .cell-room {
      .room-name {
        max-width: calc(100% - #{$spacing-base*3});
      }
    }
  }
}
