na-payment-summary {
  .price-details-list {
    li {
      display: flex;
      align-items: stretch;
      padding: 0;
      margin: 10px 0;

      p {
        flex: 3;
        padding: 0;
        margin: 0;
      }

      .price {
        flex: 1;
        padding: 0;
        margin: 0;
        text-align: right;

        b {
          text-align: right;
        }
      }

      &.price-details__divider {
        border-bottom: 1px solid $gray-light;
      }
    }
  }
}
