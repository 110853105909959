.ui-grid {
  box-sizing: content-box; // If bootstrap (or another included library) makes the default sizing on element "border-box", then calculations get messed up

  @include rounded($gridBorderRadius);
  @include transform(translateZ(0));

  + .list-pagination {
    margin-top: $spacing-base*4;
  }
}

.ui-grid-vertical-bar {
  position: absolute;
  right: 0;
  width: 0;
}

.ui-grid-header-cell:not(:last-child) .ui-grid-vertical-bar,
.ui-grid-cell:not(:last-child) .ui-grid-vertical-bar {
  width: $gridBorderWidth;
}

.ui-grid-scrollbar-placeholder {
  background-color: transparent;
}

.ui-grid-header-cell:not(:last-child) .ui-grid-vertical-bar {
  background-color: $headerVerticalBarColor;
}

.ui-grid-cell:not(:last-child) .ui-grid-vertical-bar {
  background-color: $verticalBarColor;
}

.ui-grid-header-cell:last-child .ui-grid-vertical-bar {
  right: -1px; // TODO(c0bra): Should this be grid width? Test column resizing with custom grid border width
  width: $gridBorderWidth;
  background-color: $headerVerticalBarColor;
}

.ui-grid-clearfix {

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.ui-grid-invisible {
  visibility: hidden;
}

// Wraps the contents inside the grid directive with a relatively-positioned element so that all absolute elements are positioned relative to the grid, and not the page
.ui-grid-contents-wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  .grid-not-information {
    display: block;
    padding: 45px;
    border: 1px solid $border-color;
    width: 100% !important;
    left: 0;
    margin-top: 20px;

    * {
      text-align: center !important;
      width: 100% !important;
      display: block;
    }

    td {
      border: 0 !important;
      width: 100%;

      i {
        margin-bottom: $spacing-base*2;
      }
    }
  }
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.ui-grid-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

//fixes for specific tables
#bookinglistpaginationFL {
  margin-top: 25px;
}

#bookinglistpagination,
#voucherOrderListPagination,
#voucherListPagination {
  .ui-grid-canvas {
    .loader-wrapper.loader-sm {
      width: 100%;
      margin-top: -15px;

      svg {
        height: 17px;
        width: 17px;
      }
    }
  }
}

#bookinglistpagination,
#translationPage,
#voucherOrderListPagination,
#voucherListPagination,
na-pci-proxy-list-component {
  .ui-grid-header-cell-wrapper {
    height: auto;
  }

  .ui-grid-header-canvas {
    height: auto !important;

    .ui-grid-cell-contents {
      padding: 0;
    }
  }

  .ui-grid-header-canvas,
  .ui-grid-canvas {
    margin: 0;
    width: 100%;
  }

  .ui-grid-canvas {
    > div:last-child {
      box-sizing: border-box;
      display: block;
      border-bottom: 1px solid #e5e5e5;
    }

    .ui-grid-row {
      height: auto;

      > div {
        display: flex;

        > div.ui-grid-cell:last-child:not(:first-child) {
          border-right: none;
        }
      }

      .ui-grid-cell {
        display: flex;
        align-items: center;
        padding: 5px;
      }
    }
  }
}

.na-grid-table-title {
  display: inline-flex;
  max-width: calc(100% - 50px);
  align-items: center;

  &.selected span {
    color: royalblue;
  }

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.grid-table-dropdown.dropdown-menu {
  border: 0;
  height: 42px;
}
