/*  Breakpoints usage

@include respond-to(xsmall) { ... }
@include respond-to(small) { ... }
@include respond-to(medium) { ... }
@include respond-to(large) { ... }

 */

$value-small: 768px;
$value-medium: 992px;
$value-large: 1200px;
$value-xlarge: 1366px;

@mixin respond-to($breakpoint) {

  //max-width: 991px
  @if $breakpoint =="fromxsmall" {
    @media (max-width: $value-medium - 1) {
      @content;
    }
  }

  //mobile landscape
  @if $breakpoint =="landscape" {
    @media screen and (orientation: landscape) {
      @content;
    }
  }

  //max-width: 767px
  @if $breakpoint =="xsmall" {
    @media (max-width: $value-small - 1) {
      @content;
    }
  }

  //min-width: 768px and max-width: 991px
  @else if $breakpoint =="small" {
    @media (min-width: $value-small) and (max-width: $value-medium - 1) {
      @content;
    }
  }

  //min-width:992px max-width: 1199px
  @else if $breakpoint =="medium" {
    @media (min-width: $value-medium) and (max-width: $value-large - 1) {
      @content;
    }
  }

  // min-width 768px
  @else if $breakpoint =="fromtablet" {
    @media (min-width: $value-small) {
      @content;
    }
  }

  //min-width:1200px max-width: 1365px
  @else if $breakpoint =="large" {
    @media (min-width: $value-large) and (max-width: $value-xlarge - 1) {
      @content;
    }
  }

  @else if $breakpoint =="xlarge" {
    @media (min-width: $value-xlarge) {
      @content;
    }
  }
}

@mixin reset {
  font-weight: inherit;
  color: $text-color;
  padding: inherit;
  border: none;
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
  border-radius: 0;
}

@mixin div-fill {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}

@mixin bg-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin anim($speed: 300ms, $ease: ease) {
  transition: $speed $ease;
}

@mixin anim-custom($el: all, $speed: 300ms, $ease: ease) {
  transition: $el $speed $ease;
}

@mixin anim-reveal($type: 'fadeIn', $duration: 1s) {
  @if $type =='fadeIn' {
    @keyframes reveal {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }

  @if $type =='fadeInBottom' {
    @keyframes reveal {
      from {
        opacity: 0;
        transform: translate(0, 15px);
      }

      to {
        opacity: 1;
        transform: translate(0, 0);
      }
    }
  }

  @if $type =='zoomIn' {
    @keyframes reveal {
      from {
        transform: scale(0, 0);
      }

      to {
        transform: scale(1, 1);
      }
    }
  }

  animation: reveal $duration ease;
}

@mixin scrollbar {

  $scrollbar-width: 5px;
  $scrollbar-radius: 0;

  /* Let's get this party started */
  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    @include anim;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.1);
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

@mixin resetListStyle() {

  ol,
  ul {
    li {
      margin-left: 15px;
    }
  }

  ol {
    li {
      padding-left: 5px;
      list-style: decimal;
    }
  }

  ul {
    li {
      margin-left: 19px;
      list-style: disc;
    }
  }
}

@mixin spinning($speed: 500ms) {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  animation: spin $speed linear infinite;
}

@mixin fadeIn($speed: 10000ms) {
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  /*   -webkit-animation: fadeIn $speed linear infinite;
-moz-animation: fadeIn $speed linear infinite;
-o-animation: fadeIn $speed linear infinite;
-ms-animation: fadeIn $speed linear infinite;
animation: fadeIn $speed linear infinite; */
}

@mixin fadeOut($speed: 10000ms) {
  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  animation: fadeOut $speed linear;
}

@mixin box-shadow($horizontal, $vertical, $blur, $spread, $color) {
  box-shadow: $horizontal $vertical $blur $spread $color;
}

//
@mixin boxShadowReports() {
  border-radius: 6px;
  border: 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  padding: 18px;
}

// @include gradient-hor(#000000,1,#FFFFFF,1)
@mixin gradient-hor($color1, $opacity1, $color2, $opacity2) {
  background: rgba($color1, 0);
  background: -moz-linear-gradient(left, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba($color1, $opacity1)), color-stop(100%, rgba($color2, $opacity2)));
  background: -webkit-linear-gradient(left, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: -o-linear-gradient(left, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: -ms-linear-gradient(left, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: linear-gradient(to right, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$color1', endcolorstr='$color2', gradienttype=1);
}

@mixin auto-margin {
  margin: 0 auto;
}

@mixin box-sizing {
  box-sizing: border-box;
}

// @include gradient-ver(#000000,1,#FFFFFF,1)
@mixin gradient-ver($color1, $opacity1, $color2, $opacity2) {
  background: rgba($color1, 0);
  background: -moz-linear-gradient(top, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba($color1, $opacity1)), color-stop(100%, rgba($color2, $opacity2)));
  background: -webkit-linear-gradient(top, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: -o-linear-gradient(top, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: -ms-linear-gradient(top, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: linear-gradient(to bottom, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$color1', endcolorstr='$color2', gradienttype=0);
}

@mixin noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin NALogoGradient($rotate: 270deg) {
  background: #8C00EF;
  background: -moz-linear-gradient(270deg, #8C00EF 0%, #6743F2 78%, #4285F5 100%) 0% 0% no-repeat padding-box;
  background: -webkit-linear-gradient(270deg, #8C00EF 0%, #6743F2 78%, #4285F5 100%) 0% 0% no-repeat padding-box;
  background: -o-linear-gradient(270deg, #8C00EF 0%, #6743F2 78%, #4285F5 100%) 0% 0% no-repeat padding-box;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8C00EF",endColorstr="#4285F5",GradientType=1);
}

@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);

  filter: alpha(opacity=$opacity-ie);
}

@mixin rotate($deg) {
  transform: rotate($deg);
}

@mixin border-radius($radius) {
  border-radius: $radius;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

@mixin scale($width, $height) {
  transform: scale($width, $height);
}

/*
Usage
@include arrow-left(7px,50%,#cccccc,#ffffff);
 */
@mixin arrow-left($size, $top, $border-color, $bg-color) {

  &:after,
  &:before {
    right: 100%;
    top: $top;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-right-color: $bg-color;
    border-width: $size;
    margin-top: -($size);
  }

  &:before {
    border-right-color: $border-color;
    border-width: $size + 1;
    margin-top: -($size+1);
  }
}

@mixin arrow-right($size, $top, $border-color, $bg-color) {

  &:after,
  &:before {
    left: 100%;
    top: $top;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-left-color: $bg-color;
    border-width: $size;
    margin-top: -($size);
  }

  &:before {
    border-left-color: $border-color;
    border-width: $size + 1;
    margin-top: -($size+1);
  }
}

@mixin arrow-top($size, $left, $border-color, $bg-color) {

  &:after,
  &:before {
    bottom: 100%;
    left: $left;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-bottom-color: $bg-color;
    border-width: $size;
    margin-left: -($size);
  }

  &:before {
    border-bottom-color: $border-color;
    border-width: $size + 1;
    margin-left: -($size+1);
  }
}

@mixin arrow-bottom($size, $left, $border-color, $bg-color) {

  &:after,
  &:before {
    top: 100%;
    left: $left;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: $bg-color;
    border-width: $size;
    margin-left: -($size);
  }

  &:before {
    border-top-color: $border-color;
    border-width: $size + 1;
    margin-left: -($size+1);
  }
}

@mixin chunky() {
  font-weight: bold;
  text-transform: uppercase;
}

@mixin overlay($color) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color;
}

@mixin fx-elevate($distance, $blur, $opacity) {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0);

  &:hover {
    box-shadow: 0 $distance $blur 0 rgba(0, 0, 0, $opacity);
  }
}

// Use this to not add hover effect themed colour
// Usage: @include theme-bg('hoverOff')
@mixin theme-bg($hoverOff: '', $color: $na-primary) {

  // // cms
  // body.cms & { 
  //   background-color: $color;

  //   &:hover {
  //     @if $hoverOff =='hoverOff' {}

  //     @else {
  //       background-color: darken($color, 5%) !important;
  //     }
  //   }
  // }

  // null - ibe - cms
  body &,
  body.ibe body.cms & {
    background-color: $color;

    @include theme-border-menu-top;

    &:hover {
      @if $hoverOff =='hoverOff' {}

      @else {
        background-color: darken($color, 5%) !important;
      }
    }
  }

  // chm
  body.chm & {
    background-color: $sys-color-3 !important;

    &:hover {
      @if $hoverOff =='hoverOff' {}

      @else {
        background-color: darken($sys-color-3, 5%) !important;
      }
    }
  }

  //ibe-chm
  body.ibe-chm & {
    background-color: $sys-color-4 !important;

    &:hover {
      @if $hoverOff =='hoverOff' {}

      @else {
        background-color: darken($sys-color-4, 5%) !important;
      }
    }
  }
}

// @mixin theme-bg-darker {

//   // cms
//   body.cms & {
//     background-color: $na-primary !important;
//   }

//   // null - ibe - cms
//   body &,
//   body.ibe body.cms & {
//     background-color: $na-primary-darker !important;
//     color: #111;
//   }

//   // chm
//   body.chm & {
//     background-color: darken($sys-color-3, 5%) !important;
//   }

//   //ibe-chm
//   body.ibe-chm & {
//     background-color: darken($sys-color-4, 5%) !important;
//   }
// }

@mixin theme-border-menu-top {
  border-bottom: 1px solid $gray-border;
}

/* stylelint-disable-next-line no-duplicate-selectors */
@mixin theme-color { /* stylelint-disable-line no-duplicate-selectors */
  // body.cms & {
  //   color: $na-primary;

  //   &:hover {
  //    
   color: $na-primary-darker;
  //   }
  // }

  /* stylelint-disable no-duplicate-selectors */
  body &
  // body.gs &,
  // body.ibe & 
  { /* stylelint-disable-line no-duplicate-selectors */
    color: $na-primary;

    &:hover {
      color: $na-primary-darker;
    }
  }
  /* stylelint-enable no-duplicate-selectors */

  body.chm & {
    color: $sys-color-3;

    &:hover {
      color: $na-primary-darker;
    }
  }

  body.ibe-chm & {
    color: $sys-color-4;

    &:hover {
      color: $na-primary-darker;
    }
  }
}

/* stylelint-disable-next-line no-duplicate-selectors */
@mixin theme-border { /* stylelint-disable-line */
  // body.cms & {
  //   border-color: $na-primary !important;
  // }

  /* stylelint-disable-line no-duplicate-selectors */
  body &
  // body.gs &,
  // body.ibe & 
  {  /* stylelint-disable-line no-duplicate-selectors */
    border-color: $na-primary !important;
  }

  // body.chm & {
  //   border-color: $na-primary !important;
  // }

  // body.ibe-chm & {
  //   border-color: $na-primary !important;
  // }
}

@mixin theme-border-secondary {
  // body.cms & {
  //   border-color: $na-secondary !important;
  // }

  body &
  // body.gs &,
  // body.ibe & 
  {
    border-color: $na-secondary !important;
  }

  // body.chm & {
  //   border-color: $na-secondary !important;
  // }

  // body.ibe-chm & {
  //   border-color: $na-secondary !important;
  // }
}

@mixin theme-border-top {
  // body.cms & {
  //   border-top-color: $na-primary !important;
  // }

  body &
  // body.gs &,
  // body.ibe & 
  {
    border-top-color: $na-primary !important;
  }

  body.chm & {
    border-top-color: $sys-color-3 !important;
  }

  body.ibe-chm & {
    border-top-color: $sys-color-4 !important;
  }
}

@mixin theme-border-left {
  // body.cms & {
  //   border-left-color: $na-primary !important;
  // }

  body &
  // body.gs &,
  // body.ibe & 
  {
    border-left-color: $na-primary !important;
  }

  body.chm & {
    border-left-color: $sys-color-3 !important;
  }

  body.ibe-chm & {
    border-left-color: $sys-color-4 !important;
  }
}

@mixin theme-border-right {
  // body.cms &,
  body &
  // body.gs &,
  // body.ibe & 
  {
    border-right-color: $na-primary !important;
  }

  body.chm & {
    border-right-color: $sys-color-3 !important;
  }

  body.ibe-chm & {
    border-right-color: $sys-color-4 !important;
  }
}

@mixin theme-border-bottom {
  // body.cms & {
  //   border-bottom-color: $na-primary !important;
  // }

  body &
  // body.gs &,
  // body.ibe & 
  {
    border-bottom-color: $na-primary !important;
  }

  body.chm & {
    border-bottom-color: $sys-color-3 !important;
  }

  body.ibe-chm & {
    border-bottom-color: $sys-color-4 !important;
  }
}

@mixin disabled {
  background-color: $gray-lighter;

  &,
  & * {
    color: $gray;
  }

  a {
    pointer-events: none;
  }
}

@mixin select_disabled {
  background-color: $gray-lighter;

  &,
  & * {
    color: $gray;
  }
}

@mixin mdi-icon($code) {
  font-family: $font-icon-material;
  content: mdi($code);
}

@mixin panelDefault() {
  border-radius: $border-radius-base;
  border: 1px solid #ddd;
  padding: $spacing-base;
}

@mixin expandCheckIcon($top, $right) {

  &:after {
    font: var(--fa-font-regular);
    content: '\f00c';
    pointer-events: none;
    position: absolute;
    right: $right;
    top: $top;
    color: #fff;
    font-size: 16px;

    @include scale(0, 0);
    @include anim;
  }

  
  &.selected { 
    background-color: #fff;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    z-index: 100;

    &,
    * {
      color: #000;
    }

    &:after {
      @include scale(1.1, 1.1);

      color: $na-primary-darker;
    }
  }

  &.selected-singleSelect {
    @include theme-bg;

    &,
    * {
      color: #fff;
    }
  }
}

@mixin input-placeholder-color($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin word-break {
  white-space: normal;
  word-wrap: break-word;
  /* IE */
  overflow-wrap: break-word;
}

@mixin text-overflow-mixin($size: $font-size, $line-height: 1.5, $lines-to-show: 2) {
  display: inline-flex;
  /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  height: $size*$line-height*$lines-to-show;
  /* Fallback for non-webkit */
  font-size: $size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}

@mixin anim($speed: 300ms, $ease: ease) {
  transition: $speed $ease;
}
