.naMessage {
  position: fixed;
  top: 70px;
  right: 0;
  width: 400px;
  z-index: 699;
}

// New Alert Messages style
.alert-message-wrap {
  background: $alert-info-bg-color;
  display: block;
  padding: $spacing-base*0.5 $spacing-base;
  border-radius: 5px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
}

//INFO (BLUE)
.alert-message {
  color: $info-color;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  font-size: $font-size-sm;

  &.modal-alert {
    display: block;
    position: relative;
    padding: 15px 0 15px 45px;
    background-color: $gray-lighter;
    margin: -15px -15px 0;
    width: auto;

    .fa-exclamation-circle {
      font-size: 23px;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  a {
    text-decoration: underline;
    color: $text-color !important;
    padding-left: $spacing-base*0.5;

    &:hover {
      color: $text-color;
    }
  }

  i {
    margin-right: $spacing-base*0.5;
    font-size: 20px;
    line-height: 0;
    top: 2px;
    position: relative;
    color: $info-color;
  }

  .alert-message-wrap & {
    color: $text-color;
  }
}

//ERROR
.alert-red {
  &.alert-message-wrap {
    background: $alert-error-bg-color;
  }

  .alert-message {
    i {
      color: $error-color;
    }
  }

  //if without wrap
  &.alert-message,
  &.alert-message i {
    color: $error-color;
  }
}

//WARNING
.alert-yellow {
  &.alert-message-wrap {
    background: $alert-warning-bg-color;
  }

  .alert-message {
    i {
      color: $warning-color;
    }
  }

  //if without wrap
  &.alert-message,
  &.alert-message i {
    color: $warning-color;
  }
}

body.route-dashboard.error na-top-messages {
  position: relative;
  background: #8c00ef;
  display: block;
  margin: -#{$spacing-lg} -#{$spacing-lg} 0;
  padding: $spacing-lg $spacing-lg 0;
  z-index: 1;
}

na-inline-wrapped-notification {
  display: block;
  max-width: 1320px;
  margin: 0 auto $spacing-lg*0.5;

  @include respond-to(fromxsmall) {
    margin: 0 auto;
    padding: $spacing-base 0;
  }
}
