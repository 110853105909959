.ui-grid-render-container {
  position: inherit;
  margin-bottom: 20px;

  @include scrollbar;
  @include border-radius(0, $gridBorderRadius, $gridBorderRadius, 0);

  // Prevent an outline from showing if we focus the render container element
  &:focus {
    outline: none;
  }
}

.ui-grid-viewport {
  // overflow: auto; // TODO(c0bra): turn back on when virtual repeater is hooked up
  min-height: 70px;
  height: 70vh;
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  &:focus {
    outline: none !important;
  }
}

.ui-grid-canvas {
  position: relative;
  padding-top: 1px; //to prevent canvas from absorbing the 1st rendered row's margin
  margin-bottom: $spacing-base*2; // this prevents scroll bug when the table has horizontal scroll
}

.ui-grid-row {
  clear: both; //prevent columns mixing after expand

  &:last-child .ui-grid-cell {
    border-bottom-color: $border-color;
    border-bottom-style: solid;
  }
}

.ui-grid-no-row-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 10%;

  @extend .ui-grid-top-panel-background;

  @include border-radius($gridBorderRadius);

  border: $gridBorderWidth solid $borderColor;
  font-size: 2em;
  text-align: center;

  > * {
    position: absolute;
    display: table;
    margin: auto 0;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.66;
  }
}
