.your-subscription {
  &.pay-now-box {
    margin-top: $spacing-base*2;
    padding: $spacing-base;
    border: 1px solid $border-color;
    border-radius: $global-radius;
  }
}

.your-subscription,
.recent-invoices {
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.invoice-settings {
  .table.list-table.accordion {
    na-accordion-part {
      margin: 0;
      padding: 14px 18px;

      @include respond-to(xsmall) {
        padding: $spacing-base+5 $spacing-base;
      }

      &:first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:nth-child(2) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: -1px;
      }

      label:first-of-type {
        span {
          font-size: $font-size-md;
        }
      }

      .type-group {
        .input-group-addon {
          width: auto;

          .btn-sm {
            width: 45px;
          }
        }

        .input-group-btn {
          width: auto;
        }
      }

      .btn-inactivated {
        border-right: 1px solid $border-color-dark;
      }

      .input-group {
        .input-group-addon {
          min-width: 45px;
        }
      }
    }
  }

  .type-row {
    h6 {
      padding-bottom: 8px;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    display: block;
    background-color: $gray;
    margin: 25px 0;
  }

  label {
    font-weight: 500 !important;
  }
}

.billing-tab {
  .title-link {
    color: $gray-medium;
    font-size: $font-size-sm;
  }

  .field-wrapper {
    margin-bottom: 25px;
  }

  h2 {
    display: inline-block;
    margin-top: 0 !important;
  }

  &-edit {
    h2 {
      margin-top: 50px;
    }
  }

  .info-icon {
    margin-left: 3px;
  }

  .fa-user-astronaut {
    padding: 0 2px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -5px;
    color: $gray-medium;
  }

  .fa-check {
    position: absolute;
    top: 9px;
    left: 9px;
    color: #fff;
    font-size: 17px;
  }

  .fa-file-invoice-dollar,
  .fa-retweet-alt {
    font-size: 24px;
    margin-bottom: $spacing-base;
  }

  .btn-select {
    width: 100%;
    min-width: 385px;
    border: 1px solid $gray;
    border-radius: $border-radius-lg;
    padding: 35px 0 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 200px;
    background: transparent;
    transition: 0.3s;
    position: relative;

    @include respond-to(xsmall) {
      min-width: auto;
      margin-bottom: $spacing-base*2;
    }

    @include respond-to(fromxsmall) {
      margin-bottom: $spacing-base*2;
    }

    &.btn-selected {
      box-shadow: 0 0 0 2px $primary-color;
      opacity: 1;
      cursor: default;
      border: none;

      &:before {
        content: '';
        position: absolute;
        top: -24px;
        left: -34px;
        transform: rotate(-45deg);
        width: 80px;
        height: 60px;
        background-color: $primary-color;
        transition: 0.3s;
      }

      .fa-file-invoice-dollar,
      .fa-retweet-alt {
        transition: 0.3s;
        color: $primary-color;
      }
    }

    &[disabled] {
      pointer-events: none;
    }
  }

  &__method-title {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: $spacing-base * 2;
    text-transform: none;
  }

  &__title-description {
    white-space: break-spaces;
    text-transform: none;
    font-size: $font-size;
  }

  &__sage-id {
    display: flex;
    margin-right: -11px;
    margin-left: -11px;
    border-top: 1px solid $gray;
    width: 100%;

    img {
      width: 36px;
    }

    .billing-tab__sage-id-text {
      font-size: 13px;
      text-transform: uppercase;
      color: #00DC06 !important;
      font-weight: bold;
    }

    .fa-edit {
      min-width: 35px;
      height: 100%;
      line-height: 35px;
      background-color: $gray-lighter;
      text-align: center;
      border-left: 1px solid $gray;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($gray-lighter, 10%);
      }
    }
  }

  &__sage-id-content {
    padding: 0 10px;
    width: calc(100% - 35px);
    line-height: 35px;
  }
}

.billing-paynow {
  padding-right: 70px;
  list-style: none;
}

.billing-list {
  padding: 0;

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid $border-color;

    &.date {
      padding-top: 15px;
      padding-bottom: 0;
      list-style: none;

      .billing-date__due-date {
        padding-left: 0 !important;
        list-style: none;
        color: $gray-light;
        font-size: 12px;
      }

      .billing-date__total {
        padding-right: 0 !important;
        list-style: none;

        .billing-date__total-description {
          margin-bottom: 0;
        }

        .billing-date__total-price {
          font-size: 32px;
          font-weight: bold;
          line-height: 1.2;
        }
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    p {
      margin-bottom: 0;
      padding: 0;
    }

    .billing-list__description {
      width: 100%;

      &-name {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .billing-list__link {
          display: inline-block;
          margin-left: $spacing-base;
        }
      }

      [class^="billing-list__status"] {
        border-radius: $border-radius;
        padding: 2px 5px;
        font-size: 10px;
        color: $text-color-light;
        background-color: rgba($text-color-light, 0.1);
        letter-spacing: 0.3px;
        font-weight: 600;
        white-space: nowrap;
      }

      .billing-list__status_unpaid {
        background: #FFEAEA;
        color: #F94646;
      }

      .billing-list__status_part_paid,
      .billing-list__status_void {
        color: #009CD8;
        background: #E8F9FF;
      }

      span {
        font-size: 12px;
        color: $gray-medium;

        &.billing-new__system {
          border: 1px solid $border-color;
          border-radius: 3px;
          padding: 5px;
          margin-left: 10px;
          text-transform: uppercase;
          font-weight: bolder;

          &.small {
            font-size: 9px;
          }
        }
      }
    }

    p.billing-list__price {
      font-weight: 700;
      font-size: 18px;
    }

    > *:last-child {
      text-align: right;
    }

    a {
      cursor: pointer;
      color: black;
    }
  }

  &__item--summary {
    flex-direction: column;
    align-items: flex-end;

    .billing-list__price {
      font-size: 32px;
      margin-top: 10px;
    }
  }
}

.billing-list__status_paid,
.billing-list__status_part_paid,
.billing-list__status_unpaid,
.billing-list__status_void,
.billing-list__draft,
.billing-list__disputed {
  border: 1px solid;
  padding: 2px 5px;
  border-radius: $border-radius;
  font-weight: semibold;
  font-size: 10px;
}

.billing-list__status_paid {
  border-color: #A7E7B1;
  color: #43BF57;
  background-color: #E2FFE6;
}

.billing-list__status_unpaid {
  color: #F00;
  background: #FEE;
  border-color: #FF4F4F;
}

.billing-list__status_part_paid {
  color: #FFC300;
  background: #FFFAE6;
  border-color: #FFEB96;
}

.billing-list__status_void {
  color: #9C9C9C;
  background-color: #F1F1F1;
  border-color: #D3D3D3;
}

.billing-list__draft {
  color: #009CD8;
  background-color: #E8F9FF;
  border-color: #A2DDF3;
}

.billing-list__disputed {
  color: #FF9102;
  background-color: #FFF8EB;
  border-color: #FFD793;
}

.upcoming-payments {
  .billing-list__item {
    justify-content: space-between;

    p.billing-list__description {
      display: flex;
      justify-content: space-between;
      width: 100%;
      text-align: right;
      flex: 0 0 65%;

      span {
        text-align: left;
      }
    }

    p.billing-list__price {
      font-size: 32px;
      flex: 0 0 30%;
      text-align: right;

      span {
        font-size: 14px;
        display: block;
        font-weight: 400;
        margin-bottom: 10px;
      }
    }
  }
}

na-adyen {

  [type="radio"]:not(:checked),
  [type="radio"]:checked {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:not(:checked) + span.chckt-pm__name,
  [type="radio"]:checked + span.chckt-pm__name {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
    line-height: 30px;
    margin: 0;
    padding-top: 0;
  }

  /* radio aspect */
  [type="radio"]:not(:checked) + span.chckt-pm__name:before,
  [type="radio"]:checked + span.chckt-pm__name:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 50%;
  }

  /* checked mark aspect */
  [type="radio"]:not(:checked) + span.chckt-pm__name:after,
  [type="radio"]:checked + span.chckt-pm__name:after {
    content: '';
    position: absolute;
    top: 9px;
    left: 5px;
    border-radius: 50%;
    font-size: 1em;
    line-height: 0.5em;
    background: $na-primary;
    transition: all 0.2s;
    width: 10px;
    height: 10px;
    display: block;
  }

  /* checked mark aspect changes */
  [type="radio"]:not(:checked) + span.chckt-pm__name:after {
    opacity: 0;
    transform: scale(0);
  }

  [type="radio"]:checked + span.chckt-pm__name:after {
    opacity: 1;
    transform: scale(1);
  }

  /* disabled radio */
  [type="radio"]:disabled:not(:checked) + span.chckt-pm__name:before,
  [type="radio"]:disabled:checked + span.chckt-pm__name:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
  }

  input {
    margin-right: 10px;
  }

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }

  [type="checkbox"]:not(:checked) + span,
  [type="checkbox"]:checked + span {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
    line-height: 30px;
    margin: 0;
  }

  /* checkbox aspect */
  [type="checkbox"]:not(:checked) + span:before,
  [type="checkbox"]:checked + span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 20px;
    height: 20px;
    border: 1px solid $border-color;
    background: #fff;
    border-radius: $global-radius;
  }

  /* checked mark aspect */
  [type="checkbox"]:not(:checked) + span:after,
  [type="checkbox"]:checked + span:after {
    font: var(--fa-font-light);
    content: '\f00c';
    position: absolute;
    top: 3px;
    left: 4px;
    line-height: 0.5em;
    color: $na-primary;
    font-size: $font-size-base;
    transition: all 0.2s;
    display: block;
  }

  /* checked mark aspect changes */
  [type="checkbox"]:not(:checked) + span:after {
    opacity: 0;
    transform: scale(0);
  }

  [type="checkbox"]:checked + span:after {
    opacity: 1;
    transform: scale(1);
  }

  /* disabled checkbox */
  [type="checkbox"]:disabled:not(:checked) + span:before,
  [type="checkbox"]:disabled:checked + span:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
  }
}

//this will need to be an separated file
na-stripe2 {
  //part of the style for this component is within _accordion.scss component
  $spacing-base: 10px;

  .card-list {
    .card-item.card-details {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.card-item--new-card {
        flex-direction: column;
        align-items: flex-end;
        transition: all 0.3s ease-in-out;

        &.loading {
          cursor: not-allowed;

          > * {
            pointer-events: none;
          }

          .btn-cancel {
            opacity: 0.5;
          }
        }

        .btn {
          padding: 10px 15px;
          color: black;

          &.btn-cancel {
            background-color: transparent !important;
            border-color: $gray-dark;
            color: $gray-dark;
          }

          &.btn-load {
            font-size: 11px;
            border: 1px solid $na-primary;
            border-radius: 3px;
            min-width: auto;
            height: 33px;
          }
        }
      }
    }

    .card-item__delete {
      cursor: pointer;
      color: $text-color;
      font-size: 10px;
    }
  }

  .fadeIn {
    transform-origin: top;
    animation: fadeIn 800ms ease;
  }

  .fade {
    transform-origin: bottom;
    animation: fadeIn 500ms ease reverse;
  }

  #source-element {
    width: 100%;
    margin-bottom: $spacing-base*2;
    padding: $spacing-base+5;
    box-shadow: 0 1px 2px rgba(black, 0.15);
  }

  .listofcards__add {
    background: transparent;
    border: 0;
    color: $gray-dark;
    padding: 10px 0;
    transition: color 200ms ease;

    i {
      margin-right: $spacing-base;
    }

    &:hover {
      color: black;
    }

    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  .card-item__disclaimer,
  .card-item__error-message {
    width: 100%;
    text-align: left;
  }

  .card-item__disclaimer {
    margin-bottom: $spacing-base;

    h3 {
      margin: 0;
      padding: 0;
    }
  }

  .card-item__error-message {
    color: $color-danger;
    font-size: 12px;
    margin-top: -20px;

    i {
      font-size: 16px;
      margin-right: $spacing-base*0.5;
      position: relative;
      top: 1px;
    }
  }
}

//animations
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.billing-booking_suite_box {
  border-radius: 6px;
  text-align: center;
  margin: 0;
  display: block;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 100%;
  padding: 50px;
  background-color: $gray-lighter;

  img {
    width: 100%;
    max-width: 130px;
    height: auto;
  }

  .tiles-add-label {
    &:before {
      font: var(--fa-font-regular);
      content: '\f067';
      font-weight: normal;
      font-size: 45px;
      clear: both;
      display: block;
      padding-bottom: 10px;
    }
  }
}

.modal-reactivation {
  color: black;

  .modal-body {
    display: flex;
    justify-content: space-around;
    min-height: 350px;

    &.div-load {
      align-items: center;
    }
  }

  .form-style__col {
    width: 50%;
    display: inline-block;

    &:nth-child(1) {
      width: 40%;
    }

    h2 {
      margin-top: 0;
    }
  }

  .your-subscription.pay-now-box {
    border: none;
    margin: 0;
    padding: 0;
  }

  .close-modal {
    float: right;
    cursor: pointer;

    i {
      color: $gray;
    }
  }
}

.comission-actions {
  .booking-type:first-of-type,
  .minimumMonthlyFee {
    margin-left: -15px !important;

    @include respond-to(xsmall) {
      margin: 0 !important;
    }
  }
}
