#translationPage {
  #translationPageFL {
    margin-top: 45px;
  }

  .ui-grid-canvas {
    .ui-grid-row {
      .ui-grid-cell {
        width: 100%;
        max-width: inherit;
        justify-content: flex-start;

        textarea {
          border: 0;
          outline: none;
          resize: none;
        }
      }
    }
  }

  .ui-grid-header-cell-wrapper {
    width: 100%;
  }
}
