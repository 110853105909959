
.ui-grid-pinned-container {
  position: absolute;
  display: inline;
  top: 0;

  &.ui-grid-pinned-container-left {
    float: left;
    left: 0;
  }

  &.ui-grid-pinned-container-right {
    float: right;
    right: 0;
  }

  &.ui-grid-pinned-container-left .ui-grid-header-cell:last-child {
    box-sizing: border-box;
    border-right: $gridBorderWidth solid;
    border-width: $gridBorderWidth;
    border-right-color: darken($border-color, 5%);
  }

  &.ui-grid-pinned-container-left .ui-grid-cell:last-child {
    box-sizing: border-box;
    border-right: $gridBorderWidth solid;
    border-width: $gridBorderWidth;
    border-right-color: $border-color;
  }

  &.ui-grid-pinned-container-left .ui-grid-header-cell:not(:last-child) .ui-grid-vertical-bar, .ui-grid-cell:not(:last-child) .ui-grid-vertical-bar {
    width: $gridBorderWidth;
  }

  &.ui-grid-pinned-container-left .ui-grid-header-cell:not(:last-child) .ui-grid-vertical-bar {
    background-color: $headerVerticalBarColor;
  }

  &.ui-grid-pinned-container-left .ui-grid-cell:not(:last-child) .ui-grid-vertical-bar {
    background-color: darken($verticalBarColor, 15%);
  }

  &.ui-grid-pinned-container-left .ui-grid-header-cell:last-child .ui-grid-vertical-bar {
    right: -1px; // TODO(c0bra): Should this be grid width? Test column resizing with custom grid border width
    width: $gridBorderWidth;
    background-color: darken($headerVerticalBarColor, 15%);
  }

  &.ui-grid-pinned-container-right .ui-grid-header-cell:first-child {
    box-sizing: border-box;
    border-left: $gridBorderWidth solid;
    border-width: $gridBorderWidth;
    border-left-color: darken($border-color, 5%);
  }

  &.ui-grid-pinned-container-right .ui-grid-cell:first-child {
    box-sizing: border-box;
    border-left: $gridBorderWidth solid;
    border-width: $gridBorderWidth;
    border-left-color: $border-color;
  }

  &.ui-grid-pinned-container-right .ui-grid-header-cell:not(:first-child) .ui-grid-vertical-bar, .ui-grid-cell:not(:first-child) .ui-grid-vertical-bar {
    width: $gridBorderWidth;
  }

  &.ui-grid-pinned-container-right .ui-grid-header-cell:not(:first-child) .ui-grid-vertical-bar {
    background-color: $headerVerticalBarColor;
  }

  &.ui-grid-pinned-container-right .ui-grid-cell:not(:last-child) .ui-grid-vertical-bar {
    background-color: darken($verticalBarColor, 15%);
  }

  &.ui-grid-pinned-container-first .ui-grid-header-cell:first-child .ui-grid-vertical-bar {
    left: -1px; // TODO(c0bra): Should this be grid width? Test column resizing with custom grid border width
    width: $gridBorderWidth;
    background-color: darken($headerVerticalBarColor, 15%);
  }
}
