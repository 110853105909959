body,
body.gs,
body.ibe {
  ul.uib-datepicker-popup.dropdown-menu {
    padding: 15px;

    table {
      width: 100px !important;

      th {
        td {
          background-color: red;
          width: 20px !important;
        }
      }

      tr {
        th, td {
          border: 0;

          button.uib-title {
            width: 208px !important;
            margin-top: -23px !important;
            padding-top: 0 !important;
            border: 0;
          }

          button {
            &.btn {
              &.btn-default {
                &.btn-sm {
                  border-radius: 0;
                  -moz-border-radius: 0;
                  -webkit-border-radius: 0;
                  background-color: #fff !important;
                  color: #000 !important;
                  border: 1px solid #efefef !important;

                  span {
                    color: #000;
                    height: 25px;
                    width: 22px;
                    display: block;
                    line-height: 27px;
                  }
                }
              }
            }
          }
        }
      }

      tbody {
        td.h6 {
          display: none;
        }
      }
    }
  }
}

.timepicker {
  .dropdown-menu {
    width: 200px;
  }
}

.date-picker-box {
  height: 42px;
  line-height: 20px;
  padding: $spacing-base $spacing-base+2;
  border: 1px solid #ccc;
  border-radius: 2px;
  display: block;
  cursor: pointer;

  .date-picker-icon {
    position: relative;
    margin-left: 95.2%;
    margin-top: -4.9%;
    font-size: 1.3em;
    cursor: pointer;

    &:hover {
      color: $na-primary-darker;
    }
  }
}

.date-picker-close-icon {
  position: absolute;
  margin-top: -5.4%;
  margin-left: 86.9%;
  font-size: 1.3em;
  cursor: pointer;

  &:hover {
    color: #e6a100;
  }
}
