.changelog-page-actions {
  na-pickaday {
    padding: 8px 10px;
    border: 1px solid $gray;
    display: inline-block;
    width: calc(100% - 35px);
    border-radius: 2px;

    > div {

      span,
      > i {
        cursor: pointer;
      }

      span.date {
        font-weight: 500;
        margin-right: 10px;
        margin-top: 4px;
      }

      span.date-label {
        font-size: 13px;
      }

      i {
        font-size: 16px;
      }

      .pull-right {
        display: flex;
        align-items: center;
        line-height: 1;
      }
    }
  }

  .clear-btn {
    line-height: 0;
    width: 35px;
    text-align: left;
    padding: 10px 5px;

    i {
      cursor: pointer;
      font-size: 17px;
      line-height: 1;
    }
  }
}

.changelog-page-tables {
  td span {
    line-height: 1.3;
  }
}

/* calendar */

.changelog-page-tables {
  position: relative;
  overflow: hidden;

  .ui-grid-header-cell > div {
    padding: 16px 7px 0;
  }

  .ui-grid-cell > div {
    padding: 14px 7px;
    cursor: default;
  }

  .ui-grid-header-cell na-grid-data-icon:not(.icon-filter) .fa-filter {
    margin-left: 3px;
  }

  na-paginated-grid-data-table {
    .ui-grid-viewport {
      .ui-grid-row {
        > div {
          > div:nth-child(2) {
            @extend .custom-scroll;

            > div {
              padding: $spacing-base - 2;
              font-size: $font-size-sm;
            }
          }
        }
      }
    }
  }

  .ui-grid-header-cell {
    na-grid-data-selection {
      display: block;
      height: 100%;

      > *:not(.dropdown-custom) {
        display: block;
        min-height: 100%;
      }

      .dropdown.dropdown-select {
        height: 100%;
      }
    }

    .dropdown-toggle {
      min-height: 100%;
      padding: 14px 7px;
      color: #000;
      font-weight: 500;
    }
  }

  .na-grid-table-title > span {
    min-width: 60px;
  }

  .na-grid-table-title + .sort-icons {
    padding-right: 0;
  }

  [role=button] {
    cursor: default;
  }

  .sort-icons {
    float: none;
    white-space: nowrap;
    margin: 0 0 0 4px;
  }

  @media (max-width: 1600px) {
    .ui-grid-header-cell > div {
      padding: 16px 4px 0;
    }

    .ui-grid-cell > div {
      padding: 14px 4px;
      font-size: 13px;
    }

    .na-grid-table-title > span {
      min-width: 40px;
    }
  }

  @media (max-width: 1440px) {
    .na-grid-table-title > span {
      min-width: 35px;
    }
  }
}
