na-table-grid {
  table {
    border-style: hidden;
  }

  th,
  td {
    padding: 8px;
    vertical-align: middle;
    border-top: 1px solid $border-color;
  }

  td {
    border: 1px solid $border-color;
  }
}

/**
   * The dnd-list should always have a min-height,
   * otherwise you can't drop to it once it's empty
   */
   tbody[dnd-list] {
    min-height: 42px;
    padding-left: 0;
    display: inherit;
    width: 100%;
  }
  
  /**
     * The dndDraggingSource class will be applied to
     * the source element of a drag operation. It makes
     * sense to hide it to give the user the feeling
     * that he's actually moving it.
     */
  tbody[dnd-list] .dndDraggingSource {
    display: none;
  }
  
  /**
     * An element with .dndPlaceholder class will be
     * added to the dnd-list while the user is dragging
     * over it.
     */
  tbody[dnd-list] .dndPlaceholder {
    display: block;
    min-height: 42px;
  }