.multiselect {
  margin-bottom: 0;

  .list-unstyled & li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    border-bottom: 1px solid $gray-lighter;

    &:last-child {
      border: 0;
    }

		a {
			padding: 14px 50px 13px 12px !important;
			color: $gray-dark;
			font-size: $font-size-base;
			display: block;
			position: relative;
			cursor: pointer;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			background-color: $bg-alt;
      @include expandCheckIcon(14px, 12px);
			@include anim;

      &:hover {
        text-decoration: none !important;
      }

			@include respond-to(xsmall) {
				padding: 7px 40px 7px 7px!important;
			}

      &.disabled {
        background-color: #f7f7f7;
        color: $gray;

        &,
        & * {
          cursor: default !important;
        }

      }

      .groupSelect {
        a {
          span {
            padding-left: 25px;
          }
        }
      }

    }

    .groupNameSelect {
      font-style: italic;
      padding: 8px 40px 0 12px !important;
      color: $gray;
      font-size: $font-size-sm;
    }
  }

  .pre-payment-icon {
		&:before {
			font: var(--fa-font-regular);
			content: "\f09d";
			color: $gray;
      padding-right: 5px;
		}
	}
}
