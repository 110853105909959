//Search bar
na-search-bar {
  @include respond-to(fromxsmall) {
    display: block;
    padding: 0 15px;
  }

  .searchbar__form {
    position: relative;
  }

  .backpanel {
    width: 100%;
    height: 100%;
    background: rgba(black, 0.35);
    position: fixed;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.15s linear;
  }

  .searchbar {
    position: relative;
    z-index: 100;
    width: 230px;
    float: right;
    transition: 0.4s ease;
    border: 1px solid $border-color;
    border-radius: 5px;
    background: white;

    @include respond-to(xsmall) {
      width: 120px;
      font-size: $font-size-sm;
      float: none;

      input[type=text] {
        padding: 8px 30px 8px 8px !important;
      }
    }

    &.grow {
      border-color: white;
      width: 100%;
      box-shadow: 0 3px 15px rgba(black, 0.15);

      ~ .backpanel {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .search__input {
    width: 100%;
    border: 0;
    border-radius: $border-radius-lg;
    outline: none;

    @include input-placeholder-color($border-color-dark);
  }

  .search__icon {
    font-size: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    text-align: center;

    @include respond-to(xsmall) {
      font-size: 11px;
      right: -5px;
    }

    i.fa-times {
      cursor: pointer;
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }

  .results {
    position: absolute;
    width: 100%;
    height: 0;
    background: white;
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    z-index: 1;
    box-shadow: 0 3px 10px rgba(black, 0.21);
    top: 0;
    padding: 0;

    &.is-active {
      animation: panel-entrance linear 0.35s forwards;
    }

    ul {
      margin: 0;
    }

    li {
      padding: $spacing-base*2 $spacing-base;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $bg-alt;

      a {
        color: $text-color;
      }
    }

    .results__label {
      font-size: $font-size-sm;
      color: rgba($text-color, 0.5);
      font-style: italic;
      padding: 0 10px;
      margin: 0;
    }

    .no-results {
      &:before {
        font: var(--fa-font-solid);
        content: '\f119';
      }

      &.panel-is-loading {
        &:before {
          font: var(--fa-font-solid);
          content: '\f3f4';
          animation: fa-spin 2s infinite linear;
          display: inline-block;
          width: 25px;
        }
      }
    }

    .results__area {
      > div {
        margin-bottom: $spacing-base;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}