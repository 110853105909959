
.booking-detail--wrapper {
  min-width: 820px;

  @include respond-to(xsmall) {
    min-width: auto;
  }

  .panel.panel-default {
    border-color: #E2E2E2;
    border-radius: 8px;
  }

  .btn-link {
    padding: 0;
  }

  b {
    font-weight: 500;
  }
}

.booking-detail {
  &__substitutions {
    display: flex;
    align-items: flex-start;
  }

  &__substitution-item {
    margin-top: 5px;
  }

  &__name-text {
    &:not(:empty) {
      margin-right: 5px;
    }
  }

  &__logo {
    border-radius: 3px;
    padding: 8px;
    text-align: center;

    img {
      height: 40px;
    }
  }

  &__id {
    @include respond-to(xsmall) {
      width: 100%;
    }
  }
}

.modified-booking {
  color: black;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: $na-primary;
  }

  &.cancel-booking {
    text-decoration: line-through;

    &:hover {
      text-decoration: underline line-through;
    }
  }
}

.booking-detail--header {
  position: relative;
  overflow: hidden;

  @include respond-to(xsmall) {
    padding: $spacing-base+5;
  }

  na-dd-menu {
    position: absolute;
    top: 18px;
    right: 30px;
    z-index: 10;
    font-size: 26px;
  }

  .row {
    margin: 24px !important;

    @include respond-to(xsmall) {
      margin: 0 !important;
    }

    @include respond-to(fromtablet) {
      &:last-child {
        .col {
          margin-right: $spacing-base*3;
        }
      }
    }
  }

  .col {
    display: inline-block;
    vertical-align: middle;
    margin-right: $spacing-base*4;

    @include respond-to(xsmall) {
      margin: 0 25px 15px 0;

      &:not(.booking-detail__id) {
        width: calc(50% - 27px);
      }
    }
  }

  hr {
    border-color: #e6e6e6;
    padding: 0;
    margin: 6px 0;
    width: 100%;

    @include respond-to(xsmall) {
      margin: 0 0 15px;
    }
  }

  .quick-view__subtitle {
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
    color: #000;

    @include respond-to(xsmall) {
      margin-bottom: 0;
    }
  }

  h2 {
    margin: 12px 0 0;
    padding: 0;
    font-size: 23px;
    font-weight: 500;

    @include respond-to(xsmall) {
      font-size: 20px;
      margin: 5px 0 0;
    }
  }

  .booking-id-inner {
    padding-right: $spacing-base;
  }

  .panel-footer {
    background: $gray-lighter;
    border-top: none;
    padding: 16px 24px 16px 16px;
    border-radius: 0 0 6px 6px !important;

    .btn {
      font-weight: 500;
      text-transform: capitalize;
      font-size: 14px;
      color: #000;
      margin-right: 24px;
      cursor: default;

      &:hover {
        color: #000;
      }

      &.btn-cancelled {
        color: red;

        i {
          color: red;
        }
      }

      i {
        vertical-align: inherit;
        padding: 8px;
        font-size: 18px;
        color: $primary-color;
      }
    }
  }

  .fa-rings-wedding,
  .fa-briefcase {
    font-size: 23px;
    color: $gray-darker1;
  }
}

.booking-detail--subheader {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .row {
    width: 100%;
    margin: 0;
  }

  .col {
    display: flex;
    align-self: stretch;
    flex: 1;
    margin-bottom: 0;
    padding: 20px;
    flex-direction: column;

    @include respond-to(xsmall) {
      padding: $spacing-base;
    }

    .row {
      padding: 12px 0;
      border-top: 1px solid #F2F2F2;

      .col-sm-8,
      .col-sm-4 {
        padding: 0;
      }

      &:first-child {
        padding-top: 0;
        border: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    b {
      font-weight: 500;
      color: #000;
    }

    h2 {
      padding: 0;
      margin: 0;

      @include respond-to(xsmall) {
        font-size: 17px;
      }
    }

    &:nth-child(2) {
      margin: 0 20px;
    }
  }

  &.total-block {
    @include respond-to(fromtablet) {
      max-width: 400px;
    }

    .col {
      padding: 15px;

      @include respond-to(fromtablet) {
        padding: 24px;
      }

      .row:first-child {
        padding-top: 12px;
      }
    }

    .total-paid-value {
      color: $na-primary;
    }
  }
}

.booking-detail--total {
  padding: 0 24px;
  font-size: 23px;

  @include respond-to(xsmall) {
    padding: $spacing-base+5;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .col-sm-6 {
    width: 50%;
  }
}

.booking-detail--info {

  h2 {
    font-size: 23px;

    @include respond-to(xsmall) {
      font-size: 19px;
    }
  }

  .row {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    line-height: 1.2em;
  }

  b {
    font-weight: 500;
    color: #000;
  }

  .email-wrapper {
    display: inline-block;
    width: auto;
    max-width: 100%;
    position: relative;
    overflow: hidden;

    a {
      display: inline-block;
      overflow: hidden;
      margin-right: 2em;
    }

    .fa-pen-to-square {
      position: absolute;
      right: 0;
      top: 0;
      box-shadow: 0 0 0.5em 0.5em #fff;
      background: #fff;
      padding-left: 5px;
      color: $sys-color-2;
      cursor: pointer;
    }
  }
}

.booking-detail--items {
  margin-bottom: $spacing-base*4;

  @include respond-to(xsmall) {
    margin-bottom: $spacing-base*2;
  }

  > h2 {
    font-size: 23px;
  }

  .panel.panel-default {
    padding: 24px;
    display: block;

    @include respond-to(xsmall) {
      padding: $spacing-base;
    }

    h2 {
      padding: 0;
      margin: 0 0 8px 0;
    }

    strong,
    b {
      font-weight: 500;

      &.package {
        cursor: pointer;
      }
    }

    .price {
      h2 {
        font-size: 23px;
      }

      b {
        color: #000;
      }

      i {
        color: $text-color-light;
      }
    }

    .label {
      text-align: left;
      width: 160px;
      display: inline-block;
      font-size: $font-size;
      padding: 0;
      margin: 8px 0;
      color: #000;
      font-weight: normal;

      // b {
      //   font-weight: 500;
      // }
    }

    .field {
      display: inline-block;
    }
  }

  .booking-detail__extras {
    display: flex;
    flex-wrap: wrap;
  }

  .booking-detail__extra {
    @include boxShadowReports;

    width: 22.8%;
    display: flex;
    flex-wrap: wrap;

    @include respond-to(xsmall) {
      width: 32%;
      padding: $spacing-base;
    }

    .extra-title {
      font-weight: 500;
      color: #000;
      flex: 5 1;
      line-height: 1.2em;
    }

    .extra-price {
      margin-left: 10px;
      color: $na-primary;
    }

    .extra-desc {
      font-weight: 400;
      flex: 100%;
      color: #666;
      margin-top: 4px;
    }

    h4 {
      padding: 0;
      margin: 0;
    }
  }

  hr {
    border-color: #e6e6e6;
  }
}

na-vouchers-info-panel {
  display: block;
  margin-bottom: 35px;
}

// Voucher Details
na-vouchers-report-info-panel {
  .voucher-details__to {
    padding-right: 10px !important;
  }

  .voucher-details__from {
    padding-left: 10px !important;
  }

  .voucher-details__message {
    margin-top: 20px;
  }
}

.booking-detail__custom-fields {
  .booking-detail__custom-field {
    b {
      display: block;
    }

    p {
      padding-top: 5px;
    }
  }
}

.booking-detail__newsletters {
  p {
    padding: 6px 0 7px;
    margin: 0;
  }

  .newsletters-list {
    padding: 0 0 0 17px;
    list-style: initial;

    li {
      margin-bottom: 7px;
    }
  }
}

.booking-detail__terms-conditions-for-print {
  display: none;
}

na-paginated-grid-data-table {
  na-grid-data-icon-selection {
    na-grid-data-selection {
      > .dropdown.dropdown-select {
        border: none;

        .dropdown-toggle:hover {
          background-color: #e5e5e5;
        }
      }
    }
  }
}

// na-changelog-template,
// voucher-report-list-template,
// voucher-order-report-list-template,
// na-bookings-list-template,
// voucher-redemption-report-list-template {
//   na-grid-data-selection {
//     > .dropdown.dropdown-select {
//       border: none;

//       .dropdown-toggle:hover {
//         background-color: #e5e5e5;
//       }
//     }
//   }
// }

.tax-badge {
  font-size: $font-size-sm;
  color: $gray-text;
}
