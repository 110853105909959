.custom-message {
  .field-wrapper {
    margin-bottom: 24px;
    max-width: 600px;
  }

  .divider {
    border-bottom: 1px solid $border-color;
    margin-bottom: 10px;
    max-width: 280px;
  }

  &__checkbox-group {
    > label {
      margin-bottom: 14px !important;
    }

    .field-wrapper {
      margin-bottom: 2px;
    }
  }

  &__select-group {
    margin: 0 -15px 35px !important;

    na-tariff-group-dropdown {
      display: block;
      margin-bottom: 8px;
    }
  }

  .container {
    .custom-message__daterange-group {
      .field-wrapper {
        margin-bottom: 32px;
      }

      .row {
        margin: 0 -15px !important;
      }
    }
  }
}
