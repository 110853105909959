body.blankpage {
  margin: 0;
  padding: 0;
  overflow: hidden;

  .na {
    width: 146px;
    margin: 43px auto;
    display: block;
  }

  .system-message {
    left: 0;
    top: 50%;
    margin-top: -181px;
    position: absolute;
    text-align: center;
    width: 100%;

    @media (max-height: 650px) {
      position: relative;
      margin-top: 0;
      top: 0;
    }

    img.systemMessage {
      display: block;
      margin: 0 auto;
    }

    .system-message-content {
      h2 {
        font-size: 22px;
        font-weight: bold;
        color: #000;
        margin-bottom: 0;

        &:after {
          margin: 20px auto 0;
          display: block;
          clear: both;
          height: 3px;
          width: 100px;
          background-color: $na-primary;
          content: ' ';
        }
      }

      p {
        margin: 0 auto;
        padding: 0;
        font-weight: 400;
        font-size: 16px;
        width: 360px;
        line-height: 24px;
      }

      a {
        font-weight: bold;
        color: #000;
        text-transform: uppercase;
        margin: 33px 0;
        display: inline-block;
        font-size: 14px;

        i {
          font-size: 19px;
          position: relative;
          top: 2px;
          margin-right: 5px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    &.system-message-404error {
      img.systemMessage {
        width: 195px;
        transform: translate(31px, 0);
      }
    }

    &.system-message-error {
      img.systemMessage {
        width: 200px;
        transform: translate(-18px, 0);
      }
    }

    &.system-message-maintenance {
      img.systemMessage {
        width: 220px;
        transform: translate(-19px, 0);
      }
    }
  }
}
