.na-rating--wrapper {
  display: flex;
  align-items: center;
  margin-top: $spacing-base;
}

.na-rating {
  margin-right: 20px;

  + span {
    line-height: 1;
    display: flex;
    align-items: center;
  }

  i {
    font-family: "Font Awesome 6 Pro";
    cursor: pointer;
    font-size: 20px;
    color: $gray;
    line-height: 1em;

    @include anim;

    &.fa-star.fa-solid {
      font-weight: 700;
    }

    &.na-rating-active {
      @include theme-color;
    }
  }

  * {
    @include noselect;

    outline: 0;
  }
}

.na-rating--na {
  margin: 5px 0 0 0 !important;
}
