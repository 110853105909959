.loader-icon {
  svg {
    @include spinning;

    opacity: 0.3;
    width: 24px;
    height: 24px;
  }

  &.loader-md {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.loader-sm {
    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.loader-wrapper {
  @include anim-custom(all, 0ms, ease);

  position: absolute;
  z-index: 100;

  &.loader-md {
    padding: 0;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.loader-sm {
    svg {
      width: 10px;
      height: 10px;
    }
  }

  &.loader-left {
    text-align: left;
  }

  &.loader-center {
    text-align: center;
    width: inherit;
  }

  &.loader-right {
    text-align: right;
  }

  /* Loader covers the whole page */
  .page-loader > & {
    .loader-icon {
      @include anim;
      position: fixed !important;
      top: 0;
      bottom: 0;
      background-color: #fff;
      z-index: 1;
      right: 0;
      left: $sidebar-medium + 20px;

      @media (max-width: 1280px) {
        left: $sidebar-small + 20px;
      }

      @include respond-to(xsmall) {
        left: 0;
      }

      #content.content-narrow & {
        right: $sidebar-medium;

        @media (max-width: 1280px) {
          right: $sidebar-small;
        }

        @include respond-to(xsmall) {
          right: 0;
        }
      }

      svg {
        position: absolute;
        left: 50%;
        margin-right: -12px;
        top: 50%;
      }
    }

    &.ng-hide {
      display: block !important;

      .loader-icon {
        opacity: 0 !important;
        display: block !important;
        pointer-events: none;
      }
    }
  }
}

/* Dropdown Column on Advanced List */
.list-table {
  .col-dropdown {
    .loader-wrapper {
      pointer-events: none;
      font-size: 1.8em;
      margin-bottom: -1px;
      position: relative;

      .loader-icon {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.div-load:not(.dashboard__system) {
  > *:not(.loader-wrapper) {
    opacity: 1;
    transform: translateY(0);
    transition: all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 500ms;
  }

  &.load {
    > *:not(.loader-wrapper) {
      opacity: 0;
      transform: translateY(50px);
    }
  }
}


/* Loader for media library popup*/
.mediaLibraryLoader {
  height: 75px;
  width: 50px;
  margin: 0 auto;
}

/* Loader for table users company on the system page*/
.usersSystemLoader {
  width: 50px;
  margin: 0 auto;
  position: relative;
  z-index: 100000;
  top: 50px;
}

md-progress-circular {
  margin: 0 auto;

  path {
    stroke: rgba(black, 0.65);
  }
}

.icon-loading {
  background-image: url("/js/src/common/views/directives/grids/common/img/loading.gif");
  background-repeat: no-repeat;
  background-position: center;
}

/* Loader inside Action Bar */
