@media print {

  #sidebar,
  .header-actions,
  #open_menu,
  .top-controls,
  #topbar,
  .btn-copy,
  .tooltip-inner,
  .tooltip-arrow,
  na-accordion-part.ng-isolate-scope.hide-print,
  .hide-print,
  label input[type=checkbox] + .label-text:after {
    display: none !important;
  }

  pre.code.custom-scroll.ng-binding {
    overflow: visible !important;
    height: auto;
    word-break: break-all;
    white-space: inherit;
  }

  #content .content-inner {
    padding-top: 0 !important;
  }

  label input[type=checkbox] + .label-text:before {
    border: 0;
  }
}

//We need fix this after
//.tooltip.ng-scope.ng-isolate-scope.right.fade.in{
//  @include fadeOut(.3s);
//  animation-fill-mode: forwards;
//  animation-delay: 2s;
//}

#widget-code, #link-lab {
  .box-widget {
    background-color: $gray-lighter;
    border-radius: 3px;
    position: relative;
    border: 1px solid $border-color;

    &.booking-button {
      min-height: 150px;
      padding: 56px 60px 38px 60px;
      text-align: center;

      a img {
        margin-top: -4px;
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }

      [class^="btn--trigger-"] {
        color: white !important;
        text-decoration: none;
      }

      p {
        font-family: $font-family-monospace;
      }
    }

    pre {
      text-align: left;
      font-family: $font-family-monospace;
      letter-spacing: 0;
      padding: $spacing-base*2;
      overflow: auto;
      font-size: 85%;
      line-height: 2;
      margin: 0;
      border: 0;

      &.code {
        padding-top: $spacing-base*4;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
      }

      &.custom-scroll::-webkit-scrollbar-track {
        margin-left: 0;
      }

      &.custom-scroll::-webkit-scrollbar {
        left: 0;
        height: 6px;
      }
    }
  }

  .padding-checkbox {
    padding-top: 15px;
  }

  .underline-text {
    color: inherit !important;
    text-decoration: underline !important;
    cursor: pointer;
  }

  .btn-copy, .btn-preview {
    padding: #{$spacing-base - 3} $spacing-base+5;
    background: rgba(white, 0.78);
    font-weight: 400;
    font-size: $font-size-xsm;
    position: absolute;
    right: 0;

    i {
      font-size: $font-size-sm;
      padding-right: $spacing-base*0.5;
    }

    &:hover {
      color: inherit;
    }
  }
}

#voucher-widget-code {
  .form-control {
    height: 39px;
  }

  .field-wrapper {
    margin-bottom: $spacing-base;
  }
}
