na-grid-select,
.na-grid-select {
  display: block;

  .cell {
    padding: 0;
    margin: 0 -1px -1px 0;
    position: relative;

    @include anim;

    background-color: $bg-alt;
    color: #999;

    span {
      display: none;
    }

    a {
      border: 1px solid $gray-light;
      padding: 12px 17px;
      display: block;

      @include anim;

      color: #666;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        color: #000;
      }

      &:focus,
      &:hover,
      &:active {
        text-decoration: none;
      }
    }

    &.disabled {
      @include disabled;
    }

    &.select_disabled {
      @include select_disabled;
    }

    
    &:after {
      font: var(--fa-font-regular);
      content: '\f00c';
      pointer-events: none;
      position: absolute;
      right: 15px;
      top: 15px;
      color: #fff;
      font-size: 16px;
  
      @include scale(0, 0);
      @include anim;
    }
  
    
    &.selected { 
      background-color: #fff;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      z-index: 99;
  
      &,
      * {
        color: #000;
      }
  
      &:after {
        @include scale(1.1, 1.1);
  
        color: $na-primary-darker;
      }

      a {
        border: 1px solid #d2d2d2;
        padding-right: 40px;

        &:hover {
          text-decoration: none;
          color: #000 !important;
        }
      }

      &.disabled {
        opacity: 0.3;
      }

      &.select_disabled {
        opacity: 0.3;
      }
    }
  
    &.selected-singleSelect {
      @include theme-bg;
  
      &,
      * {
        color: #fff;
      }
    }
  }

  .clone-rates-grid & {
    > div {
      display: flex;
      flex-wrap: wrap;

      .cell {
        flex: 0 1 33%;

        a {
          height: 100%;
        }
      }
    }
  }
}
