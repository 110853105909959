.ui-grid-cell {
  overflow: hidden;
  float: left;
  border-right: $gridBorderWidth solid $border-color;
  border-color: transparent;
  box-sizing: border-box;
  padding: 0;
  background-color: transparent;
  border-bottom: $gridBorderWidth solid $border-color;

  > div {
    padding: 14px;
    cursor: pointer;
  }

  &:last-child {
    border-right: 0;
  }

  na-grid-data-icon {
    width: 100%;
    text-align: center;
    display: block;
    padding: 14px 0;
    cursor: pointer;

    i {
      font-size: 16px;
    }
  }

  &.icon-filter {
    &:not(:nth-child(4)):not(.voucher-redemption-icon):not(.voucher-order-undo-icon) {
      border-right-color: transparent;
    }
  }

  &.data-grid-menu {
    text-align: center;

    span {
      font-size: 23px;
      padding: 13px 0;
    }
  }

  textarea:disabled {
    background: none;
  }
}

.ui-grid-cell-contents {
  padding: 4px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
}

.ui-grid-cell-contents-hidden {
  visibility: hidden;
  width: 0;
  height: 0;
  display: none;
}

.ui-grid-row .ui-grid-cell.ui-grid-row-header-cell {
  background-color: $rowHeaderCell;
  border-bottom: solid $gridBorderWidth $borderColor;
}
