.na-logo {
  @include NALogoGradient;

  width: $sidebar-top;
  height: $sidebar-top;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-logo {
    width: 35px;
  }
}

.icon-logo path {
  stroke: transparent;
  fill: #fff;
  stroke-dasharray: 450;
  stroke-dashoffset: 0;
}

.icon-logo:hover path {
  stroke: #fff;
  animation: anim 4s ease-out;
}

@keyframes anim {
  0% {
    stroke-dashoffset: 450;
    fill: transparent;
    stroke-width: 2;
  }

  97% {
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-width: 3;
  }
}
