.box-multiselect {
  display: flex;
  justify-content: space-between;

  .box-multiselect__empty {
    flex: 1;
    border: 1px solid $border-color;
    background: $bg-alt;
    margin: 11px 15px 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $text-color;

    @include respond-to(xsmall) {
      margin: 10px 0 0;
      padding: 10px;
    }

    i {
      font-size: 50px;
    }
  }

  .box-multiselect__panel {
    flex: 1;
    display: flex;
    flex-direction: column;

    h2 {
      padding: 0 15px;
      margin-top: 0;
    }

    @include respond-to(xsmall) {
      .col-sm-12 {
        padding: 0 !important;
      }
    }

    na-advanced-list {
      padding: 0;
    }
  }

  > i {
    align-self: center;
    margin: $spacing-base $spacing-base*2;
    font-size: 20px;
    flex: none;

    @include respond-to(xsmall) {
      margin: $spacing-base $spacing-base*0.5;
    }
  }

  form & {
    .container .row,
    .container-fluid .row {
      margin: 0 !important;
    }
  }


  table.list-table,
  table.list-table tbody {
    display: flex;
    flex-direction: column;
    border: 0 !important;
    margin: 0;

    tbody {
      overflow: hidden;

      &.dropempty {
        display: none;
      }
    }

    tr,
    td {
      border: 0;
      display: flex;
    }

    tr {
      &:not(:nth-last-child(2)) {
        border-bottom: 1px solid $border-color;
      }
    }

    td {
      padding: 0;
      margin: 0;
      width: 100%;
      align-items: center;
      height: auto;

      na-field-component {
        width: 100%;

        span {
          padding: $spacing-base+$spacing-base*0.5 $spacing-base;
          width: 100%;
          display: block;
          cursor: pointer;
        }
      }
    }

    tr:only-child {
      border: 0 !important;
    }

    td.col-xs:not(.ng-hide) {
      flex: none;
      width: 50px;
      text-align: center;
      cursor: ns-resize;

      @include respond-to(xsmall) {
        width: auto;
        padding: 5px;

        &.drag-icon-container {
          display: none;
        }
      }

      img {
        margin: 0 auto;
      }

      + td {
        span {
          cursor: ns-resize;
        }

        + td {
          width: 50px;
          text-align: center;

          @include respond-to(xsmall) {
            width: auto;
            padding: 5px;
          }

          i {
            color: $text-color;
            transition: all 0.4s ease;
            position: relative;
            z-index: 0;

            &:after {
              content: "";
              background: $border-color;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) scale(0);
              transition: all 0.4s ease;
              width: 23px;
              height: 23px;
              z-index: -1;
              border-radius: 50%;
            }
          }

          &:hover {
            i {
              &:after {
                transform: translate(-50%, -50%) scale(1);
              }
            }
          }
        }
      }
    }
  }


  na-body-list table.list-table tbody,
  .box-multiselect__empty {
    border: 1px solid $border-color !important;
    border-radius: 4px;
  }

  .btn-add {
    text-align: left;
    text-transform: uppercase;
    font-size: 11px;
    margin: $spacing-base 15px 0;
    font-weight: bold;
  }
}

na-editable-box-row-input {
  display: block;
  width: 100%;
}

.editable-icon-row {
  display: flex;
  align-items: center;

  &__icon-holder {
    width: 30px;
    text-align: center;
    margin-left: 10px;

    @include respond-to(xsmall) {
      margin-left: 5px;
    }
  }

  i {
    color: #757575;
    width: 20px;
    font-size: 20px;
    text-align: left;
    vertical-align: middle;
    line-height: 1;
  }
}
