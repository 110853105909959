// override default .row margin in form
form .container .row {
  &.parameter-row {
    margin-bottom: 0 !important;
  }
}

.parameter-row {
  .field-wrapper {
      margin-bottom: 18px;
  }

  .label--block {
    display: block;
  }

  .icon-colorpicker {
    bottom: 28px;
  }
}

.param-checkbox {
  display: block;
  margin-top: 5px;

  &>label {
      margin: 0;
  }
}

