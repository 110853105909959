//image cropper
body.imgCropper-dragging,
body.imgCropper-dragging * {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important;
  cursor: grabbing, move;
  /* IE hack */
}

.imgCropper-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
  cursor: grab, move;
  /* IE hack */
}

.imgCropper-container,
.imgCropper-container > * {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
}

.imgCropper-container {
  text-align: center;
}

.imgCropper-container > * {
  max-width: none;
  max-height: none;
  width: 100%;
  height: 100%;
}

.imgCropper-sample {
  position: absolute !important;
  top: -100000px !important;
  left: -100000px !important;
  width: auto !important;
  height: auto !important;
}

.media-library-crop-controll {
  padding: 4px;
}

.imgCropper-controls {
  opacity: 0;
}

image-cropper:hover .imgCropper-controls {
  opacity: 1;
}