//rate bands view
na-selected-rate-band-component {
  .grid-header-cells {
    .cell-day {
      font-weight: 400;

      > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        > span {
          margin-top: $spacing-base;
          font-size: $font-size-sm;
        }

        > span ~ span {
          display: flex;
          justify-content: space-around;
          width: 100%;

          [class*="fa"] {
            cursor: pointer;
          }
        }
      }
    }
  }
}

//  Color Picker
na-color-picker-basic {
  material-picker {
    width: 287px;
    height: 212px;
    display: block;
    margin-top: #{$spacing-base*0.5};
    margin-left: -#{$spacing-base*0.5};

    + span.btn-close-color {
      position: relative;
      top: inherit;
      left: -#{$spacing-base*0.5};
    }
  }
}

#rates-grid-form,
#rates-grid-form + div.div-load {
  .loader-wrapper {
    width: 100%;
    text-align: center;
    top: 10px;
  }

  .grid-header {
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      width: 100%;
      height: 1px;
      color: $gray-300;
      position: absolute;
      bottom: 1px;
    }

    + div.row {
      z-index: 0;
    }
  }
}

//drag functionality
.grid-header-cells {
  .dropzone {
    &.dropzone-active {
      &.dropzone-over {
        &:before {
          font: var(--fa-font-light);
          content: '\f067';
          width: 18px;
          height: 18px;
          position: absolute;
          background: $na-primary;
          border-radius: 50%;
          left: -8px;
          font-size: 12px;
          line-height: 17px;
          color: white;
          box-shadow: 0 0 6px rgba(black, 0.25);
        }
      }
    }

    .draggable {
      &.dragging {
        opacity: 0.5;
      }
    }
  }
}
