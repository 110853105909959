.action-bar {
  @include anim;

  text-align: center;
  position: fixed;
  bottom: -70px;
  left: $sidebar-medium;
  right: 0;
  padding: 15px;
  background-color: #fff;
  z-index: 101;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.15);

  &.open {
    bottom: 0;
  }

  @media (max-width: 1280px) and (min-width: 769px) {
    left: $sidebar-small;
  }

  @include respond-to(fromxsmall) {
    left: 0;
  }

  #content.content-narrow & {
    @media (max-width: 1280px) and (min-width: 769px) {
      right: $sidebar-small;
    }
  }

  button {
    margin-left: 10px;
    min-width: 140px !important;
    position: relative;
  }
}
