na-stats-overview {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .dashboard__system-data:nth-child(2) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: $spacing-base*0.5;
  }

  @supports (display: grid) {
    .dashboard__system & {
      display: grid;
      gap: $spacing-base;
      grid-template-columns: auto auto;
      grid-template-rows: auto minmax(100px, 130px);
      grid-template-areas:
        "title value"
        "graph graph";

      .dashboard__system-main {
        grid-area: title;
      }

      .dashboard__system-data {
        grid-area: value;
      }

      .dashboard__system-chart {
        grid-area: graph;
      }
    }

    .dashboard__system--col & {
      flex-direction: row;
      justify-content: space-between;

      .dashboard__system-data {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .data__variation {
          margin-top: $spacing-base*0.5;
        }
      }

      .dashboard__system-chart {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;

        &.no-data-yet {
          top: 30%;
        }
      }
    }

    .dashboard__system:not(.dashboard__system--col) & {
      grid-template-columns: auto minmax(100px, 250px);
      grid-template-rows: minmax(10px, max-content) minmax(10px, max-content);
      grid-template-areas:
        "title graph"
        "value graph";
    }
  }
}

na-stats-list {
  @supports (display: grid) {
    .dashboard__system & {
      display: grid;
      gap: $spacing-base;
      grid-template-columns: 0.9fr 1.1fr;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        "title title"
        "graph value"
        "graph value";

      .dashboard__system-main {
        grid-area: title;
      }

      .dashboard__system-data {
        grid-area: value;
      }

      .dashboard__system-chart {
        grid-area: graph;
      }
    }
  }
}

na-stats-list,
.dashboard__system-data,
na-stats-overview {
  width: 100%;

  //most recent
  table {
    width: 100%;
    margin: $spacing-base+$spacing-base*0.5 auto 0;

    td {
      text-align: left;
      padding: $spacing-base*1.5 $spacing-base;
      font-size: $font-size-base;
      border: none;

      @include respond-to(xsmall) {
        font-size: $font-size-small;
      }

      //content
      *:not(.more-info) i {
        font-size: $font-size-xsm;
        margin: 0 $spacing-base*0.5;
      }

      .more-info {
        cursor: pointer;
        color: $text-color !important;

        &:hover {
          color: $na-primary !important;
        }

        i {
          font-size: $font-size-base;
        }
      }

      .text-sm {
        font-size: $font-size-sm;
        color: $text-color-light;
      }

      &:last-child {
        text-align: right;
        padding-right: 0;
      }
    }

    tr:first-child {
      background: $bg-alt;

      th:first-child {
        border-radius: $border-radius 0 $border-radius 0;
      }

      th:last-child {
        border-radius: 0 $border-radius $border-radius 0;
      }
    }

    th {
      color: rgba($text-color, 0.5);
      padding: $spacing-base*0.5 $spacing-base;
    }
  }

  //most popular
  ul {
    margin: $spacing-base 0 0;

    li {
      display: flex;
      justify-content: space-between;
      padding: $spacing-base+$spacing-base*0.5 0;

      @include respond-to(xsmall) {
        padding: 5px $spacing-base+$spacing-base*0.5 !important;
      }

      &:last-child {
        padding-bottom: 0;
      }

      a {
        text-decoration: none;
        color: $text-color;
      }

      p {
        margin: 0;
        padding: 0 $spacing-base 0 0;

        &:last-child {
          padding: 0;
        }
      }
    }

    .dashboard--superadm & {
      margin: 0;
      width: 100%;

      li {
        padding: $spacing-base+$spacing-base*0.5;

        &:not(:last-child) {
          border-bottom: 1px solid $bg-alt;
        }
      }
    }
  }

  //featured values
  .dashboard__system-value {
    color: $na-primary;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    display: inline-flex;
  }
}