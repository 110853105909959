.grid__nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  > * {
    flex: 1;
    height: 100%;
    border-left: 1px solid $gray-300;

    > i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .move-left.no-border + .move-right.no-border {
    border-left-width: 0;
  }
}

.grid-control {
  display: flex;
  align-items: center;

  na-labeled-dropdown {
    > div {
      display: flex;
      align-items: center;

      > * {
        width: auto;
      }

      label {
        margin: 0;
        top: inherit;
      }
    }

    na-field-component {
      > div {
        .dropdown.dropdown-select {
          min-width: 200px;
          border-color: $gray-300;

          @include respond-to(fromxsmall) {
            max-width: 200px;
          }

          > a {
            padding: $spacing-base 25px $spacing-base $spacing-base;

            span {
              font-size: 8px;
              top: inherit;
              right: 6px;
              transform: translateY(50%);
            }
          }
        }
      }
    }
  }

  na-availability-from-pool-switch {
    display: flex;
    align-items: center;

    button {
      border-color: $gray-300;
      padding: 1px !important;
      width: 15px;
      height: 15px;
      min-width: 15px;
      min-height: 15px;
    }

    span.label-text {
      font-size: $font-size-sm;
      padding-left: #{$spacing-base*0.5} !important;
    }
  }
}

.dropdown-rate-bulk.dropdown-menu,
.dropdown-menu--grid .dropdown-menu {
  margin: -#{$spacing-base*0.5} 0;
}
