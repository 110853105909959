.na-text-page {
  color: $gray-base;

  .na-text-page__title {
    margin: 0;
    padding-top: 0;
    padding-bottom: 25px;

    @include respond-to(xsmall) {
      padding-bottom: 15px;
    }
  }

  .no_secondary_text {
    margin-bottom: 20px !important;

    @include respond-to(xsmall) {
      margin-bottom: $spacing-base !important;
    }
  }

  .na-text-page__mainText {
    margin: 0;
    padding-top: 0;
  }

  .na-text-page__secondaryText {
    padding: 0;
    margin-bottom: 30px;
    font-size: $font-size-sm;
    color: $text-color;

    @include respond-to(xsmall) {
      margin-bottom: 15px;
    }
  }
}

.deposit_title {
  margin-top: 0;
  padding-top: 0;
}
