.grid-container {
  width: 100%;
  margin: 0 auto;
  position: relative;

  .grid-header {
    width: 100% !important;
    display: block;
    height: $td-height *2;
    position: absolute !important;
    left: auto !important;
    top: 0 !important;

    .controls {
      right: -50px;
      top: 4px;
      position: absolute !important;
      left: $fixed-col-width - 50px;

      @media (max-width: 768px) {
        left: $fixed-col-width-sm - 50px;
      }

      @media (max-width: 767px) {
        left: 0;
        right: 0;
        bottom: 0;
      }

      .btn-prev,
      .btn-next {
        position: absolute;
        top: 0;
        font-size: 20px;
        cursor: pointer;
        color: #000;
        z-index: 100;
        width: $arrows-width;
        text-align: center;
      }

      .btn-prev {
        left: $arrows-width;

        &.week {
          left: 0;
        }
      }

      .btn-next {
        right: $arrows-width;

        &.week {
          right: 0;
        }
      }

      &:before {
        content: '';
        display: block;
        background-color: $bg-base;
        position: absolute;
        top: -4px;
        left: - $fixed-col-width + 50px;
        height: $td-height;
        width: $fixed-col-width;

        @media (max-width: 768px) {
          left: 0;
        }
      }
    }
  }
}

.jump-to-date {
  background-color: $bg-base;
  position: absolute;
  top: $td-height;
  left: 0;
  z-index: 100;
  display: table-cell;
  width: $fixed-col-width;
  font-weight: 400;
  height: $days-height + 1;
  vertical-align: middle;
  padding: 4px $padding-base;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  border-right: 1px solid $border-color-darker;
  font-size: 13px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: $fixed-col-width-sm;
  }

  span {
    width: calc(100% - 25px);
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  i {
    position: relative;
    top: 6px;
    font-size: 16px;
  }
}

/* PREV NEXT */
.scroll-container {
  overflow-x: hidden;
  position: absolute;
  height: $common-height;
  transition: 200ms all ease;

  &.container-left,
  &.container-rooms {
    overflow: inherit;
    z-index: 1;
    width: $fixed-col-width;
    top: $td-height*2;
    left: 0;
    background: $bg-base;
    border-right: 1px solid $border-color-darker;
    border-bottom: 1px solid $border-color;

    @-moz-document url-prefix() {
      top: $td-height*2 + 1;
    }

    @media (max-width: 768px) {
      width: $fixed-col-width-sm;
    }

    table {
      tr {
        td {
          width: $fixed-col-width - 1;
          max-width: $fixed-col-width - 1;
          padding: 0;
          border: 0;
          position: relative;

          @media (max-width: 768px) {
            width: $fixed-col-width-sm - 1;
            max-width: $fixed-col-width-sm - 1;
          }

          &.td-heading,
          &.room-name {
            position: relative;
            font-size: 14px;
            text-align: left;
            border-top: 1px solid $border-color-darker;
            padding: 0 $padding-base;

            a {
              color: $text-color;
              width: 92%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
              cursor: pointer;
              text-decoration: none;
              float: left;
            }

            span.curr {
              position: relative;
              right: -2px;
              float: right;
            }
          }

          &.td-sub,
          &.room-occ {
            text-align: right;
            border: 0;
            padding: 0 $padding-base;
            position: relative;

            a {
              color: $text-color;
              width: 86%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
              cursor: pointer;
              text-decoration: none;
            }

            span.curr {
              position: relative;
              right: -2px;
              top: 1px;
              float: right;
              margin-left: 2px;
            }
          }

          &.td-split {

            span.curr,
            span.hash {
              opacity: 0.5;
              font-size: 11px !important;
              line-height: 15px;
            }

            span.curr {
              position: absolute !important;
              top: 18px !important;
              right: 5px !important;
            }

            span.hash {
              position: absolute;
              top: 1px;
              right: 5px;
            }
          }
        }
      }
    }
  }

  &.container-main,
  &.container-rates {
    position: absolute !important;
    top: 72px;
    z-index: 0;
    left: 160px;
    width: calc(100% - 160px);
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: 768px) {
      left: $fixed-col-width-sm - 1;
      overflow-x: scroll;
      width: calc(100% - #{$fixed-col-width-sm - 1 });
    }

    table {
      margin-left: 0;
      display: inline-block;

      &:first-child {
        margin-left: 0;
      }

      tr {
        td {
          min-width: $td-width;
          height: $td-height;
          position: relative;
          transition: all 200ms ease;
          line-height: 15px;

          img.icon-loading {
            display: none;
          }

          &.td-loading,
          &.rate-loading {
            .icon-loading {
              display: block;
              margin: 0 auto;
            }

            a {
              display: none !important;
            }
          }

          &.td-split {

            &,
            a {
              padding: 0;
              font-size: 10px;
            }

            a {
              padding: 0 !important;
              margin: 0;
              height: 50px;
            }

            a:nth-of-type(2) {
              margin-top: 1px;

              &:before {
                content: '';
                height: 1px;
                display: block;
                width: 106%;
                background: $border-color;
                clear: both;
                position: relative;
                top: -2px;
              }
            }
          }

          a {
            padding: 9px 0;
            width: $td-width - 1;
            display: block;
            color: $text-color;
            text-decoration: none;
            transition: all 200ms ease;
            border: 1px solid transparent;
            margin: -1px 0;
            cursor: pointer;
            height: $td-height;

            &:focus,
            &:hover {
              background-color: $bg-alt;
              color: darken($border-color, 50%);
              border: 1px solid darken($border-color, 50%);
            }
          }

          a.split {
            padding: 2px 0;
          }

          a.slim {
            padding: 1px 0;
            height: $td-height-half;
          }

          &:first-child {
            border-left: 1px solid $border-color-darker;
          }

          &.row-days {
            height: $days-height;
            vertical-align: middle;
            padding: 0;
            font-size: 16px;
            background-color: $bg-alt;
            border-top: 1px solid $border-color;

            @-moz-document url-prefix() {
              border-top: 2px solid $border-color !important;
            }

            span {
              display: block;
              font-size: 10px;
              width: $td-width;
              margin-top: 2px;
              margin-bottom: -4px;
            }
          }

          &.row-months {
            text-align: left !important;
            background-color: $bg-base;
            border: 0 !important;
            position: relative;
          }
        }
      }
    }
  }
}

.top-rate-bands {
  top: 35px !important;
}

.container-days {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 160px;
  right: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  border-bottom: 1px solid $border-color;
  white-space: nowrap;
  height: $days-height*2 + 1;
  transition: 200ms all ease;

  @media (max-width: 768px) {
    left: $fixed-col-width-sm - 1;
  }

  table {
    margin-left: 0;
    display: inline-block;

    &:first-child {
      margin-left: 0;
    }

    td {
      position: relative;

      &:first-child {
        border-left: 1px solid $border-color-darker !important;

        @-moz-document url-prefix() {
          border-left: 2px solid $border-color-darker !important;
        }
      }

      &.row-days {
        height: $days-height;
        vertical-align: middle;
        padding: 0;
        font-size: 16px;
        background-color: $bg-alt;
        border-top: 1px solid $border-color;

        @-moz-document url-prefix() {
          border-top: 2px solid $border-color !important;
        }

        span {
          line-height: 12px;
          display: block;
          font-size: 10px;
          width: $td-width - 1;
          margin-top: 2px;
          margin-bottom: -4px;
        }
      }

      &.row-months {
        position: relative;
        text-align: center !important;
        background-color: $bg-base;
        border: 0 !important;
        overflow: hidden;
      }

      .header-icon {
        position: absolute;
        bottom: 0;
        font-size: 12px;
        opacity: 0.3;
        color: #000;
        cursor: pointer;
        transition: 200ms all ease;
        line-height: 18px;

        &.header-hover {
          bottom: -20px;

          &:hover {
            bottom: 0;
          }
        }

        &.icon-delete {
          left: 4px;
        }

        &.icon-colorpicker {
          right: 4px;
        }

        &:hover {
          opacity: 1;
        }
      }

      &.header-hover {
        .header-icon {
          opacity: 1;
          bottom: -20px !important;
        }

        &:hover {
          .header-icon {
            bottom: 0 !important;
          }
        }
      }
    }
  }
}

.color_icon {
  position: absolute;
  z-index: 2;
  bottom: 11px;
  right: 25px;
  font-size: 13px;
}

.month-container {
  transition: 0ms all linear;
  padding: 8px;
  height: $td-height;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  overflow: hidden;
  border-left: 1px solid $border-color;

  &:before {
    font: var(--fa-font-regular);
    content: '\f105';
    position: absolute;
    left: -25px;
    top: 8px;
    height: 40px;
    width: 40px;
    color: #abc5bc;
    font-size: 56px;
    line-height: 63px;
    z-index: 10;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    z-index: 10;
    background: rgba(255, 255, 255, 0);
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
  }
}

/* GRID - COLLAPSE VARIATIONS */

.grid-collapse {
  .collapse-control {
    width: $td-height;
    height: $td-height + 1;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -1px;
    border-top: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-bottom: 0 solid $border-color;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    background-color: $bg-base;

    i {
      font-size: 18px;
    }
  }

  .td-heading,
  .td-sub {
    text-align: left !important;
    font-size: 12px !important;
    padding-left: $td-height + 9 !important;

    a {
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .td-gap {
    border-left: 1px solid $bg-base !important;
    border-right: 1px solid $bg-base !important;
  }

  .td-heading {
    border-top: 1px solid $border-color !important;
    border-bottom: 1px solid $border-color !important;

    a {
      display: table !important;
      height: $td-height - 1;
      width: auto !important;
      min-width: $fixed-col-width + 2;
      background-color: #fff;
      line-height: 34px;
      font-size: 13px;
      overflow: visible !important;
      padding-right: 15px;
    }
  }

  .td-sub {
    border-bottom: 1px solid $border-color !important;
    height: $td-height;

    .collapse-control {
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
    }

    a {
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 15px;
    }
  }

  &.container-main {
    table {
      td {
        &.td-heading {
          a {
            display: none;
          }
        }
      }
    }
  }

  .container-main {
    table {
      margin-top: $td-height;
    }
  }
}


/* GRID - CUSTOM GRID */
.grid-custom {
  .grid-header {
    height: $td-height - 1 !important;

    .container-days {
      height: $td-height;

      .table-month {
        td {
          &.row-days {
            span {
              margin-top: 0;
              margin-bottom: auto;
            }
          }
        }
      }
    }
  }

  .scroll-container {
    &.container-rates {
      table {
        margin-left: -1px;

        tr {
          td:first-child {
            border-left: 0 solid $border-color;
          }
        }
      }
    }

    &.container-rooms {
      top: $td-height - 1;
      margin-bottom: 100px;

      #col-fixed {
        border-top: 1px solid $border-color;

        .room-name {
          display: table-cell;
        }
      }
    }
  }
}

.grid-container,
.grid-custom {
  table {
    border-style: hidden;

    td {
      border: 1px solid $border-color;
      vertical-align: middle;
      height: $td-height;
      text-align: center;
      font-size: 11px;
      font-family: $font-family-base !important;
      font-weight: 400;

      &.col-fixed {
        width: $fixed-col-width;
        text-align: left;
        opacity: 0;

        @media (max-width: 768px) {
          width: $fixed-col-width-sm;
        }
      }
    }
  }
}

na-rate-band-settings-grid {
  .grid-container .grid-header .controls:before {
    top: -6px;
  }

  .container-days {
    left: 159px;
    width: fit-content;
  }
}


/* Dots Status */
.availability-closeout {
  .status-dot {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
    padding: 5px;
    line-height: 16px !important;
    font-size: 6px;
    cursor: pointer;
  }
}

.status-dot {
  i {
    color: $status-null;
  }

  &.status-closeout {
    i {
      color: $status-closeout;
    }
  }

  &.status-available {
    i {
      color: $status-available;
    }
  }

  &.status-alert {
    i {
      color: $status-alert;
    }
  }
}

.dropdown-menu {
  li {
    a {
      padding: 9px 16px !important;
      outline: none;

      i {
        margin-right: 12px;
        font-size: 11px;
      }
    }
  }
}

@keyframes border-blink {
  0% {
    border-color: $border-color-darker;
  }

  50% {
    border-color: darken($border-color-darker, 50%);
  }

  100% {
    border-color: $border-color-darker;
  }
}

/* Inline Edit */
a[contentEditable],
div[contentEditable] {
  cursor: pointer;
}

a[contentEditable]:focus,
a[contentEditable]:hover:focus,
div[contentEditable]:focus,
div[contentEditable]:hover:focus {
  cursor: pointer;
  background-color: $bg-base;
  outline: 0 !important;
  border: 3px solid;
  color: darken($border-color-darker, 50%) !important;
  animation: border-blink 1s ease 0s infinite normal;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3) inset;
}

/* Selected */
a[contentEditable]::selection,
div[contentEditable]::selection {
  background: $border-color !important;
  color: #000;
}

a[contentEditable]::-moz-selection,
div[contentEditable]::-moz-selection {
  background: $border-color !important;
  color: #000;
}

/* BAND COLORS */
td.band-cust,
td.band-1,
td.band-2,
td.band-3 {

  a,
  div {
    padding: 2px 0 !important;
  }

  span.band {
    display: block;
    clear: both;
    font-size: 10px;
  }
}

td.band-1 {

  a,
  div {
    color: $band-color-1 !important;
  }
}

td.band-2 {

  a,
  div {
    color: $band-color-2 !important;
  }
}

td.band-3 {

  a,
  div {
    color: $band-color-3 !important;
  }
}

/* COLOR PICKER PARAM SETTINGS */

.btn-close-color {
  position: absolute;
  z-index: 2000;
  top: 282px;
  left: 17px;
  height: 34px;
  width: 286px;
}

.input-ex {
  position: absolute;
  z-index: 2000;
  top: 282px;
  width: 100px;
}

.materialpicker-colors {
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  border: 1px solid #FFF;
  border-radius: 2px;
  display: inline-block;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  cursor: default;
  z-index: 200000;
}

.input-color {
  .materialpicker-colors {
    top: 70px;
    left: 16px;
  }
}

.materialpicker-colors div.selected {
  position: relative;
  z-index: 2;
  box-shadow: 0 0 4px #000;
  border: 2px solid #FFF;
}

div.materialpicker-white.selected {
  border-color: #78909C;
}

.colorpicker-preview {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  color: #F8F9F8;
  cursor: pointer;
}

.materialpicker.ng-invalid .materialpicker-colors {
  border-color: #a94442;
  box-shadow: 0 1px 5px #a94442;
}
/* END COLOR PICKER PARAM SETTINGS */


/* PRE-PAYMENT PACKAGES */

.cell-pre-payment,
.room-with-pre-payment {
  background: #f7f7f7;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}
