.GHA-form {
  &__outer-box {
    border: 1px solid $gray;
    border-radius: 6px;
    padding: 20px;
  }

  &__checkbox-holder {
    display: flex;
    align-items: center;

    .field-wrapper {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 0;

      label {
        margin: 0;
      }
    }
  }

  .container .row {
    margin: 18px 0 !important;
  }
}
