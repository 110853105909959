.grid-header-cells {
  height: $dates-height;

  .cell-empty,
  .grid-column,
  .cell-day,
  .room-column {
    height: 100%;
  }

  .grid__nav,
  .cell-empty,
  .cell-day,
  na-selected-floating-rate & {
    background: $gray-100;
    border-color: $gray-300;
    color: $gray-900;
    border-width: 1px 1px 1px 0;
    border-style: solid;
  }

  .cell-day {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-base*0.5 0;
    line-height: 0.75;
    font-weight: 600;

    > span {
      font-size: 10px;
      font-weight: 400;
      margin: 0;
      width: inherit;
      line-height: inherit;
    }
  }
}

.grid-column.first-day {
  border-left: 0;
}

.data-row {

  div.cell-data,
  .cell-room,
  .cell-room-subcat {
    height: $cell-height;
    border-color: $gray-300;
    border-style: solid;
    border-width: 1px;
    margin-top: 10px;
    color: $gray-900;
  }

  .cell-room,
  .cell-room-subcat {
    display: flex;
    align-items: center;
  }

  .cell-room {
    background: $gray-0;

    .room-name,
    .room-expand,
    .whole-row-room-name {
      border: 0;
      background: transparent;
      width: auto;
      max-width: calc(100% - #{$spacing-base*3});
      height: auto;
      padding: 0 0 0 $spacing-base;
      line-height: 1.5;
      font-size: $font-size;
      color: inherit;
    }

    .room-expand {
      font-size: $font-size-sm;
    }

    .room-column & {
      border-width: 1px;
    }
  }

  //content child cells && room child cells
  .cell-data + div > .cell-data,
  .cell-room.cell-room-group + div > .cell-room-subcat {
    margin: 0;
    border-width: 0 1px 1px 0;

    > .cell-data {
      border-bottom: 0;
    }
  }

  .cell-room.cell-room-group + div > .cell-room-subcat,
  .cell-room .room-name {
    width: 100%;
    padding: 0 0 0 $spacing-base;
    font-size: $font-size-sm;
    border-width: 0 1px 1px 1px;

    > .alt:not(:only-child) {
      width: calc(100% - #{$cell-height});
    }

    > i,
    > div > i {
      width: $cell-height;
      text-align: center;
      height: 100%;
      line-height: $cell-height;
      font-size: 15px;
      transition: background-size 0.4s ease-in-out;
      background: radial-gradient(circle, $gray-0 50%, transparent 53%);
      background-size: 0 0;
      background-position: center;
      background-repeat: no-repeat;

      &:hover,
      &.dropdown-trigger--grid {
        background-size: #{$cell-height - $spacing-base*0.5} #{$cell-height - $spacing-base*0.5};
      }

      &.link {
        top: -50%;
        position: relative;
        pointer-events: none;
        width: 0;
        margin-right: $spacing-base;
      }
    }
  }

  .cell-room .room-name {
    max-width: 100%;
    font-size: $font-size;

    > div {
      display: flex;
      align-items: center;

      > i {
        background-image: radial-gradient(circle, $gray-200 50%, transparent 53%);
      }
    }
  }

  div.cell-data {
    width: $cell-min-width;
    border-width: 1px 1px 1px 0;
    font-size: $font-size-sm;
    transition: box-shadow 200ms ease;

    &.manual-edit {
      color: $na-primary;
    }

    &.cell-data-group-no-border {
      background: $gray-0;
      cursor: pointer;
    }

    a {
      width: 100%;
      height: 100%;
      border: 0;
    }

    &:not(.cell-data-group-no-border):not(.updating):not(.updated):not(.error-updating):not(.availability-closeout),
    a {

      &:hover,
      &:focus {
        background: transparent;
        box-shadow: inset 0 0 0 1px $gray-300;
      }
    }

    > div {
      line-height: #{$cell-height - 2};
    }

    > .cell-data {
      margin-top: 0;
      width: 100%;
    }

    > .dropdown-grid:not(.slim) {
      height: 100%;
    }

    > .slim:first-child,
    .dropdown-grid > .slim:first-child {
      padding-top: 2px;
      height: 60%;
      line-height: 1.35;
    }

    > .slim.dropdown-grid,
    .dropdown-grid > .slim:last-child {
      font-size: $font-size-xsm;
      height: 40%;
      line-height: 1.15;
    }

    &.disabled-cell-data:not(.cell-loading-data) {
      background: repeating-linear-gradient(-19deg, #fff 1px, $gray-100 2px, $gray-100 2px, #fff 4px, #fff 8px);

      > .cell-data {
        background: transparent;
        color: $gray-900 !important;
      }
    }

    &.cell-loading-data {
      .icon-loading {
        height: 100% !important;
        background-color: white;
        text-indent: -9999px;
      }

      &:empty:not(.cell-data-group-no-border) { /* stylelint-disable-line */
        @extend .icon-loading;
      }
    }

    > a[contentEditable],
    > div[contentEditable] {
      border-width: 0;
      height: 100%;
      text-align: center;
    }
  }

  .cell-room-subcat {
    overflow: visible;
    border-left-width: 1px;
  }
}

.cell-day,
.cell-room,
.cell-room-subcat {
  transition: color 0.4s ease-in-out;

  &.highlighted {
    color: $na-primary !important;
  }
}

//modals
#bulkupdate,
#availbulkupdate,
#ratefloatmodal {
  .modal-body {
    .row [class*="col-md-"] {
      margin-bottom: $spacing-base*2 !important;
    }

    .daySelector .table-row div a {
      width: 77px;
    }

    .modal--subheader {
      font-weight: 600;
      color: black;
      padding: $spacing-base $spacing-base+5;
      margin-bottom: $spacing-base*2;
    }
  }
}

.field-wrapper--bulk {
  label {
    margin-right: $spacing-base;
  }

  input[type="text"] {
    border-radius: 2px;
    border: 1px solid $gray;
    outline: 0;
  }

  label + span input[type="text"] {
    margin-right: -1px;
  }

  b {
    font-weight: 600;
  }
}

//success
div.cell-data {
  position: relative;
  z-index: 1;

  i {
    transition: 200ms ease all !important;
  }

  &.updating,
  &.updated,
  &.error-updating {
    overflow: hidden;

    .cell-data {
      position: relative;
      z-index: 1;
      background: transparent;
    }

    &:after {
      content: "";
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      background: transparent;
      top: 50%;
      left: 50%;
      transform: scale(0) translate(-50%, -50%);
      transform-origin: top left;
      opacity: 0;
      z-index: -1;
    }
  }

  &.updated:after {
    background: transparent;
    border: 1px solid #999;
  }

  &.error-updating:after {
    background: $color-danger;
  }

  &.error-updating,
  &.updated {
    &:after {
      transform: scale(3) translate(-50%, -50%);
      animation: anim-success2 0.5s ease-in both, disappear 0.5s ease-in both 0.55s;
    }
  }
}

@keyframes anim-success2 {
  0% {
    opacity: 0;
    transform: scale(0) translate(-50%, -50%);
  }

  100% {
    opacity: 1;
    transform: scale(3) translate(-50%, -50%);
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
