// Logos and Backgrounds

// No payment provider
.none-bg {
  background: rgb(56, 71, 144);
  background: -moz-linear-gradient(128deg, rgba(56, 71, 144, 1) 0%, rgba(14, 104, 219, 1) 100%);
  background: -webkit-linear-gradient(128deg, rgba(56, 71, 144, 1) 0%, rgba(14, 104, 219, 1) 100%);
  background: linear-gradient(128deg, rgba(56, 71, 144, 1) 0%, rgba(14, 104, 219, 1) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#384790", endColorstr="#0e68db", GradientType=1);
}

.none-logo {
  display: none;
}

// Stripe
.stripe-bg {
  background: rgb(93, 106, 242);
  background: -moz-linear-gradient(128deg, rgba(93, 106, 242, 1) 0%, rgba(142, 150, 234, 1) 100%);
  background: -webkit-linear-gradient(128deg, rgba(93, 106, 242, 1) 0%, rgba(142, 150, 234, 1) 100%);
  background: linear-gradient(128deg, rgba(93, 106, 242, 1) 0%, rgba(142, 150, 234, 1) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#5d6af2", endColorstr="#8e96ea", GradientType=1);
}

.stripe-logo {
  height: 117px;
  width: 280px;
  opacity: 0.1;
  background-image: url(../../images/payment-card-images/stripe.svg);
}

// global payments
.gp-bg {
  background: #1C6BFF;
  background: -moz-linear-gradient(125deg, #013E74 0%, #1C6BFF 100%);
  background: -webkit-linear-gradient(125deg, #013E74 0%, #1C6BFF 100%);
  background: linear-gradient(125deg, #013E74 0%, #1C6BFF 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#013E74", endColorstr="#1C6BFF", GradientType=1);
}

.gp-logo {
  height: 170px;
  width: 380px;
  background-image: url(../../images/payment-card-images/global-payments.svg);
}

// PayU
.payu-bg {
  background: rgb(72, 168, 9);
  background: -moz-linear-gradient(128deg, rgba(72, 168, 9, 1) 0%, rgba(154, 196, 0, 1) 100%);
  background: -webkit-linear-gradient(128deg, rgba(72, 168, 9, 1) 0%, rgba(154, 196, 0, 1) 100%);
  background: linear-gradient(128deg, rgba(72, 168, 9, 1) 0%, rgba(154, 196, 0, 1) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#48a809", endColorstr="#9ac400", GradientType=1);
}

.payu-logo {
  height: 117px;
  width: 280px;
  opacity: 0.1;
  background-image: url(../../images/payment-card-images/payu.svg);
}

// PCI
.pci-bg {
  background: #02102E;
  background: -moz-linear-gradient(128deg, #02102E 0%, #013E74 100%);
  background: -webkit-linear-gradient(128deg, #02102E 0%, #013E74 100%);
  background: linear-gradient(128deg, #02102E 0%, #013E74 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#02102E", endColorstr="#013E74", GradientType=1);
}

.pci-logo {
  height: 108px;
  width: 280px;
  opacity: 0.08;
  background-image: url(../../images/payment-card-images/pci-proxy.svg);
}

.adyen-bg {
  background: rgb(9, 152, 67);
  background: -moz-linear-gradient(128deg, rgba(9, 152, 67, 1) 0%, rgba(10, 191, 83, 1) 100%);
  background: -webkit-linear-gradient(128deg, rgba(9, 152, 67, 1) 0%, rgba(10, 191, 83, 1) 100%);
  background: linear-gradient(128deg, rgba(9, 152, 67, 1) 0%, rgba(10, 191, 83, 1) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#099843", endColorstr="#0abf53", GradientType=1);
}

.adyen-logo {
  margin-top: -10px;
  height: 90px;
  width: 280px;
  opacity: 0.1;
  background-image: url(../../images/payment-card-images/adyen.svg);
}

// Planet
.planet-bg {
  background: #EC75BA;
  background: -moz-linear-gradient(128deg, #E00082 0%, #EC75BA 100%);
  background: -webkit-linear-gradient(128deg, #E00082 0%, #EC75BA 100%);
  background: linear-gradient(128deg, #E00082 0%, #EC75BA 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#E00082", endColorstr="#EC75BA", GradientType=1);
}

.planet-logo {
  width: 380px;
  margin-top: 30px;
  margin-left: -60px;
  opacity: 0.15;
  background-image: url(../../images/payment-card-images/planet.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

// Pxp
.pxp-bg {
  background: #0D133A;
  background: -moz-linear-gradient(128deg, #0D133A 0%, #49C2D6 100%);
  background: -webkit-linear-gradient(128deg, #0D133A 0%, #49C2D6 100%);
  background: linear-gradient(128deg, #0D133A 0%, #49C2D6 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#0D133A", endColorstr="#49C2D6", GradientType=1);
}

.card-design__invoice_comp_text{
  opacity: 1;
  position: absolute;
  width: 90%;
  background-color: rgba(0, 0, 0, .75);
  padding-top: 22%;
  height: 100%;
  color: white;
  border-radius: 12px;
  div{
    font-size: 15px;
    width: 60%;
    margin-left: 20%;
  }
}

.pxp-logo {
  width: 370px;
  margin-top: 15px;
  margin-left: -25px;
  opacity: 0.1;
  background-image: url(../../images/payment-card-images/pxp_logo-white.svg);
  background-repeat: no-repeat;
  background-size: 160%;
}

// Trust Payments
.trust-bg {
  background: #162031;
  background: -moz-linear-gradient(128deg, #162031 0%, #309D76 100%);
  background: -webkit-linear-gradient(128deg, #162031 0%, #309D76 100%);
  background: linear-gradient(128deg, #162031 0%, #309D76 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#162031", endColorstr="#309D76", GradientType=1);
}

.trust-logo {
  width: 300px;
  margin-top: 21px;
  margin-left: -33px;
  opacity: 0.1;
  background-image: url(../../images/payment-card-images/trust_payments_fullcolour.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.flag-visa {
  height: 16%;
  background-image: url(../../images/payment-card-images/visa.svg);
}

.flag-mastercard {
  height: 21%;
  background-image: url(../../images/payment-card-images/mastercard.svg);
}

.flag-amex {
  height: 20%;
  background-image: url(../../images/payment-card-images/amex.svg);
}

.card-design {
  border-radius: 12px;
  background: #ccc;
  width: 90%;
  height: 1px;
  display: block;
  padding-bottom: 58%;
  position: relative;
  color: #fff;
  font-size: $font-size;
  font-weight: 500;
  overflow: hidden;

  &:hover {

    // card-details hover
    .card-design__logo {
      transform: translate(10px, 10px) rotate(-30deg);
    }

    .card-design__details {
      opacity: 1;

      span {
        opacity: 1;
        transform: translate(-50%, -50%);
      }
    }
  }

  @include respond-to(xsmall) {
    width: 100%;
  }

  &.card-design--none {
    @extend .none-bg;

    .card-design__logo {
      @extend .none-logo;
    }
  }

  &.card-design--invoice_comp{
    @extend .none-bg;
    .card-design__number,.card-design__expiry {
      z-index: auto;
    }
  }

  &.card-design--stripe {
    @extend .stripe-bg;

    .card-design__logo {
      @extend .stripe-logo;
    }
  }

  &.card-design--payu {
    @extend .payu-bg;

    .card-design__logo {
      @extend .payu-logo;
    }
  }

  &.card-design--pci {
    @extend .pci-bg;

    .card-design__logo {
      @extend .pci-logo;
    }
  }

  &.card-design--adyen {
    @extend .adyen-bg;

    .card-design__logo {
      @extend .adyen-logo;
    }
  }

  &.card-design--gp {
    @extend .gp-bg;

    .card-design__logo {
      @extend .gp-logo;
    }
  }

  &.card-design--planet {
    @extend .planet-bg;

    .card-design__logo {
      @extend .planet-logo;
    }
  }

  &.card-design--pxp {
    @extend .pxp-bg;

    .card-design__logo {
      @extend .pxp-logo;
    }
  }

  &.card-design--trust {
    @extend .trust-bg;

    .card-design__logo {
      @extend .trust-logo;
    }
  }

  &__number {
    position: absolute;
    left: 20px;
    top: 35%;
    color: #fff;
    font-weight: 500;
    z-index: 10;
    display: flex;
    align-items: center;
  }

  .card-design__dots {
    letter-spacing: -9px;
    font-size: 4vw;
    line-height: 12px;
    height: 26px;
    display: inline-block;
    vertical-align: text-bottom;
    font-weight: 400;

    @include respond-to(xsmall) {
      letter-spacing: -4px;
      font-size: 45px;
    }

    @media screen and (min-width: 1680px) {
      font-size: 68px;
    }
  }

  .card-design__number-inner {
    font-size: $font-size-h1;
    position: relative;
    top: -2px;

    @media screen and (min-width: 1680px) {
      font-size: 30px;
      top: -1px;
    }
  }

  &__expiry {
    position: absolute;
    left: 20px;
    bottom: 16px;
    font-size: 18px;
    z-index: 10;

    span:first-child {
      font-size: $font-size;
      display: block;
    }
  }

  &__flag {
    display: block;
    width: 30%;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    position: absolute;
    bottom: 20px;
    right: 20px;

    &.card-design--visa {
      @extend .flag-visa;
    }

    &.card-design--mastercard {
      @extend .flag-mastercard;
    }

    &.card-design--amex {
      @extend .flag-amex;
    }
  }

  &__logo {
    margin-top: 10px;
    margin-left: -20px;
    width: 200px;
    height: 100px;
    transform: rotate(-30deg);
    transition: all ease 1000ms;
  }

  &__details {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 20;
    opacity: 0;
    transition: all 200ms ease;
    color: #fff;
    cursor: pointer;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transition: all 600ms $easeOutQuart;
      transform: translate(-50%, 50%);
      opacity: 0;
      color: #fff;
    }
  }

  &__owner {
    position: absolute;
    left: 20px;
    top: 11%;
    font-size: $font-size-md;
  }
}

.payment-card-title {
  @include respond-to(fromtablet) {
    padding-left: 10%;
  }
}

.payment-card-component {
  .swiper-pagination {
    position: relative;
  }

  .swiper-container-horizontal > .swiper-pagination {
    bottom: -4px;

    @include respond-to(fromtablet) {
      left: -20px;
    }
  }

  .swiper-slide {
    @include respond-to(fromtablet) {
      margin-left: -18px;

      &:nth-child(2) {
        margin-left: -19px;

        &.swiper-slide-active {
          margin-left: 0;
        }
      }
    }
  }
}
