.pac-container {
  z-index: 9999999 !important;
}

.search-area,
.map-wrapper {
  padding: $spacing-base - 5;
}

.search-area + .map-wrapper {
  padding-top: 0;
}
