/* plain accordion */
.plain {
  & .panel-heading {
    padding: 0;
    display: table;
    width: 100%;

    & h4 {
      display: table-row;
      width: 100%;

      & a {
        text-decoration: none;
        width: 100%;
      }

      & i {
        padding: 15px;
        border-right: 1px solid #ddd;
        margin-right: 11px;
        color: #8a8a89;

        &.rounded {
          margin-right: 18px;
          padding: 6px !important;
        }
      }
    }

    & .setData {
      width: 90%;
    }

    & .setStock {
      width: 100%;
    }

    & .editColumn {
      padding-right: 19px;
    }
  }

  & .panel-body {
    padding: 0;
    background-color: #f8fafa;

    & .table {
      margin-bottom: 0;
      margin-top: -1px;
    }
  }

  & .panel-group .panel + .panel {
    margin-top: 0;
  }

  & .panel-group {
    > .panel-default.panel {
      margin-bottom: 0 !important;
      border-top: 0 !important;
    }

    .panel-body {
      border-top: 0 !important;
      border-bottom: 1px solid #ddd !important;
    }
  }

  &.tableIndent {
    margin-left: 46px;
    border-left: 1px solid #ddd;
  }

  &table {
    &.first {
      padding-left: 16px;
    }

    &td {
      vertical-align: middle !important;
      padding: 12px !important;
    }
  }

  &div.leftMargin2 {
    margin-left: 46px !important;
    border-left: 1px solid #ccc;
  }

  &.filteredOut {
    display: none !important;
  }

  &.withSubFilter {

    &.panel-default {
      border: none;
    }

    &.tableCell {
      border-bottom: 1px solid #ddd;
    }
  }
}

// Channel manager & payments & stripe component

.table.list-table.accordion,
.card-list.accordion {
  background: 0 0;
  border: none !important;

  na-accordion-part,
  .accordion__item {
    background: white;
    display: block;
    padding: 18px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 10px 0;

    &.cookiePolicy {
      na-parameters {
        .col-md-6 {
          width: 100%;
          padding: 0;
        }

        .field-wrapper {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          label {
            // display: block;
            width: 100%;

            &.field_required:after {
              content: none;
            }
          }
        }

        .form-control {
          max-width: 285px;
        }

        .field-message {
          width: 100%;
        }
      }
    }

    .accordion-part {
      &__notification-block {
        margin: -18px -18px 18px;
        padding: 18px;
        background: $alert-info-bg-color;

        .fa-check-circle {
          font-size: 18px;
          color: $na-primary;
        }
      }
    }

    &.global-payments {
      .noselect > div > div:first-child label span img {
        max-width: 137px;
      }
    }

    .row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .dropdown.dropdown-select {
      margin: 0;

      > a {
        padding: 13px 26px 12px 13px;
        font-size: 1em;

        span {
          right: 11px;
          top: 15px;
          font-size: 0.8em;
        }
      }
    }

    .noselect > div {
      > div:first-child {
        color: black;

        /* Base for label styling with logo */
        label {
          span {
            display: inline-block;

            img[src=""],
            img:not([src]) {
              margin-left: -10px !important;
            }

            &:empty {
              display: none;
            }
          }

          span:nth-child(2) {
            margin-left: 10px;
          }

          span:first-child:last-child {
            margin-left: 0;
          }
        }
      }

      .img-logo {
        max-height: 24px;
        margin: 0 0 0 10px;
        object-fit: contain;
        object-position: left center;
        width: auto;

        &:not([src]) {
          display: none;
        }
      }

      > div:not(:first-child) {
        margin-left: 0 !important;
        margin-right: 0 !important;

        > div {
          margin-top: 20px !important;
        }

        form .container .row {
          margin: 0 !important;
        }
      }
    }

    //style for the radio checkbox
    [type="radio"]:not(:checked),
    [type="radio"]:checked {
      position: absolute;
      left: -9999px;
    }

    [type="radio"]:not(:checked) + label,
    [type="radio"]:checked + label {
      position: relative;
      padding-left: 1.95em;
      cursor: pointer;
      line-height: 30px;
      margin: 0;
    }

    /* radio aspect */
    [type="radio"]:not(:checked) + label:before,
    [type="radio"]:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border: 1px solid #ccc;
      background: #fff;
      border-radius: 50%;
    }

    /* checked mark aspect */
    [type="radio"]:not(:checked) + label:after,
    [type="radio"]:checked + label:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 5px;
      border-radius: 50%;
      font-size: 1em;
      line-height: 0.5em;
      background: $na-primary;
      transition: all 0.2s;
      width: 10px;
      height: 10px;
    }

    [type="radio"]:disabled:checked + label:after {
      background: $gray-dark;
    }

    /* checked mark aspect changes */
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }

    [type="radio"]:checked + label:after {
      opacity: 1;
      transform: translateY(-50%) scale(1);
    }

    /* disabled radio */
    [type="radio"]:disabled:not(:checked) + label:before,
    [type="radio"]:disabled:checked + label:before {
      box-shadow: none;
      border-color: #bbb;
      background-color: #ddd;
    }

    input {
      margin-right: 10px;
    }
  }
}

.card-list.accordion {
  padding: 0;
  font-size: 13px;
}
