//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

a.btn,
.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold;

  @include button-size(13px, 29px, 11px, 1em, $btn-border-radius-base);
  @include user-select(none);
  @include anim-custom(background-color, 200ms, ease);

  &:hover,
  &:focus,
  &.focus {
    color: #fff;
    text-decoration: none;
    outline: 0 !important;
  }

  &:active,
  &.active {
    outline: 0 !important;
    background-image: none;

    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;

    @include opacity(0.65);
    @include box-shadow(none);
  }

  // [converter] extracted a& to a.btn

  &.btn-neutral-outline {
    background-color: transparent !important;
    border-color: $gray-dark;
    color: $gray-dark;
  }

  &.btn-default-outline,
  &.btn-primary .btn-outline {
    @include button-outline { 
      border-color: $na-primary !important;
      color: $na-primary;
    }
  }
}

a.btn {
  @include theme-bg;

  &,
  &:hover {
    color: #fff;
  }

  &.disabled,
  fieldset[disabled] & {
    pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
  }
}

// Alternate buttons
// --------------------------------------------------

.btn-default,
.btn-primary {
  @include theme-border;
  @include theme-bg;

  color: #fff;
}

.btn-neutral {
  color: #fff !important;
}

.btn-primary {
  color: #fff !important;

  @include theme-border;
  @include theme-bg;
}

.btn-secondary {
  color: #fff !important;

  @include theme-border-secondary;
  @include theme-bg('', $na-secondary);

  .loader-wrapper {
    background-color: $na-secondary;
  }
}

.btn-inactivated {
  background-color: $gray-lighter;
  border-left: 1px solid #ccc;

  &:hover,
  &:focus,
  &:active {
    color: #111;
    box-shadow: none;
  }
}

// Success appears as green
.btn-success {
  //@include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
  color: #fff;

  @include theme-border;
  @include theme-bg;
}

// Warning appears as orange
.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

// Danger and error appear as red
.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

.btn-load {
  position: relative;

  &:disabled {
    opacity: 1;
  }
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  @include theme-color;
  padding: 4px 0;
  border-radius: 0;
  font-weight: 500;
  text-transform: unset;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;

    @include box-shadow(none);
  }

  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
    text-decoration: none !important;
  }

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
  }

  &[disabled],
  fieldset[disabled] & {

    &:hover,
    &:focus {
      color: $btn-link-disabled-color;
      text-decoration: none;
    }
  }

  i {
    font-size: 14px;
    vertical-align: text-bottom;
  }
}

// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $btn-border-radius-large);
}

.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}

.btn-xs {
  @include button-size($padding-xs-vertical, $padding-xs-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}

// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

button,
.btn-primary,
.btn-submit {
  .loader-wrapper {
    pointer-events: none;
    border-bottom: 0;

    svg {
      opacity: 0;
      height: 18px;
      width: 18px;

      .svg-spin-icon {
        fill: #fff;
      }
    }
  }

  &.load {
    position: relative;

    &:disabled {
      opacity: 1;
    }

    body.login & {
      color: #228fff !important;
    }

    .loader-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      svg {
        opacity: 1 !important;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -8px auto 0;
      }
    }
  }
}

.btn-checkbox {
  padding-bottom: 14px;
  background-color: $sidebar-background;
  border: 1px solid $gray;
}

.icon-button {
  cursor: pointer;
  padding-left: 12px;
}

.btn-checked {
  line-height: 0 !important;
  color: $na-primary !important;
  padding: 3.7px 5.7px !important;

  span {
    padding-bottom: 4px;
  }
}

.btn-select {
  color: #999 !important;
  border: 1px solid $bg-alt;
  background-color: $bg-alt;

  &:hover {
    color: #000 !important;
  }
}

.btn-selected {
  background-color: #fff;
  color: #000 !important;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #999;
  z-index: 0;

  span {
    color: $na-primary !important;
    padding-right: 5px;
  }
}
