$timepicker-width: 135px;
$timepicker-heigth: 136px;
$timepicker-timebox-height: 98px;
$timepicker-semicolon-height: 28px;

table.uib-timepicker {
  position: relative;
  width: 100%;
  height: $timepicker-timebox-height;

  tr {
    &:first-child {
      td {
        padding-top: 7px;
      }
    }

    &:last-child {
      td {
        padding-bottom: 7px;
      }
    }
  }

  td {
    border: 0;
    height: auto;

    &.uib-time {
      &.am-pm {
        button {
          @include theme-color;

          position: absolute;
          left: 0;
          top: $timepicker-timebox-height;
          border: 0;
          border-top: 1px solid #ccc !important;
          border-radius: 0;
          background: transparent !important;
          width: 100%;
          box-shadow: none;
        }
      }
    }

    &.uib-separator {
      font-weight: bold;
      color: #fff;
      position: relative;

      &:after {
        content: "";
        background: #dedede;
        width: 1px;
        height: $timepicker-timebox-height;
        position: absolute;
        left: 1px;
        top: -$timepicker-semicolon-height;
        z-index: 1;
      }

      &:before {
        content: ":";
        font-weight: bold;
        color: #666;
        background: #fff;
        height: 33px;
        width: 4px;
        line-height: $timepicker-semicolon-height;
        position: absolute;
        top: 3px;
        left: -1px;
        font-size: 19px;
        z-index: 2;
      }
    }
  }

  a.btn.btn-link {
    color: $na-primary !important;
    background: transparent !important;
    padding: 5px 10px !important;
    border: 0 !important;

    &:active {
      box-shadow: none;
    }
  }

  button.btn.btn-default {
    background: transparent;
    color: #666;
  }

  input.form-control {
    border: 0 !important;
    padding: 7px 12px !important;
    font-size: 18px;
    width: 64px;
    font-weight: 400;
  }
}

.timepicker-wrapper {
  width: $timepicker-width !important;
  clear: both;
  height: $timepicker-heigth;
  min-width: inherit !important;
  vertical-align: top;
}
