.editable-icon-row {
  .custom-icon-feature {
    &.editing {
      padding: 2px 0 2px 2px;

      .custom-icon-feature__inner {
        padding: 0;
      }
    }

    &__inner {
      border: none;
    }

    .field-wrapper {
      flex: 1;

      .translation-input span {
        padding: initial;
        width: auto;
        right: 15px;

        @include respond-to(fromxsmall) {
          right: 5px;
        }
      }
    }
  }
}

.custom-icon-feature {
  &.editing {
    padding: 0 15px;

    @include respond-to(fromxsmall) {
      padding: 0;
    }

    .custom-icon-feature__inner {
      padding: 2px 0 2px 2px;
    }
  }

  &:not(.editing) {
    padding: 0 0 0 15px;

    @include respond-to(fromxsmall) {
      padding: 0 15px;
    }

    @include respond-to(xsmall) {
      padding: 0 7px;
    }

    .custom-icon-feature__inner {
      align-items: center;
    }
  }

  &__inner {
    display: flex;
    align-items: flex-start;
    width: 100%;
    border: 1px solid $gray-light;

    @include respond-to(fromxsmall) {
      flex-wrap: wrap;
    }
  }

  &__picker-wrapper {
    display: flex;
    align-items: center;
    border-radius: 3px;
    margin-right: $spacing-base;
    flex: 1;
    position: relative;

    @include respond-to(fromxsmall) {
      margin: 0;
      flex-basis: 100%;
    }
  }

  .btn-icon-picker {
    background-color: $gray-lighter;
    padding: 11px !important;
    border: 1px solid $gray;
    position: absolute;
    top: 0;
    left: 0;
    // height = input height
    height: 41px;
    z-index: 2;

    i {
      color: $na-primary;
      font-size: 20px;
    }
  }

  .btn-link {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 11px 8px;

    @include respond-to(fromxsmall) {
      width: 50%;
      justify-content: center;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.btn-delete {
      position: relative;

      &:before {
        content: '';
        width: 1px;
        height: 19px;
        background: $gray-light;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        @include respond-to(fromxsmall) {
          left: -3px;
        }
      }
    }

    .loader-wrapper svg .svg-spin-icon {
      fill: $gray-icon;
    }

    i {
      margin-right: $spacing-base*0.5;
      font-size: 13px;
      color: inherit;
      width: auto;
      height: auto;
    }

    .fa-trash {
      font-size: 10px;
      margin-bottom: -2px;
    }

    .fa-floppy-disk {
      font-size: 12px;
    }

    span {
      display: flex !important;
      align-items: center;
      padding: 0 !important;
    }
  }

  .field-wrapper {
    flex: 1;

    @include respond-to(fromxsmall) {
      margin: 0;
    }

    .translation-selection {
      top: 30%;
      bottom: auto;
      right: 10px;

      @include respond-to(fromxsmall) {
        right: 1px;
      }
    }
  }

  &__icon-name-input {
    font-size: $font-size !important;
    height: 100% !important;
    border: 1px solid $gray !important;
    padding: 10px 35px 10px 56px !important;

    @include respond-to(fromxsmall) {
      padding: 10px 25px 10px 45px !important;
    }
  }
}

.box-multiselect__panel.selected-features {
  .custom-icon-feature {
    .btn-link {
      font-size: 10px;
    }
  }
}
