na-app-breadcrumbs {
  ol {
    margin-top: 2px;
    margin-bottom: 0;

    li {
      display: inline-block;

      @include respond-to(xsmall) {
        margin-bottom: 0;
      }

      &:before {
        font: var(--fa-font-solid);
        content: '\f054';
        font-size: 10px;
        line-height: 13px;
        margin: 0 15px;
        opacity: 0.7;
        vertical-align: middle;
        color: $gray-medium;

        @include respond-to(xsmall) {
          margin: 0 5px 0 10px;
        }
      }

      span,
      a {
        color: #000 !important;
        font-size: $font-size-base;

        @include respond-to(xsmall) {
          font-size: $font-size-small;
        }
      }

      &:first-child {
        &::before {
          content: none;
        }
      }

      &:last-child {
        a,
        span {
          color: $na-primary !important;
          font-size: $font-size-base;
          font-weight: 600 !important;
          line-height: 14px;

          @include respond-to(xsmall) {
            font-size: $font-size-small;
          }
        }
      }

      a {
        @include anim;

        &:hover {
          color: $na-primary !important;
          text-decoration: none;
        }
      }
    }
  }
}
