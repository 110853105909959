.retargeting-email,
.retargeting-exit-prevention {
  max-width: 600px;

  &__test {
    display: flex;
    align-items: flex-end;

    @include respond-to(fromxsmall) {
    }

    .field-wrapper {
      margin-bottom: 0 !important;
    }

    .btn {
      height: $spacing-base*4;
      margin-left: $spacing-base;
      margin-top: 25px;
    }

    input[type="text"] {
      min-height: 41px;
    }
  }

  &__test-title {
    margin: 45px 0 4px;

    @include respond-to(fromxsmall) {
      margin-top: $spacing-base*2;
    }
  }

  textarea {
    min-height: 300px;
    resize: none;

    @include respond-to(fromxsmall) {
      min-height: 200px;
    }
  }

  a {
    font-weight: 600;
  }

  .field-wrapper {
    &.textarea-holder {
      margin-bottom: $spacing-base;
    }

    &.message-header {
      margin-bottom: 23px;
    }
  }
}

.retargeting-email {
  .field-wrapper {
    margin-bottom: 18px;
  }
}

.retargeting-exit-prevention {
  .select-holder {
    margin: 5px 0 24px;

    .field-wrapper {
      max-width: 160px;
    }

    .description-text {
      padding: 5px 0;
    }
  }

  .description-text {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }
}

.na-modal--exit-prevention {
  text-align: center;
  padding: 75px 28px 65px;

  @include respond-to(fromtablet) {
    min-height: 492px;
    min-width: 493px;
  }

  .na-modal__content {
    width: 100%;
  }

  .fa-history {
    font-size: 51px;
    margin-bottom: 19px;
  }

  h1 {
    color: #515458;
    font-size: 26px;
    padding: 0 0 25px;
  }

  .btn.btn-lg {
    background: $sys-color-2;
    color: #fff;
    margin: 0 auto $spacing-base;
    font-size: 12px;
    border-radius: 3em;
    padding: 16px 24px;

    &:hover {
      background: darken($sys-color-2, 10%);
    }
  }

  p {
    line-height: 1.5;
    margin: 0 auto 32px;
    padding: 0;
    max-width: 320px;

    &.or {
      margin: 0 auto 7px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  a {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
