$submit-btn-width: 102px;

.hubspot-form-content {
  .hs-form-field {
    margin-bottom: $form-group-margin-bottom;
  }

  input[type="text"],
  input[type="email"] {
    display: block;
    width: 100%;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $input-color;
    background-color: $input-bg;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid $input-border;
    border-radius: 2px; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
    padding: $spacing-base $spacing-base+2 !important;

    @include transition(border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s);
    @include placeholder;

    &.error {
      border-left: 1px solid $color-danger;
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    &:hover {
      border-color: $gray-dark;
    }

    &:focus {
      outline: none !important;
      border-color: $gray-dark;
    }

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
      border: 0;
      background-color: transparent;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
  }

  input[type="submit"] {
    display: inline-block;
    margin-bottom: 0; // For input.btn
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: bold;
    height: 39px;
    width: $submit-btn-width;

    @include button-size(13px, 29px, 11px, 1em, $btn-border-radius-base);
    @include user-select(none);
    @include anim-custom(background-color, 200ms, ease);

    color: #111 !important;

    @include theme-border-secondary;
    @include theme-bg('', $na-secondary);

    .loader-wrapper {
      background-color: $na-secondary;
    }

    &:active,
    &.active {
      outline: 0 !important;
      background-image: none;

      @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
    }
  }

  .hs-form-booleancheckbox {
    label {
      min-height: $line-height-computed; // Ensure the input doesn't jump when there is no text
      padding-left: 20px;
      margin-bottom: 0;
      font-weight: normal;
      cursor: pointer;
      color: $gray-dark;
    }

    input[type="checkbox"] {
      text-indent: -9999px;
      opacity: 0;
      position: absolute;

      + span:before {
        border-radius: 2px;
        content: ' ';
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid $gray;
        background-color: #fff;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        margin-right: 5px;
      }

      + span:after {
        @include anim-custom(all, 100ms, ease);

        font: var(--fa-font-light);

        @include theme-color;

        content: '\f00c';
        left: 4px;
        position: absolute;
        z-index: 1;
        top: 3px;
        display: inline-block;
        cursor: pointer;
        transform: scale(0);
        font-size: $font-size-base;
        line-height: 1em;
      }

      &:checked {
        + span:after {
          transform: scale(1, 1);
          color: $na-primary;
        }
      }
    }
  }

  .inputs-list {
    margin: 0 0 $form-group-margin-bottom;

    li {
      list-style: none;
    }
  }

  .hs-error-msg,
  .hs-error-msgs,
  .hs-error-msgs label {
    color: $color-danger;
  }

  .modal-body {
    position: relative;
  }

  .actions {
    text-align: right;
    right: $modal-inner-padding;
    bottom: - 55px;
    position: absolute;
  }

  .modal-footer {
    height: 70px;
  }

  .closeButton {
    right: calc(#{$modal-inner-padding} + #{$submit-btn-width} + #{$modal-inner-padding});
    bottom: $modal-inner-padding;
    position: absolute;

    &.hubspot-form-submitted {
      position: static;
    }
  }
}
