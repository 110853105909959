
.input-date-picker {
  display: table;
  width: 100%;
  padding: $spacing-base $spacing-base+2;
  border: 1px solid #ccc;

  & .tableCell {
    float: none;
    vertical-align: top;
  }

  &.disabled {
    @extend .disabled;

    background: $gray-lighter;
  }
}

.tableRow {
  display: table-row;
}

.tableCell {
  display: table-cell;
  span {
    .close-icon {
      left: -2px;
      top: 3px;

      &.fa-times {
        left: -#{$spacing-base};
        top: 1px;
        font-size: 16px;
        position: relative;
        cursor: pointer;
        transition: 200ms ease;
        &:hover {
          color: $na-primary;
        }
      }
    }
    .arrow-icon {
      font-size: 0.8em;
    }
  }
}

.label-picker {
  div {
    position: absolute;
    left: 0;
    top: 98px;
    border-top: 1px solid #ccc !important;
    background: transparent !important;
    width: 100%;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: bold;
    padding: 13px 0;
    font-size: 11px;
    line-height: 1em;
    color: $text-color-dark !important;
  }
}

table td.no-border {
  border: none;
}
