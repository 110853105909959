$checkbox-size: 20px;

form,
form.form-default {
  .container {
    width: auto !important;

    .row {
      margin: 0 -15px 18px !important;

      label {
        font-weight: normal;
        font-size: $font-size-base;
        margin-bottom: 8px;

        @include anim-custom(color, 300ms, ease);

        text-transform: initial;
      }

      .filter-wrapper {
        &:hover {
          input[type=text],
          input[type=number],
          input[type=email],
          input[type=textarea],
          input[type=password],
          select {
            border-color: $gray-dark;
          }
        }
      }
    }
  }

  /* Fix gap when using clearfix */
  .clearfix.field-margin {
    display: block;
    height: 16px;
  }

  .clearfix.field-margin-2x {
    display: block;
    height: 32px;
  }

  // Input
  input[type=text],
  input[type=number],
  input[type=email],
  input[type=password],
  input[type=tel],
  textarea,
  select {
    padding: $spacing-base $spacing-base+2;
    height: inherit;
  }

  select {
    padding: 9px !important;
    height: auto !important;

    option {
      padding: 10px !important;
    }
  }

  &[disabled] {
    @extend .disabled;
  }

  // .field-error {
  //   .field-message {
  //     padding: 5px 0;
  //     color: $brand-danger;
  //     font-size: $font-size-sm;
  //   }
  // }

  label {
    &.label-checkbox-right {
      text-align: left;
				&:before {
					left: auto;
					right: 5px;
				}

				&:after {
					left: auto;
					right: 9px;
				}

				&.label-text-checked:after {
					transform: scale(1, 1);
					top: calc(50% - 7px);
				}

      .label-text {
        display: block;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;

        &:before {
          left: auto;
          right: 5px;
        }

        &:after {
          left: auto;
          right: 9px;
        }

        &.label-text-checked:after {
          transform: scale(1, 1);
          top: calc(50% - 7px);
        }

        &.label-text-disabled:before {
          background-color: $gray-light;
        }
      }
    }
  }
}

form label.label-checkbox-right,
form.form-default label.label-checkbox-right,
.label-radio,
label {
  input[type=radio],
  input[type=checkbox] {
    position: absolute;
    height: 0;
    opacity: 0;
    visibility: hidden;
    left: -9999px;

    &:focus,
    &:active {
      outline: 0;
    }
  }

  .label-text {
    &:before {
      border-radius: 2px;
      content: ' ';
      width: $checkbox-size;
      height: $checkbox-size;
      border: 1px solid $gray;
      background-color: #fff;
      position: absolute;
      z-index: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:after {
      @include anim-custom(all, 100ms, ease);
      @include theme-color;

      font: var(--fa-font-light);
      content: '\f00c';
      position: absolute;
      z-index: 1;
      top: 3px;
      cursor: pointer;
      transform: scale(0, 0);
      font-size: $font-size-base;
      line-height: 1em;
    }
  }
}

/* stylelint-disable-next-line no-duplicate-selectors */
textarea {
  max-width: 100%;
}

na-checkbox {
  position: relative;
  user-select: none;
}

.field-wrapper {
  @include respond-to(fromxsmall) {
    margin-bottom: $spacing-base;
  }
}

label {
  display: inline-block;
  max-width: 100%; // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
  margin-bottom: 5px;
  position: relative;
  color: black;

  .label-text {
    @include noselect;

    cursor: pointer;
    display: inline-block;
    padding: 0 0 0 30px;
    line-height: 1.2;
    position: relative;

    &:before {
      left: 0;
    }

    &:after {
      left: 4px;
    }
  }

  /* Checkbox */
  input[type=radio],
  input[type=checkbox] {
    &:disabled + .label-text:before {
      background-color: $gray-light;
    }

    &:checked {
      + .label-text:after {
        transform: scale(1, 1);
        top: calc(50% - 7px);
      }
    }
  }


  /* Radio */
  input[type="radio"] {
    + .label-text {
      line-height: 20px;
    }

    + .label-text:before {
      border-radius: 50%;
      content: ' ';
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid $gray;
      background-color: #fff;
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      transform: none;
    }

    &:disabled + .label-text:before {
      background-color: $gray-light;
    }

    + .label-text:after {
      width: 10px;
      height: 10px;
      top: 5px !important;
      left: 5px;
      border-radius: 50%;
      content: ' ';
      z-index: 1;

      @include theme-bg;
    }
  }
}

.label-text{
	&.parameter-description {
		font-size: $font-size-sm;
		margin-top: 10px;
	}
}

/*admin/general/companies/*/

.zip_style {
  margin-top: 10px !important;
}

input#float_change {
  padding: 10px 12px;
  height: auto;

  &:hover {
    border-color: #ccc;
  }
}

// for the media library fields
na-media-library-popup {
  .well {
    box-shadow: none;

    .row {
      margin-bottom: 0 !important;

      #openMediaLibrary {
        white-space: normal;
      }
    }
  }

  .na-media-library-popup__input {
    @extend .form-control;
    @extend .input-group;

    height: auto;
    line-height: 20px;
    padding: 0;
    cursor: pointer;
    table-layout: fixed;

    &:hover {
      border-color: $input-group-addon-border-color;
    }

    .field-text-wrap {
      //used for the media library file name.
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      float: left;
      width: 100%;
    }

    > * {
      display: table-cell;
      white-space: nowrap;
      vertical-align: middle;
      padding: $spacing-base $spacing-base+2;
    }
  }

  .openMediaLibrary {
    line-height: 20px;
    background-color: $input-group-addon-bg;
    border-left: 1px solid $input-group-addon-border-color;
    width: 45px;
    font-size: 16px;
    transition: 0.2s ease;

    &:hover {
      color: $na-primary;
    }
  }
}

.col-md-6.row.ng-scope.margin-number-toggle {
  margin-right: 15px !important;
}

.optional {
  font-size: $font-size-sm;
  color: $text-color;
  text-transform: lowercase;
  font-weight: normal;
}

// form .container {
.custom-form-fields {
  &__dropdown-option {
    .option-wrapper {
      border-bottom: 1px solid $input-group-addon-border-color;
      padding-bottom: 15px;
      display: flex;
      align-items: flex-start;
    }
  }

  &__drag-image {
    margin-right: 22px;
    margin-top: 13px;
  }

  &__trash-icon {
    margin-left: 28px;
    margin-top: 10px;
  }

  &__drag-image,
  &__trash-icon {
    cursor: pointer;

    &:hover {
      i {
        color: darken($input-group-addon-border-color, 10%);
      }
    }

    i {
      @include anim();

      color: $input-group-addon-border-color;
    }
  }

  &__dropdown-option-input {
    flex-grow: 1;
    padding: 0;
  }

  &__required-checkbox {
    margin: 23px 0 18px;

    a {
      text-decoration: underline;
    }
  }

  &__limit {
    margin-bottom: 25px !important;

    na-tariff-group-dropdown {
      display: block;
      margin-bottom: 7px;
    }
  }

  &__field-type,
  &__placeholder {
    margin-bottom: 25px !important;
  }

  .btn {
    color: #000;
    border-color: #000;
  }

  

  .dndPlaceholder {
    height: 57px;
    background-color: $gray-lighter !important;
    list-style: none;
    width: 50%;
  }

  .dndDraggingSource {
    display: none;
  }

  .options-title label {
    font-weight: 600 !important;
    font-size: $font-size-md !important;
  }
}

@media (max-width: 991px) {
  .col-md-6.row.ng-scope.margin-number-toggle {
    margin-right: -15px !important;
  }
}

label.occupancyIndicator {
  margin-bottom: 20px !important;
}



.dropdown-menu {
  &.dropdown-menu-body {
    z-index: 99999999 !important;
  }
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 6px 0 6px 6px;
}

na-labeled-dropdown {
  .dropdown.dropdown-select {
    & > a {
      padding: 8px 26px 8px 13px;

      span {
        top: 7px;
      }
    }
  }

  .labelable {
    top: 5px;
    padding-left: 0;

    @include respond-to(fromxsmall) {
      & + .col-md-9 {
        padding-right: 0;
      }
    }

  }
}



