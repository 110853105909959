
/* This file contains variable declarations (do not remove this line) */

/*-- VARIABLES (DO NOT REMOVE THESE COMMENTS) --*/

//$bootstrapDirectory: "../../../node_modules/bootstrap";

/**
* $section Grid styles
*/
$gridBorderWidth: 1px;
$gridBorderRadius: 0;
$borderColor: #d4d4d4;


/**
* $section Header styles
*/

/** $description Colors for header gradient */
$headerBackgroundColor: #efefef;
$headerGradientStart: #eee;
$headerGradientStop: #fff;
$headerVerticalBarColor: $borderColor;

/**
* $section Grid body styles
*/

/** $description Colors used for row alternation */
$verticalBarColor: $borderColor;
$rowColorEven: #f3f3f3;
$rowColorOdd: #fdfdfd;

// TODO: colors for buttons

/**
* $section Sort arrow colors
*/

$sortArrowBackgroundColor: #aaa;
$sortArrowBorderColor: #777;

// TODO: color for menu background
$rowHeaderCell: #F0F0EE;
$rowSelected: #C9DDE1;
$rowSavingForeground: #848484;
$rowErrorForeground: #F00;
$rowDirtyForeground: #610B38;

// TODO: color for cell selections
$focusedCell: #b3c4c7;

// Color to use for enabled or selected settings/items/cells, etc. Should probably override the one above
$selectedColor: #cecece;

/**
* $section Scrollbar styles
*/
$scrollbarBackground: darken($rowColorEven, 15%);
$scrollbarBackgroundHover: darken($scrollbarBackground, 15%);
$scrollbarBackgroundScrolling: darken($scrollbarBackgroundHover, 15%);
$scrollbarWidth: 10px;
$scrollbarBorderRadius: 2px;
$scrollbarShadow: 0 0 0 #fff;
$scrollbarBorder: 1px solid darken($scrollbarBackground, 15%);
$scrollbarBorderScrolling: 1px solid darken($scrollbarBackgroundScrolling, 15%);

//Border to be applied to editors when the input value or invalid
$invalidValueBorder: 1px solid rgb(252, 143, 143);
$validValueBorder: 1px solid $borderColor;

//Pagination controls
$paginationButtonColor: $headerGradientStart;
$paginationButtonBackgroundColor: $headerBackgroundColor;
$paginationButtonBorderColor: #ccc;


/**
* $section font library path
*/
$font-path: '';

/*-- END VARIABLES (DO NOT REMOVE THESE COMMENTS) --*/
