.plus-minus {
	>div {
		display: flex;
    align-items: center;
    justify-content: space-between;
	}

	.form-control {
		line-height: 1em;
		padding: 0;
		font-size: $font-size-md;
		height: 28px;
		pointer-events: none;
		background: none;
		border: none;
		box-shadow: none;
		max-width: 32px;
		width: 32px;
		// @include font(bold);
	}

	.btn.btn-default-outline {
    padding: 5px;
    width: 30px;
		height: 30px;
    border-radius: 50%;
	}
}