// export data actions
.dashboard__export {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;

  .fa-file-export,
  .fa-share-nodes {
    font-size: 18px;
    margin-right: 5px;
  }
}

.dashboard__export-link {
  color: #fff;
  
  &:hover,
  &:focus { 
    color: #fff;
    text-decoration: none;
  }
} 

.dashboard__dwm-dropdown {
  padding: $spacing-base 20px 25px;
  width: 300px;
  border-radius: 6px;
  box-shadow: none;

  @include respond-to(xsmall) {
    width: 280px;
    padding-right: 10px;
    padding-left: 10px;
    transform: translateX(15%);
  }

  @media (max-width: 375px) {
    right: 0 !important;
    transform: none;
  }

  h3 {
    margin-top: 6px;
  }

  .na-grid-select, 
  na-grid-select{
    .cell {
      &:after {
        left: 10px;
      }

      &.selected a {
        padding-right: 10px;
        padding-left: 25px;
      }

      a {
        padding: 12px 10px;
        text-align: center;
        text-overflow: initial;
      }
    }
  }

  @include respond-to(xsmall) {
    .na-grid-select {
      & > div {
        display: flex;
      }
  
      .cell {
        flex: 1;
      }
    }
  }
}

.dashboard__pdf-dropdown {
  padding: $spacing-base 5px 20px;
  width: 300px;
  border-radius: 6px;
  box-shadow: none;

  h3 {
    font-weight: 700;
  }

  .md-chips {
    @extend .custom-scroll;
    margin-bottom: 15px;
    max-height: 140px;
    padding: 0 0 4px 8px;
  }

  .chip-error {
    margin: -5px 0 15px;
  }
}

.dashboard__separator {
  display: inline-block;
  margin: 0 3px;
}