/*  Breakpoints usage

@include respond-to(xsmall) { ... }
@include respond-to(small) { ... }
@include respond-to(medium) { ... }
@include respond-to(large) { ... }

 */

/*if this size change, we need to change these in the MediaQueryFilter.js file*/
$value-small: 768px;
$value-medium: 992px;
$value-large: 1200px;
$value-xlarge: $container-max-width;
$value-container: $container-max-width; //1124px

@mixin respond-to($breakpoint) {
  @if $breakpoint =="xxsmall" {
    @media (max-width: 374px) {
      @content;
    }
  }

  // max-width: 991px
  @if $breakpoint =="fromsmall" {
    @media (max-width: $value-medium - 1) {
      @content;
    }
  }

  @if $breakpoint =="xsmall" {
    @media (max-width: $value-small - 1) {
      @content;
    }
  }

  @if $breakpoint =="fromxsmall" {
    @media (max-width: $value-small) {
      @content;
    }
  }

  // 768px - 991px
  @else if $breakpoint =="small" {
    @media (min-width: $value-small) and (max-width: $value-medium - 1) {
      @content;
    }
  }

  // 992px - 1199px
  @else if $breakpoint =="medium" {
    @media (min-width: $value-medium) and (max-width: $value-large - 1) {
      @content;
    }
  }

  // min-width 768px
  @else if $breakpoint =="fromtablet" {
    @media (min-width: $value-small) {
      @content;
    }
  }

  // min-width 992px
  @else if $breakpoint =="frommedium" {
    @media (min-width: $value-medium) {
      @content;
    }
  }

  @else if $breakpoint =="large" {
    @media (min-width: $value-large) and (max-width: $value-xlarge - 1) {
      @content;
    }
  }

  // min-width 1124px
  @else if $breakpoint =="xlarge" {
    @media (min-width: $value-xlarge) {
      @content;
    }
  }

  // max-width 1124px
  @else if $breakpoint =="fromcontainer" {
    @media (max-width: $value-container) {
      @content;
    }
  }
}

//all animations in _na-animations-custom.scss
@mixin animation-panel-reveal {
  animation: nafadeIn 0.3s ease-out;
}

@mixin animation-panel-close {
  animation: nafadeOut 0.3s ease-in;
}

@mixin animation-error-enter {
  animation: naErrorFadeIn 0.3s ease-in-out;
}

@mixin animation-error-leave {
  animation: naErrorFadeOut 0.5s ease-in-out;
}

@mixin h-scroll {
  overflow-x: auto;
  white-space: nowrap;
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

@mixin font($weight) {
  @if $weight =="thin" {
    font-weight: 300;
    font-style: normal;
  }

  @else if $weight =="light" {
    font-weight: 300;
    font-style: normal;
  }

  @else if $weight =="regular" {
    font-weight: 400;
    font-style: normal;
  }

  @else if $weight =="regularit" {
    font-weight: 400;
    font-style: italic;
  }

  @else if $weight =="semi" {
    font-weight: 600;
    font-style: normal;
  }

  @else if $weight =="semiit" {
    font-weight: 600;
    font-style: italic;
  }

  @else if $weight =="bold" {
    font-weight: 700;
    font-style: normal;
  }

  @else if $weight =="boldit" {
    font-weight: 700;
    font-style: italic;
  }

  @else if $weight =="xbold" {
    font-weight: 800;
    font-style: normal;
  }
}

@mixin chuncky($weight: xbold) {
  @include font($weight);

  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  text-transform: uppercase;
}

// @include text-overflow(14,2,2);
@mixin text-overflow($size: $font-size, $line-height: 1.5, $lines-to-show: 2) {
  display: block;
  /* Fallback for non-webkit */
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  display: -webkit-box;
  max-width: 100%;
  height: $size*$line-height*$lines-to-show;
  /* Fallback for non-webkit */
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  font-size: $size;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  padding: 0;
}

@mixin resetList {

  ul,
  ol {
    li {
      list-style-type: auto;
      margin-bottom: 6px;
      display: list-item;
    }
  }

  ul {
    li {
      margin-left: 16px;
      list-style: disc !important;
    }
  }

  ol {
    li {
      margin-left: 18px;
      list-style: decimal !important;
    }
  }
}

@mixin reset {
  font-weight: inherit;
  color: $text-color;
  padding: inherit;
  border: none;
  background-color: white;
  font-size: inherit;
  font-family: inherit;
  border-radius: 0;
}

@mixin word-break {
  //breakline for long-word languages
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

@mixin div-fill {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
}

@mixin bg-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin anim($speed: 300ms, $ease: ease) {
  transition: $speed $ease;
}

@mixin anim-custom($el: all, $speed: 300ms, $ease: ease) {
  transition: $el $speed $ease;
}

// @include gradient-hor(#000000,1,#FFFFFF,1)
@mixin gradient-hor($color1, $opacity1, $color2, $opacity2) {
  background: rgba($color1, 0);
  background: -moz-linear-gradient(left, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba($color1, $opacity1)), color-stop(100%, rgba($color2, $opacity2)));
  background: -webkit-linear-gradient(left, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: -o-linear-gradient(left, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: -ms-linear-gradient(left, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: linear-gradient(to right, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$color1', endcolorstr='$color2', gradienttype=1);
}

// @include gradient-ver(#000000,1,#FFFFFF,1)
@mixin gradient-ver($color1, $opacity1, $color2, $opacity2) {
  background: rgba($color1, 0);
  background: -moz-linear-gradient(top, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba($color1, $opacity1)), color-stop(100%, rgba($color2, $opacity2)));
  background: -webkit-linear-gradient(top, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: -o-linear-gradient(top, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: -ms-linear-gradient(top, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  background: linear-gradient(to bottom, rgba($color1, $opacity1) 0%, rgba($color2, $opacity2) 100%);
  filter: progid:dximagetransform.microsoft.gradient(startcolorstr='$color1', endcolorstr='$color2', gradienttype=0);
}

@mixin noselect {
  user-select: none;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: ($opacity * 100);

  filter: alpha(opacity=$opacity-ie);
}

@mixin rotate($deg) {
  transform: rotate($deg);
  /* Standard syntax */
}

@mixin border-radius($radius) {
  border-radius: $radius;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

@mixin scale($width, $height) {
  transform: scale($width, $height);
}

@mixin overlay($color) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color;
}

@mixin box-shadow($shadow) {
  @if $shadow =="default" {
    box-shadow: $box-shadow;
  }

  @else if $shadow =="top" {
    box-shadow: $box-shadow-top;
  }

  @else if $shadow =="dark" {
    box-shadow: $box-shadow-dark;
  }
}

@mixin fx-elevate($distance, $blur, $opacity) {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0);

  &:hover {
    box-shadow: 0 $distance $blur 0 rgba(0, 0, 0, $opacity);
  }
}

@mixin removeAnim {
  max-height: 2000px;

  &.remove-item {
    animation: itemOut 300ms ease forwards;
  }
}

@mixin flex($align, $justify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin input-placeholder-color($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }

  &::-moz-placeholder {
    color: $color;
  }

  &:-moz-placeholder {
    color: $color;
  }

  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin columns($columns) {
  -webkit-columns: $columns;
  -moz-columns: $columns;
  columns: $columns;
  //This bellows make sure the content doesn't looks funny
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -moz-page-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
}

@mixin loading-state {
  background-color: #f6f7f8 !important;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) !important;
  background-repeat: no-repeat !important;
  background-size: 800px 104px !important;
  display: inline-block;
  position: relative;
  pointer-events: none;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}
