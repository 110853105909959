.header-options {
  label {
    font-size: 14px;
    font-weight: 600;
  }

  li {
    label {
      font-size: 13px;
      font-weight: normal;

      input {
        vertical-align: top;
      }
    }
  }
}

table.list-table {
  border-top: 1px solid $gray-light;
  border-bottom: 1px solid $gray-light;
  background-color: $gray-lighter;

  tr {
    &.disabled {
      td:not(.col-dropdown) {
        color: $gray;
        pointer-events: none;

        a,
        > * {
          opacity: 0.4;
        }
      }
    }

    &.item-selected {
      td {
        @include theme-bg('hoverOff', $na-primary !important);

        &,
        a {
          color: #fff;
        }

        input,
        select,
        select option {
          pointer-events: none !important;
          display: none;
        }

        &.col-select {
          i {
            display: block;
          }
        }
      }
    }

    &.dndPlaceholder {
      td {
        background-color: transparent;
        height: 51px;
      }
    }

    &.dndDraggingSource {
      display: none;
    }

    &.collapse-content {
      > td {
        background-color: $gray-lighter;
        padding: 0 !important;
        font-size: 0.8em;

        table {
          background-color: $gray-lighter;
          margin: 0;
          width: 100%;

          tr {
            td {
              border-bottom: 1px solid $gray-light;
              font-size: 1.2em;
              background-color: $gray-lighter;

              &.col-dropdown {
                font-size: 1.1em;
                text-align: center;
                width: 45px;
                padding-left: 14px !important;
                padding-right: 14px !important;
              }
            }
          }
        }
      }
    }
  }

  th,
  td {
    background-color: #fff;
    text-align: left;
    border: 0;
    border-bottom: 1px solid $gray-light;
    font-size: $font-size-base;
    padding: $spacing-base*2;
    vertical-align: middle;
    line-height: 1em;
    width: inherit;
    transition: background-color 0.3s ease, color 0.3s ease;

    @include respond-to(xsmall) {
      padding: $spacing-base;
      font-size: $font-size-sm;
      line-height: 1.3;
    }

    a {
      &.email {
        color: $text-color;

        &:hover {
          text-decoration: none;
          color: #000;
        }
      }
    }

    &.text-right {
      text-align: right;
    }

    &.col-xs {
      width: 50px;

      @include respond-to(xsmall) {
        width: 30px;
      }
    }

    &.col-sm {
      width: 100px;
    }

    &.col-md {
      width: 180px;
    }

    &.col-lg {
      width: 260px;
    }

    &.col-icon,
    &.col-select,
    &.col-collapse,
    &.col-dropdown {
      width: 45px;
    }

    &.col-drag {
      width: 20px;
      padding: 0 15px !important;
      text-align: center;
      vertical-align: middle;

      img {
        pointer-events: none;
        opacity: 0.3;

        @include anim;
      }

      &:hover {
        img {
          opacity: 1;
        }
      }
    }

    &.col-icon {
      padding: 0 0 0 10px !important;
      text-align: center;
      vertical-align: middle;
    }

    &.col-badge {
      width: auto;
      min-width: 35px;
      padding-right: 0 !important;
      text-align: left;

      .sys-badge {
        font-size: $font-size-xsm;
        text-transform: uppercase;
        border: 1px solid $na-primary;
        color: $na-primary;
        font-weight: 600;
        margin-bottom: $spacing-base*0.5;
        display: inline-block;
        line-height: 1.3;

        &--disabled,
        &.test-label {
          border-color: $gray;
          background: $gray;
          color: white;
          padding: 4px;
          border-radius: 3px;
        }

        &--active {
          border-color: $success-color;
          background: $success-color;
          color: white;
          font-weight: 400;
          padding: 4px;
          border-radius: 3px;
        }

        &--new {
          border-color: $na-secondary;
          background: $na-secondary;
          color: black;
        }
      }
    }

    &.col-main {
      font-size: 1.2em;
    }

    &.col-collapse {
      border-right: 1px solid $gray-light;
      text-align: center;
      padding: 0 !important;
      vertical-align: middle;
      cursor: pointer;

      .btn-collapse-icon {
        display: block;
        padding: 14px;

        i {
          font-size: $font-size-base;
          line-height: 1em;
        }
      }
    }

    &.col-select {
      i {
        display: none;
      }
    }

    &.col-dropdown {

      &,
      * {
        vertical-align: bottom;
      }

      span.glyphicon {
        font-size: 21px;
        cursor: pointer;
      }
    }

    &.noresults {
      padding: 0 !important;
      height: 0;

      > div {
        padding: ($spacing-base*6) ($spacing-base*4);
        color: $gray-dark;
        font-size: $font-size-large;
        font-weight: 300;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid $border-color;
        background: #fff;

        span {
          display: block;

          @include anim-reveal('fadeInBottom');
        }

        i {
          display: block;
          margin-bottom: 15px;
          color: darken($gray, 10%);
          font-size: 30px;
        }
      }
    }

    /* Custom Forms */
    .form-control {
      font-size: 11px;
      padding: 0;
      height: 22px;
    }
  }

  th {
    background-color: $gray-lighter;
    font-weight: 600;
  }

  td {
    .code-title-table {
      margin-left: 4px;
      font-size: 10px;
      color: $text-color-light;
    }

    &.disabled {
      color: #ccc;
      pointer-events: none;

      .code-title-table {
        color: #ccc;
      }
    }

    &.muted {
      color: #ccc;

      a {
        color: #ccc !important;
      }
    }
  }

  &.sort-manual {
    tr {
      td {
        &:hover {
          cursor: grab;
          cursor: -moz-grab;
          cursor: -webkit-grab;
        }
      }
    }
  }

  //locked user

  .locked {
    &:before {
      font: var(--fa-font-solid);
      content: '\f023';
      margin-right: $spacing-base;
    }
  }
}

// uib-dropdown
.list-table span.dropdown {
  > a {
    color: $text-color !important;
    border: 0;
    white-space: nowrap;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
      color: #000;
    }
  }
}

*[field-type=multiselect] span.dropdown {
  a {
    &:before {
      display: none;
    }
  }
}

na-field-component .bsuite-logo {
  max-width: 100%;
}

#userListAL,
#weddingListAL,
#corporateListAL {
	.list-table {
		// table height with 5 rows to prevent nullable table height
		min-height: 240px;
		background-color: #fff;
    border-bottom: none;

    @include respond-to(fromtablet) {
      position: relative;
      // table height with 5 rows to prevent nullable table height
      min-height: 312px;
    }
  }

  .loading-row {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    z-index: 10;

    td {
      width: 100%;
      height: 100%;
      padding: 0;
    }

    .loader-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $spacing-base*2;
  
      tr {
        min-height: 250px;
  
        @include respond-to(fromtablet) {
          min-height: 310px;
        }
      }
    }
  }  
}

#userListAL {
  #userList {
    @include respond-to(fromxsmall) {
      position: relative;
    }
  }

  .error-row {
    td {
      text-align: center;
      line-height: 1.4;
      color: #757575;
      height: 300px;
    }

    &__title {
      margin-bottom: 10px;
      font-weight: bold;
      color: #515458;
    }

    &__text {
      margin-bottom: 18px;
    }

    button {
      padding: 8px 27px;
      background-color: #fff;
      color: #000;
      border-color: #000;
      border-radius: 3px;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
