.pre-post-emails__edit {
  max-width: 595px;

  .preview-link {
    @include anim();

    font-weight: 600;
  }

  .field-wrapper {
    margin-bottom: 15px;

    &.select-days-holder {
      margin: 15px 0 0;
      display: flex;
      align-items: center;

      .select-days {
        display: grid;
        grid-template-areas: "select";
        align-items: center;
        cursor: pointer;
        width: 51px;
        height: 31px;
        margin-left: 10px;
        border: 1px solid $gray;
        border-radius: $border-radius;

        &:after {
          content: "";
          width: 10px;
          height: 6px;
          margin-right: 8px;
          background-color: #000;
          clip-path: polygon(100% 0%, 0 0%, 50% 100%);
          grid-area: select;
          justify-self: end;
          pointer-events: none;
        }
      }

      select {
        appearance: none;
        background-color: transparent;
        border: none;
        padding: 0 8px !important;
        margin: 0;
        width: 100%;
        height: 100% !important;
        font-family: inherit;
        font-size: inherit;
        cursor: inherit;
        line-height: inherit;
        outline: none;
        grid-area: select;
      }
    }
  }

  .mce-i-resize {
    display: none !important;
  }

  .mce-charactercount {
    font-size: 12px;
  }
}
