.payment-providers {
  ul:not([class]) {
    list-style: initial;
    padding-left: $spacing-base*2;
    margin-bottom: $spacing-base*2.5;
    color: #000;
  }

  &__info-for-gibe {
    margin-top: $spacing-base*2.5;
  }

  &__info-for-gibe-ifFail {
    font-size: 12px;
    color: #666;
  }

  na-accordion-part.individual-member-class {
    .noselect>div>div:first-child label span {
      &:before {
        content: '\f594';
        font: var(--fa-font-regular);
        margin-right: 3px;
      }
    }
  }
}