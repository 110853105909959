#link-lab {
  .field-wrapper {
    margin-bottom: $spacing-base;
  }
}

#link-lab {
  .btn-preview {
    right: $spacing-lg*2 + 5px !important;
  }

  .box-widget {
    margin-top: $spacing-lg - 5px;

    pre {
      padding: $spacing-lg+2px $spacing-base*2 !important;

      &.preview {
        padding-top: $spacing-lg - 5px !important;
        padding-bottom: $spacing-base*2 + 8px !important;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }
  }
}
