.widget-settings {
  .occupants-multiselect {
    position: relative;

    .dropdown-toggle {
      background: #fff;
    }

    .dropdown-menu {
      padding: $spacing-base*1.5
    }

    .glyphicon {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 0.8em;
      cursor: pointer;
      pointer-events: none;
    }

    .occupant {
      display: flex;
      align-items: center;
      margin-bottom: $spacing-base;

      &__label {
        font-size: 13px !important;
        margin-bottom: 0 !important;
        flex: 1;
        font-weight: bold !important;
        padding-right: 65px
      }
    }
  }

  .parameter-row .field-wrapper.param-radio--multivalue {
    &:has(.label--block) {
      margin-bottom: 0;
    }

    .label--block {
      margin-bottom: 15px;
    }
  }

  .param-multiselect-boxes {
    .table-row {
      display: flex;
      align-items: flex-start;
    }

    .multiselect-box__cell {
      width: calc(100% / 5);

      &.disabled .multiselect-box__button {
        border: none;

        &:before {
          content: none;
        }
      }

      &.selected .multiselect-box__button {
        background-color: #fff;

        @include respond-to(fromxsmall) {
          padding: 11px 4px 11px 18px;
        }
      }

      .multiselect-box__button {
        border: 1px solid #999;
        background-color: #f5f5f5;

        @include respond-to(fromxsmall) {
          font-size: 13px;

          &:before {
            left: 4px;
            font-size: 13px;
          }
        }
      }
    }

    .multiselect-box__note {
      background-color: #fff;
      font-size: 12px;
      
      @include respond-to(fromtablet) {
        padding-top: 3px;
      }
    }
  }
}