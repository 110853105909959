/* IE Hacks */
/*
 * Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
 *
 * [1] IE9
 * [2] IE10+
 */
/* 1 */
.ie9 img[src$=".svg"] {
  width: 100%;
}

/* -------- */

// Format the SVG as a URL
@function inline-svg($string) {
  @return url('data:image/svg+xml,#{url-encode($string)}');
}

.full-bg {
  background-size: cover;
  background-position: center center;
}

.bg-neutral {
  background-color: #f7f7f7;

  p {
    padding: $spacing-base;
  }
}

body.is-searching {
  overflow: hidden;
}

.is-cancel {
  text-decoration: line-through;
}

.div-fill {
  @include div-fill;
}

.radius {
  @include border-radius($border-radius);
}

.radius-sm {
  @include border-radius($border-radius-sm);
}

.scroll {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  @media (max-width: 768px) {
    position: relative;
    display: block;
    height: auto !important;
  }
}

.np {
  padding: 0 !important;
}



//no padding bottom
.np-b {
  padding-bottom: 0 !important;
}

.np-t {
  padding-top: 0 !important;
}

.np-r {
  padding-right: 0 !important;
}

.np-l {
  padding-left: 0 !important;
}

.nm,
.row {
  margin: 0 !important;
}

.size-body {
  font-size: $font-size-base !important;
}

.width-80p {
  width: 80%;
}

.width-60p {
  width: 80%;
}

.width-40p {
  width: 80%;
}

.width-20p {
  width: 20%;
}

.fitwidth {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.visible-xxs {
  display: none;
}

.md-form-fix,
.form-margin-fix {
  margin-right: -10px;
  margin-left: -10px;
}

.h-space {
  width: 100%;
  display: block;
  margin-top: $spacing-base*0.5;
  margin-bottom: $spacing-base*0.5;
  clear: both;

  &.x2 {
    height: $spacing-base*2;
  }
}

.h-space-big {
  width: 100%;
  display: block;
  margin-top: $spacing-base*2;
  margin-bottom: $spacing-base*2;
  clear: both;

  &.x2 {
    height: $spacing-base*2;
  }
}

.color {
  color: $secondary-color;
}

.color-gray {
  color: $gray;
}

.color-primary {
  //blue
  color: $na-primary;
}

.box-shadow {
  @include boxShadowReports;
}

.anim {
  @include anim;
}

.no-animate {
  transition: none !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.avoid-clicks {
  pointer-events: none;
}

.hint {
  color: $gray-lighter;

  &.hint-sm {
    font-size: 13px !important;
  }
}

.separator-hor {
  height: 1px;
  background-color: $border-color;
  display: block;
}

/* Same Height Columns */
.row-h {
  display: table;

  &:before,
  &:after {
    display: none;
  }
}

.row-h [class*="col-"] {
  float: none;
  display: table-cell;
  vertical-align: top;
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Large devices */
@include respond-to(large) {
  .no-padding-lg,
  .np-lg {
    padding: 0 !important;
  }

  .padding-lg {
    padding: 15px;
  }

  .txt-center-lg {
    text-align: center;
  }

  .pull-none-lg {
    float: none !important;
  }

  .block-lg {
    display: block !important;
  }
}

/* Medium Devices */
@include respond-to(medium) {
  .no-padding-md,
  .np-md {
    padding: 0 !important;
  }

  .padding-md {
    padding: 15px;
  }

  .txt-center-md {
    text-align: center;
  }

  .pull-none-md {
    float: none !important;
  }

  .block-md {
    display: block !important;
  }
}

/* Small devices */
@include respond-to(small) {
  .hidden-xs {
    display: none !important;
  }

  .visible-xs {
    display: block !important;
  }

  .no-padding-sm,
  .np-sm {
    padding: 0 !important;
  }

  .padding-sm {
    padding: 15px;
  }

  .txt-center-sm {
    text-align: center !important;
  }

  .pull-none-sm {
    float: none !important;
  }

  .block-sm {
    display: block !important;
  }
}

@include respond-to(xsmall) {
  .no-padding-xs,
  .np-xs {
    padding: 0 !important;
  }

  .padding-xs {
    padding: 15px;
  }

  .txt-center-xs {
    text-align: center;
  }

  .pull-none-xs {
    float: none !important;
  }

  .hidden-xxs {
    display: none !important;
  }

  .block-xs {
    display: block !important;
  }
}

.no-child {
  > div {
    padding-left: 0;
    padding-right: 0;
  }
}

//custom scroll
.custom-scroll {
  overflow: auto !important;
  overflow-y: auto !important;
  padding: 0;

  &::-webkit-scrollbar-track {
    margin-left: 10px;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);

    @include anim-custom(background-color, 100ms, ease);
  }

  &:hover::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar {
    position: absolute;
    left: -10px;
    width: 6px;
    background-color: rgba(0, 0, 0, 0);

    @include anim-custom(background-color, 100ms, ease);

    padding-right: 7px;
  }

  &:not(::-webkit-scrollbar) {
    border: 1px solid gray;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.1);

    @include anim-custom(background-color, 100ms, ease);
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

// Word Break class
.word-break {
  @include word-break;
}

.disabled {
  cursor: url(../../images/icons/icon-notallowed.svg), auto !important;
}

.mobile-scroll-x {
  @include respond-to(xsmall) {
    overflow-x: auto;
  }
}

// Animation based on angular ng-enter and ng-enter-active classes
// for ngRepeat, ngInclude, ngIf, ngView elements
.angular-reveal {

  &.ng-enter,
  &.ng-hide-remove {
    -webkit-transition: 0.7s linear all;
    transition: 0.7s linear all;
    opacity: 0.5;

    &.ng-enter-active,
    &.ng-hide-remove-active {
      opacity: 1;
    }
  }
}

.angular-zoomIn {

  &.ng-enter,
  &.ng-hide-remove {
    -webkit-transition: 0.3s ease-in-out all;
    transition: 0.3s ease-in-out all;
    opacity: 0.5;
    transform: scale(0, 0);

    &.ng-enter-active,
    &.ng-hide-remove-active {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
}
