.legal-page {
  &__subtitle {
    font-size: $font-size-md !important;
    padding: $spacing-base 0 0;
    font-weight: bold !important;
  }

  &__cookies-setup {
    position: relative;
  }

  &__cookies-textarea {
    width: 100%;
    height: 400px !important;
    padding: $spacing-base*2;
  }

  &__cookies-action {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  &__cookies-logo {
    margin-bottom: 25px;
  }

  &__cookies-link {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #fff;
    }

    .legal-page__subtitle + .label-radio {
      padding-top: 5px;
    }
  }
  
  .label-radio {
    display: block;
    padding-bottom: 5px;
  }
}

//mce-widget styles
.mce-panel {
  @include respond-to(xsmall) {
    max-width: 100% !important;
    width: 100% !important;

    .mce-container-body,
    .mce-window-body,
    .mce-form,
    .mce-foot {
      max-width: 100% !important;
      width: 100% !important;
    }

    .mce-foot {
      .mce-container-body {
        text-align: center;
      }

      .mce-btn {
        float: none !important;
      }
    }
  }

  //end

  .mce-window-head {
    padding: 15px;

    .mce-title {
      font-size: 16px;
    }

    .mce-close {
      top: 16px;
    }
  }

  textarea {
    left: 15px !important;
    right: 15px;
    width: auto !important;
  }

  .mce-foot {
    height: auto !important;

    .mce-container-body {
      width: 100% !important;
      height: auto !important;
      padding: 15px;
      box-sizing: border-box;
    }

    .mce-btn {
      position: static !important;
      float: right;
      width: auto !important;
      height: auto !important;
      border-radius: 3px;
      overflow: hidden;
      background-color: transparent !important;
      border-color: #666;
      color: #666;
      margin-left: $spacing-base;

      &.mce-primary {
        background-color: $na-secondary !important;
        border-color: $na-secondary;

        button span {
          color: #fff;
        }
      }

      button {
        text-transform: uppercase;
        font-weight: 700;
        padding: 13px 29px;
        font-size: 11px;
        line-height: 1em;
      }

      .mce-txt {
        font-size: 11px;
        font-weight: bold;
      }
    }
  }
}