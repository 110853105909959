//SuperAdmin
.dashboard--superadm {
  margin-top: 0;

  .dashboard__systems-list {
    .dashboard__system {
      overflow: hidden;

      //labels styling - inherit from label styling and adds to it
      .label {
        font-size: $font-size-xsm;
        text-transform: uppercase;
        border: 1px solid $na-primary;
        color: $na-primary;
        font-weight: 600;
        margin-bottom: $spacing-base*0.5;
        display: inline-block;
        line-height: 1.3;

        &--disabled {
          border-color: $gray;
          background: $gray;
          color: white;
        }

        &--new {
          border-color: $na-secondary;
          background: $na-secondary;
          color: white;
        }
      }

      &.disabled {
        background: $gray-lighter;
      }

      // add product box
      &.add-product {
        border: 1px dashed $gray-medium;
        cursor: default;

        .label {
          color: $gray-border;
          border: 1px dashed $gray-border;
        }

        .btn-secondary {
          padding: 13px 19px;
        }
      }

      //icon backgrounds
      &:after {
        font: var(--fa-font-solid);
        font-size: 110px;
        position: absolute;
        bottom: -$spacing-base;
        right: -$spacing-base;
        color: rgba($gray-medium, 0.08);
        z-index: -1;
      }

      //Icons
      &.ovs {
        &:after {
          content: '\f663';
        }
      }

      &.cms {
        &:after {
          content: '\f245';
        }
      }

      &.ibe {
        &:after {
          content: '\f085';
        }
      }

      &.gibe {
        &:after {
          font: var(--fa-font-regular);
          font-size: 110px;
          content: '\f0e8';
        }
      }

      &.paid {
        &:after {
          content: '\f155';
        }
      }

      &.trial {
        &:after {
          content: '\f34e';
        }
      }

      &.delete {
        &:after {
          content: '\f1f8';
        }
      }

      &.add-product {
        &:after {
          content: '\f067';
        }
      }
    }
  }

  //SuperAdmin overview
  .dashboard__overview .dashboard__system {
    padding-bottom: 0;

    &:after {
      // bottom: calc(153px - #{$spacing-base*0.5});
      top: 15px;
    }

    @supports (display: grid) {
      na-stats-overview {
        grid-template-rows: auto auto auto;
        grid-template-areas:
          "title title"
          "value value"
          "graph graph";
      }
    }

    na-stats-overview {
      .dashboard__system-name {
        font-size: $font-size-md+2;
        margin-bottom: $spacing-base*0.5;
      }

      .dashboard__system-data {
        align-items: flex-start;

        .dashboard__system-value {
          font-size: 28px;

          @include respond-to(xsmall) {
            font-size: 22px;
          }
        }

        + .dashboard__system-data {
          margin-top: $spacing-base;
          border-top: 1px solid $bg-alt;
          width: calc(100% + #{($spacing-base+5) * 2});
          left: -#{$spacing-base+5};
          position: relative;
          z-index: 1;
          background: white;
          max-height: 204px;
          grid-area: graph;
        }
      }
    }
  }
}