// Colors
$na-primary:				        	#307FE2;	// Net Affinity Primary
$na-primary-darker:			      	darken($na-primary, 6.5);	// Net Affinity Primary Darker
$na-secondary:				      	#8B00EF;
$na-secondary-darker:		      	darken($na-secondary, 6.5);	// Net Affinity secondary Darker
$na-tertiary:                 #393939;
$sys-color-1:				         	#8BC34A;	// CMS colour
$sys-color-2:				        	#307FE2;	// IBE colour
$sys-color-3:			        		#DB0000;	// CHM colour
$sys-color-4:			        		#AE4AC3;	// IBE/CHM colour

/* Colors */
$primary-color:			$na-primary;
$secondary-color:		$na-secondary;

// Typography
$font-family-sans-serif:  	'Segoe UI', helvetica, arial, sans-serif;
$font-icon-awesome:			    "Font Awesome 5 Pro";
$font-icon-material:		    "Material Design Icons";
$font-family-base:        	$font-family-sans-serif;

/* Design */
$border-radius:			           3px;
$border-radius-sm:		         2px;
$border-radius-lg:		         6px;
$spacing-base:			           10px;
$spacing-lg:			             40px;
$global-radius:                5px;

$font-size-base:          	14px;
$font-size-large:         	ceil(($font-size-base * 1.25)); //~17.5
$font-size-small:         	ceil(($font-size-base * 0.85)); //12
$font-size-h1:            	floor(($font-size-base * 1.7)); //24
$font-size-h2:            	floor(($font-size-base * 1.4)); //19
$font-size-h3:            	ceil(($font-size-base * 1));    //14
$font-size-h4:            	ceil(($font-size-base * 0.9));  //~12.6
$font-size-h5:            	$font-size-base;
$font-size-h6:            	ceil(($font-size-base * 1));
$font-weight-base:         	400;

$font-size:        14px;
$font-size-sm:     12px;
$font-size-xsm:    10px;
$font-size-md:     16px;

$bg-base:				        #fff;
$bg-alt:			        	#F8F9F8;
$container-width:		      926px;
$container-max-width:	    1320px;
$fixed-col-width:		      160px;
$fixed-col-width-sm:    	105px;
$arrows-width:		      	25px;
$common-height:		      	auto;
$days-height:			        36px;
$td-height:			        	36px;
$td-height-half:        	18px;
$td-width:			        	50px;
$padding-base:	      		$spacing-base;
$border-color:	    		#D8E4E0;
$border-color-darker:   	darken($border-color, 15%);

// Container Sidebar
$sidebar-background:         #fff;
$sidebar-heading-background: #fff;
$sidebar-small: 		         	 285px;
$sidebar-medium: 			         300px;
$sidebar-top:				           50px;
$sidebar-bottom:			         50px;
$sidebar-width-mobile:         270px;

$color-success:			         #68c635;
$color-alert:		           	 #f9e71d;
$color-danger:			         #db2b2b;


/* Status Colors */
$status-available:		       $color-success;
$status-closeout:		         $color-danger;
$status-alert:			         $color-alert;
$status-null:			         #efefef;

/* Band Colors */
$band-color-1:			#45A2FF;
$band-color-2:			#42bf00;
$band-color-3:			#b79f00;


/* Gray */
$gray-darker:			  #333;
$gray-darker1:      #515458;
$gray-dark:				  #666;
$gray:					    #ccc;
$gray-light:			  #e6e6e6;
$gray-lighter:			#f7f7f7;
$gray-medium:       #B2B2B2;
$gray-border:       #dcdcdc;
$gray-icon:         #AAA;
$gray-text:         #959A9F;

// Custom Colors
$body-bg:					  #fff;
$header-bg:					  $primary-color;
$text-color:				#666;
$text-color-dark:		#111;
$text-color-light:	#999;
$link-color:				  $primary-color;
$link-hover-color:	  darken($primary-color, 10%);
$border-color:		  #e5e5e5;
$border-color-dark:	#ccc;
$bg-hover:					  $gray-lighter;
$bg-alt:					  #f5f5f5;

//System message colors
$alert-info-bg-color:    #E3F3F8;
$alert-error-bg-color:   #F6DEDE;
$alert-warning-bg-color: #FDF1D3;

$info-color:             #44BFE6;
$error-color:            #D82343;
$warning-color:          #F6B82B;
$success-color:          #4D8C4C;

//Pastel Colours
$success-pastel:         #DAF4D9;
$warning-pastel:         #FFF9C5;
$danger-pastel:          #FFDCDC;

//Custom Easing
$easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
