#container__master {
  &.container-fluid--dashboard {
    #content .content-inner {
      max-width: unset !important;
    }
  }
}

div.dashboard__title,
div.dashboard__grid,
div.dashboard__recent {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}

.dashboard,
.add-system + na-company-dashboard-list {
  //difference padding top + height header
  margin: calc(40px + 35px) auto 0;
  display: block;

  .container {
    width: unset;
  }
}

.dashboard__loader {
  .loader-wrapper {
    left: 50%;
    top: 50%;
  }
}

.dashboard__menu {
  na-dd-menu {
    display: inline-block;
    font-size: 21px;
    width: $spacing-base*2;
    text-align: center;
    position: relative;
    line-height: 1;
  }
}

.dashboard__company-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: $spacing-base*3 0 $spacing-base*2;

  .dashboard__menu {
    position: absolute;
    right: 0;
  }
}

.dashboard__company-name {
  font-size: 18px;
  font-weight: 700;
  color: black;
  display: inline-block;
  margin: 0;
  padding: 0;
  max-width: calc(100% - 100px);

  .dashboard__company.disabled & {
    color: rgba(black, 0.5);
  }
}

.dashboard__company-icon {
  max-width: 42px;
  width: 42px;
  height: 42px;
  margin-right: $spacing-base;
}

.dashboard__systems-list {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + #{$spacing-base*2});
  margin-left: -$spacing-base;
  margin-right: -$spacing-base;

  @include respond-to(small) {
    flex-direction: column;
  }

  @include respond-to(xsmall) {
    margin: 0;
    width: 100%;
  }

  .view-all,
  .more-info {
    padding: $spacing-base*0.5 $spacing-base;
    border: 1px solid $bg-alt;
    border-radius: $border-radius;
    text-transform: capitalize;

    @include anim();

    &:hover {
      text-decoration: none;
      background: $bg-alt;
    }
  }
}

.label__group {
  flex: 1;
  margin-right: $spacing-base;
}

na-stats-comparable-overview,
na-stats-pie-chart,
na-stats-bar-chart,
.dashboard__recent .dashboard__system,
.dashboard--superadm .dashboard__system {
  width: calc(33.333% - #{$spacing-base*2});
  min-height: 170px;
  margin: 0 $spacing-base $spacing-base*2;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: column;
  position: relative;
  z-index: 0;
  padding: $spacing-base+5;
  color: $text-color;
  border: 1px solid $bg-alt;
  border-radius: $border-radius-lg;
  cursor: pointer;
  background: white;
  transition: 300ms ease;

  @include respond-to(xsmall) {
    width: 100%;
    padding: $spacing-base;
    margin: 0 0 $spacing-base;
  }

  .dashboard:not(.dashboard--system) & {
    @media (max-width: 1400px) {
      width: calc(50% - #{$spacing-base*2});
    }

    &:not(.disabled) {
      &:hover {
        @include fx-elevate(1px, 6px, 0.08);
      }
    }
  }

  @include respond-to(small) {
    width: calc(100% - #{$spacing-base*2});
  }
}

.dashboard__system-main {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  .dashboard__menu {
    na-dd-menu {
      right: -$spacing-base*0.25;
    }
  }

  &.dashboard__system-main--columns {
    .dashboard__system-name {
      flex: inherit;
    }

    .dashboard__system-data {
      width: auto;
    }
  }
}

.dashboard__system-chart {
  &.has-chart {
    cursor: pointer;
  }
}

.dashboard__system-name {
  padding: 0;
  margin: $spacing-base*0.5 0 $spacing-base+5 0;
  color: $text-color;
  font-size: $font-size-md;
  font-weight: 600;
  line-height: 1.35;
  flex: 1 0 100%;

  .dashboard__system.disabled & {
    color: rgba(black, 0.5);
  }
}

.dashboard__system-footer {
  font-size: $font-size-sm;

  > * {
    padding: 0;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: $spacing-base*0.5;
    }
  }
}

.dashboard__system-bookingsuite {
  width: 80px;
}

.add-system {
  background: $gray-lighter;
  height: $sidebar-top + 15;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 100;
  right: 0;
  top: $sidebar-top;

  @include anim(100ms, ease);

  @media (min-width: 769px) {
    left: $sidebar-medium;
  }

  @media (max-width: 769px) {
    width: 100%;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    max-width: $container-max-width;
    padding: 0 25px;
    color: $gray-darker;

    h2,
    p {
      margin: 0;
      padding: 0;
      font-size: $font-size-sm;
      display: inline-block;
    }

    h2 {
      font-size: $font-size;
      font-weight: 700;
      margin-right: $spacing-base;
      text-transform: uppercase;
    }

    @media (max-width: 950px) {
      span p {
        display: none;
      }
    }
  }

  .btn {
    i {
      margin-right: $spacing-base;
    }

    @media (max-width: 950px) {
      padding: $spacing-base;
    }
  }

  //animation changing the size when the second sidebar is open
  #content.content-narrow & {
    margin-right: $sidebar-small;

    @include anim(400ms, cubic-bezier(0.19, 1, 0.22, 1));
  }
}

.dashboard__grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  // z-index: 1;

  > * {
    flex: 1;
  }
}

.dashboard__header {
  @include NALogoGradient(180deg);

  margin: -35px -40px -120px;
  padding: 40px 40px 110px;
  position: relative;

  @include respond-to(xsmall) {
    padding: 20px 20px 80px;
    margin: 0 -15px -100px;
  }

  @include respond-to(small) {
    padding: 90px 40px 110px;
  }

  @media screen and (min-width: 460px) and (max-width: 920px) and (max-height: 520px) {
    padding: 20px 15px 80px;
    margin: 0 -15px -110px;
  }

  &.one-error {
    padding-top: 70px !important;

    @include respond-to(fromxsmall) {
      padding-top: 20px !important;
    }
  }

  // error execption
  // add padding top when error is visible

  body.route-dashboard.error & {
    padding-top: 100px;
  }

  .dropdown-actions {
    padding: $spacing-base*2;
  }
}

.dashboard__title {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: $text-color;
  margin: $spacing-base*4 0 $spacing-base*2;

  &-overview {
    display: inline-flex;
  }

  h2,
  h3 {
    margin: 0;
    padding: 0;
    font-size: $font-size-h2;
    color: inherit;
  }

  &.dashboard__welcome-message,
  .dashboard__overview &,
  .dashboard__progress & {
    color: white;
  }

  &.dashboard__welcome-message {
    margin-top: 0;
    margin-bottom: $spacing-base+5;
    align-items: center;
  }

  .new-booking-notification {
    color: $gray;
    font-size: 11px;
    transition: 200ms ease;
    cursor: pointer;

    i {
      color: $text-color;
      margin-left: $spacing-base*0.5;
      font-size: $font-size-sm;
    }

    &:hover {
      color: $text-color;
      text-decoration: none;
    }
  }
}

.dashboard__config {
  @extend .dashboard__select;

  .dropdown-select {
    &.open {
      .dropdown-custom {
        right: 0;
        border-radius: $global-radius;
      }
    }

    > a {
      display: flex;
      align-items: center;
    }
  }

  .multiselect li a {
    padding: 9px 30px 9px 12px !important;
  }

  .dropdown-actions__selected-dates {
    font-weight: bold;
  }

  @include respond-to(xsmall) {
    ob-daterangepicker .picker-dropdown-container .picker {
      position: absolute !important;
      top: 110px !important;
      transform: translateX(-50%) !important;

      .date-range {
        box-shadow: none !important;
        padding: 10px 20px;
      }
    }

    date-range-picker .calendar:first-child {
      margin: 0;
    }

    .dropdown > .dropdown-custom {
      min-width: 310px;

      .dropdown-body {
        min-width: 0;
        max-height: none;
      }
    }

    .dropdown-actions__selected-dates {
      grid-area: dates;
      text-align: center;
    }

    .btn-neutral-outline {
      grid-area: cancel;
    }

    .btn-primary {
      grid-area: apply;
    }

    .dropdown-actions {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 10px;
      grid-template-areas:
        "dates dates"
        "cancel apply";
    }
  }

  @include respond-to(small) {
    ob-daterangepicker .picker-dropdown-container .picker {
      text-align: center;
    }
  }

  @include respond-to(fromtablet) {
    .dropdown > .dropdown-custom {
      min-width: fit-content;

      .dropdown-body {
        display: flex;
        flex-direction: column;
        max-height: none;
      }
    }

    .multiselect {
      min-width: 300px;
    }

    ob-daterangepicker .picker-dropdown-container .picker {
      height: auto;
      position: static;
      border-radius: 0 !important;
      border-top: none;
      border-right: none;
      border-color: #f7f7f7;
    }

    date-range-picker {
      display: flex;
      align-items: center;
    }
  }

  @include respond-to(xlarge) {
    .dropdown > .dropdown-custom {
      .dropdown-body {
        flex-direction: row;
      }
    }
  }

  .checkbox {
    top: 0;
    font-size: $font-size-sm;

    .label-text {
      color: white;
      font-weight: 700;

      &:before {
        border: none;
        box-shadow: 0 0 $spacing-base rgba(0, 0, 0, 0.1);
      }

      &:after {
        left: 3px;
        font-weight: 500;
      }
    }
  }
}

.dashboard__select {
  padding-top: $spacing-base*0.5;

  .dropdown.dropdown-select {
    min-width: 200px;
    background: none;
    border: none;

    > a {
      border-radius: $border-radius;
      box-shadow: 0 0 $spacing-base rgba(0, 0, 0, 0.1);
      padding: $spacing-base $spacing-base*3 $spacing-base $spacing-base;
      color: $text-color-dark !important;

      &:before {
        font: var(--fa-font-light);
        content: '\f073';
        margin-right: $spacing-base;
        line-height: 0;
        font-size: $font-size-md;
        color: $text-color;
      }

      > span {
        top: $spacing-base;
        right: $spacing-base;
        color: $text-color;

        &:before {
          font: var(--fa-font-solid);
          content: '\f078';
        }
      }
    }
  }

  .open > .dropdown-menu {
    right: -1px;
    left: auto;
    border: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    .multiselect li a {
      background: white;

      &.selected-singleSelect {
        background: $na-primary;
        border: 0;
      }

      &:not(.selected-singleSelect) {
        &:hover {
          background: $bg-alt;
        }
      }
    }
  }
}

.dashboard__overview {
  .dashboard__systems-list {
    @media (max-width: 1400px) {
      width: inherit;
    }
  }

  na-stats-comparable-overview {
    min-height: 98px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
    border: 0;

    &:after {
      @extend .far;

      font-size: 90px;
      bottom: -$spacing-base*0.5;
      right: -$spacing-base*0.5;
    }

    .no-results {
      padding-bottom: 0;
    }
  }

  //grid overview on SYSTEMS dashboard (excludes the super admin main dashboard) for browsers that support GRID layouts
  .dashboard:not(.dashboard--superadm) & {
    .dashboard__systems-list.overview-section {
      @supports (display: grid) {
        width: 100%;
        display: grid;
        margin: 0;
        gap: 20px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 104px 104px;
        grid-template-areas:
          "rev bkg"
          "rev unt-sld"
          "rev unt";

        @include respond-to(small) {
          display: flex;
        }

        @include respond-to(xsmall) {
          grid-template-columns: 1fr;
          grid-template-rows: auto;
          gap: 10px;
          grid-template-areas:
            "rev"
            "bkg"
            "unt-sld"
            "unt";
        }

        > na-stats-comparable-overview {
          width: auto;
          height: auto;
          float: none;
          margin: 0;
          overflow: visible;
        }

        .revenue {
          grid-area: rev;

          .dashboard__system-name {
            font-size: $font-size-md+2;
          }
        }

        .bookings {
          grid-area: bkg;
        }

        .units-sold {
          grid-area: unt-sld;
        }

        .units {
          grid-area: unt;
        }
      }

      .dashboard__system-value {
        font-size: 28px;

        @include respond-to(xsmall) {
          font-size: 22px;
        }
      }
    }
  }
}

.no-results {
  padding: $spacing-base*3 $spacing-base;
  text-align: center;
  color: rgba($text-color, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @supports (display: grid) {
    grid-area: graph-start / graph-start / value-end / value-end;
  }

  &:before {
    font: var(--fa-font-solid);
    content: '\f695';
    display: block;
    margin-bottom: $spacing-base*0.5;
    font-size: 25px;
  }
}

.data__variation {
  font-size: $font-size-sm;
  font-weight: 500;
  margin-left: $spacing-base*0.5;
  line-height: 0;

  &.data-up {
    color: $success-color;
  }

  &.data-down {
    color: $error-color;
  }

  .dashboard__grid > div > div:not(.dashboard__overview) & {
    i {
      font-size: 10px;
      position: relative;
      top: -1px;
    }
  }

  .dashboard__overview & {
    font-size: $font-size-md;
    margin-left: $spacing-base;
  }
}

.container-fluid--dashboard {
  background: #FAFBFD;
  min-height: 100vh;
  position: relative;

  @include respond-to(xsmall) {
    padding-top: 65px;
  }
}

.dashboard--system {
  margin-top: 0;

  //cards on system dash
  na-stats-comparable-overview,
  na-stats-pie-chart,
  na-stats-bar-chart,
  .dashboard__recent .dashboard__system {
    cursor: default;
    position: relative;
    justify-content: flex-start;

    @media (min-width: 769px) {
      flex-grow: 1;
    }

    .dashboard__system-main {
      .dashboard__system-name {
        margin: 0;
      }
    }
  }

  .dashboard__system {
    .div-load {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.load {
        ~ * {
          display: none;
        }
      }
    }
  }
}

//Popular & Recent - Adjusting space
.dashboard__popular,
.dashboard__recent {
  max-width: 1320px;

  .dashboard__system {
    .view-all {
      position: absolute;
      right: $spacing-base + $spacing-base*0.5;
      top: $spacing-base;
      font-size: 11px;
      z-index: 1;
    }
  }
}

.dashboard__popular {
  .select-type {
    display: flex;
    align-items: center;

    .dashboard__select {
      margin-left: $spacing-base;

      .dropdown.dropdown-select {
        min-width: auto;
        width: 24px;
        height: 24px;

        > a {
          text-indent: -999px;
          padding: $spacing-base;
          box-shadow: none;

          &:hover {
            box-shadow: 0 0 $spacing-base rgba(0, 0, 0, 0.1);
          }

          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            text-indent: 0;
            width: 24px;
            height: 24px;
            font-size: 0.85em;
            top: 0;
            right: 0;
          }
        }
      }

      .open {
        > .dropdown-menu {
          right: unset;
        }
      }
    }
  }
}

@keyframes panel-entrance {
  0% {
    height: 0;
  }

  100% {
    height: auto;
    max-height: 300px;
    top: $spacing-base*3;
    padding-top: $spacing-base*2;
  }
}






