.modalTitle {
  & h2 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 1.4em;
  }

  & h4 {
    padding-top: 10px !important;
    padding-bottom: 0 !important;
    font-size: 1em;
  }
}

.header-title-modal {
  text-transform: capitalize;
  font-size: 15px;
  font-weight: bold;
}

.modalBody {
  padding: 24px 0;

  & .naForm {
    padding-top: 0;
    padding-bottom: 0;

    & input[type=checkbox] {
      display: inline-block;
      left: auto !important;
      top: -5px;
    }
  }
}

.modal--min-height {
  .modal-body {
    min-height: 300px;
  }
}

// START TRANSLATIONS MODAL 
.modal {
  .modal-body {
    p {
      word-break: break-word;
    }

    .naTabs {
      .translate-with-google {
        display: block;
        line-height: 2.6em;
        margin-bottom: 5px;
      }

      .tab-content {
        margin-top: -50px;
      }
    }
  }

  .modal-content {
    .translation-original-text-lang-name {
      font-weight: bold;
      line-height: 2em;
    }
  }

  //tabs

  form[name=naFormTranslation] {

    * {
      animation: none !important;
    }

    ul.nav.nav-tabs {
      height: 20px;
      overflow: hidden;
      width: 210px;

      &:hover {
        height: unset;
        width: 210px;

        li:not(.active):hover a {
          box-shadow: inset 0 0 10px #ccc;
        }
      }

      li {
        animation: none !important;

        &:not(.active) {
          margin: 0;
        }
      }
    }

    .tabsLong {
      ul.nav.nav-tabs {
        li:not(.active) {
          width: 50%;
          display: inline-block !important;
          margin: 0;
        }
      }
    }
  }
}

// Translation popup
.translations-ul {
  margin-top: 6px;

  .translation-li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

custom-compiled-field {
  .translation-col {
    margin-bottom: 30px !important;

    label {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 8px;

      .translate-with-google {
        flex: none;
        font-size: 12px;
        color: $text-color;
        text-decoration: underline;
      }
    }
  }
}

// Form Translation icon popup trigger

.NaForm {
  .translation-input {
    position: relative;
  }
}

.translation-original-text {
  height: auto;
  box-shadow: none;
}

.translation-original-block {
  position: sticky;
  top: 0;
}

.translation-selection {
  position: absolute;
  bottom: 100%;
  right: 0;

  .translation-globe {
    cursor: pointer;

    //add colour variables
    i {
      color: #DB2B19;
      font-size: 20px;
    }

    &.translation-done {
      i {
        color: #7DB159;
      }
    }

    &.translation-partial {
      i {
        color: #EAB84E;
      }
    }
  }
}

// END TRANSLATIONS MODAL 

// MODAL PAYMENT DETAILS
// MODAL NOTES
.na-modal__payment-details,
.na-modal__notes {
  .modal-body {
    @include resetListStyle;

    .row {
      width: 80%;
      margin: 0 auto !important;
      padding: 4px 0;

      h2 {
        margin-top: 10px;
      }

      b {
        font-weight: 600;
        font-size: 14px !important;
        color: #000;
      }

      .label {
        font-weight: 500;
        padding: 4px 4px !important;
        display: inline-block;
      }
    }

    hr {
      margin: 15px;
      border-color: #ccc;
    }
  }
}

.na-modal__notes {
  .row {
    width: 98% !important;

    .col-md-2 {
      padding-left: 0;
    }
  }
}

.na-modal__retargeting-email {
  .modal-body {
    padding: 0 2px;
  }

  iframe {
    min-height: 50vh;
  }

  .loader-wrapper {
    left: calc(50% - 12px);
    top: 50%;
  }
}

.na-modal__excluded-packages {
  .modal-body {
    padding-bottom: $spacing-base*4;
  }

  .package-row {
    margin-bottom: $spacing-base*3;
  }

  h5 {
    padding: 0;
  }

  .input-group {
    margin-bottom: 9px;

    .btn {
      font-weight: bold;
      text-transform: capitalize;
      min-width: 160px;
      border: none;
    }
  }
}

.na-modal__availability-confirmation {
  a {
    text-decoration: underline;
  }
}

.na-modal__terms-conditions {
  color: #959a9f;

  h3 {
    font-size: 16px;
  }

  .package-title {
    padding-bottom: 10px;
  }

  .tax-title {
    padding-bottom: 7px;
  }

  .tax-item {
    padding-bottom: 0;
  }

  .general-terms-title {
    margin: 15px 0 0;
  }
}
.na-cookieBot-modal_popup{
  overflow-y: auto;
  height: 360px;
}



