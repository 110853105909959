#btn-temp-menu {
  @include anim;

  position: fixed;
  bottom: 0;
  left: 0;
  display: block;
  width: 50px;
  height: 50px;
  z-index: 999999;
  opacity: 0.3;
  padding: 12px;
  text-align: center;

  i {
    @include anim;

    font-size: 22px;
  }

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &.active {
    opacity: 1;
    left: 250px;

    i {
      transform: rotate(180deg);
    }
  }
}

#temp-menu {
  @include anim;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0);
  font-size: 1.2em;

  .h-default {
    padding-bottom: 0;
  }

  #menu-inner {
    @include anim;

    opacity: 0;
    padding: 30px;
    position: absolute;
    top: 0;
    left: -300px;
    bottom: 0;
    width: 300px;
    border-right: $border-color 1px solid;
    background: #fff;
    -webkit-box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.1);

    ul {
      margin: 0 0 25px 0;

      li {
        margin: 10px 0;
        list-style: none !important;

        a {
          padding: 5px 0;
          display: block;
          line-height: 1em;

          @include anim;

          &:hover {
            padding-left: 10px;
            color: #000;
          }

          &:hover,
          &:focus,
          &:active {
            text-decoration: none;
          }
        }
      }
    }
  }

  .close {
    left: 300px;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 100;
    cursor: default;
  }

  &.active {
    display: block;
    pointer-events: auto;
    background: rgba(255, 255, 255, 0.8);

    #menu-inner {
      opacity: 1;
      left: 0;
    }
  }
}
