/* https://github.com/outbrain/pure-angular-date-range-picker */

calendar,
ob-daterangepicker {
  font-family: $font-family-sans-serif;
}

.ng-cloak,
.x-ng-cloak,
[data-ng-cloak],
[ng-cloak],
[ng\:cloak],
[x-ng-cloak] {
  display: none;
}

// styles for admin-side
body.admin {
  ob-daterangepicker {
    .picker-dropdown-container .picker .date-range {
      box-shadow: 0 0 29px $border-color-dark;
    }
  }

  na-voucher-edit {
    ob-daterangepicker {
      position: relative;
    }

    na-section[label="Availability"] {
      ob-daterangepicker {
        position: relative;
        z-index: 100;
      }
    }
  }

  @include respond-to(xsmall) {
    date-range-picker .calendar {
      display: block;
    }
  }

  @media (min-width: 768px) {
    date-range-picker {
      display: flex;
      align-items: center;
    }
  }
}

.ibe,
.ovs {
  ob-daterangepicker {
    .picker-dropdown-container {

      .picker {
        box-shadow: 0 0 29px $border-color-dark;

        @include respond-to(xsmall) {
          height: auto;
          white-space: normal;

          .date-range {
            text-align: center;
            padding: 10px;
          }

          .calendar + .calendar {
            margin: 10px 0 0;
          }
        }
      }
    }
  }
}

calendar {
  color: #565154;

  .input-container {
    position: relative;

    label {
      display: inline-block;
      color: #565154;
      font-size: 12px;
    }

    input {
      position: absolute;
      right: 0;
      padding-left: 5px;
      box-sizing: border-box;
      width: 185px;
      height: 25px;
      font-size: 12px;
      outline: 0;
      border: 1px solid $border-color-dark;
      border-radius: 2px;
    }
  }

  .header {
    position: relative;
    padding: 10px 0;

    @include theme-color;

    border-radius: 2px 2px 0 0;
    text-align: center;

    .date {
      white-space: nowrap;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
    }

    .arrow-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 28px;
      cursor: pointer;
      display: inline-block;

      &::before {
        font: var(--fa-font-regular);
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
      }
    }
  }

  .board {
    position: relative;
  }
}

ob-daypicker .picker-dropdown-container {
  position: relative;
}

calendar,
.calendar {
  .header .arrow-btn {
    &.left {
      left: 0;

      &::before {
        content: '\f104';
        // transform: translate(-7px, 7px);
      }
    }

    &.right {
      right: 0;

      &::before {
        content: '\f105';
        // transform: translate(8px, 7px);
      }
    }
  }

  .board {
    padding-top: 10px;
    white-space: nowrap;
    border-radius: 0 0 2px 2px;

    &.months-list,
    &.years-list {
      .months-list-item,
      .years-list-item {
        text-align: center;
      }
    }

    .cell,
    .day {
      font-size: 12px;
      text-align: center;
    }

    .cell {
      display: inline-block;
      height: 28px;
      width: 36px;
      line-height: 28px;
    }

    .days-of-week .day-name {
      display: inline-block;
      line-height: 28px;
      text-transform: uppercase;
      height: 30px;
      width: 36px;
    }

    .day {
      margin-left: -1px;
      margin-bottom: -1px;
      height: 33px;
      width: 37px;
      line-height: 30px;
      border: 1px solid transparent;
      display: inline-table;

      @include anim;

      cursor: pointer;

      &:not(.in-range):not(.other-month) {
        border: 1px solid $border-color-dark !important;
      }

      &.selected:not(.other-month):not(.disabled) {
        border: 1px solid $border-color-dark;
      }

      &.current:not(.other-month) {
        font-weight: 700;
      }

      &.other-month {
        color: #E2DFDE;
      }

      &.in-range:not(.other-month):not(.disabled),
      &.in-range-move:not(.other-month):not(.disabled) {
        background: #e9efed;
        color: #000;
        border-top: 1px solid $border-color-dark;
        border-bottom: 1px solid $border-color-dark;
      }

      &.range-start:not(.other-month):not(.disabled),
      &.range-end:not(.other-month):not(.disabled) {
        color: #FFF;

        @include theme-bg;
        @include theme-border;
      }

      &.disabled {
        color: #E2DFDE;
      }
    }
  }
}

date-range-picker .calendar {
  display: inline-block;

  &:first-child {
    margin-right: 10px;
  }
}

ob-daypicker {
  display: inline-block;
  overflow: visible;

  .picker-dropdown-container {
    &.disabled input {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.invalid input {
      border: 1px solid red;
    }

    .picker {
      position: absolute;
      top: 7px;
      z-index: 10;
    }

    input {
      box-sizing: border-box;
      height: 30px;
      width: 200px;
      line-height: 30px;
      border: 1px solid $border-color-dark;
      border-radius: 2px;
      padding-left: 10px;
      outline: 0;
      font-size: 14px;
    }
  }

  &.center .picker {
    right: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  &.right .picker {
    left: auto;
    right: 0;

    @include respond-to(xsmall) {
      right: 50%;
      transform: translateX(50%);
    }
  }
}

ob-daterangepicker {
  display: block;
  overflow: visible;
  cursor: pointer;
  font-size: 12px;

  .picker-dropdown-container {
    position: relative;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .picker-dropdown {
      box-sizing: border-box;
      position: relative;
      height: 35px;
      line-height: 30px;
      border: 1px solid $border-color-dark;
      border-radius: 2px;
      padding-left: 10px;
      font-size: 14px;
      padding-top: 3px;

      &.open {
        border-radius: 2px 2px 0 0;
      }
    }
  }

  .pickerDrop {
    content: '';
    position: relative;
    float: right;
    height: 0;
    font-size: 16px;
    display: inline-table;
    top: -#{$spacing-base};
    right: -#{$spacing-base + 2};
    width: auto;

    * {
      margin-left: 0 !important;
      line-height: 20px;
      padding: $spacing-base $spacing-base+2;
    }

    div[class*="calendar"] {
      background-color: $input-group-addon-bg;
      border-left: 1px solid $input-group-addon-border-color;
    }
  }

  .glyphicon {
    padding-right: 6px;
  }

  .picker-dropdown-container .picker {
    box-sizing: border-box;
    position: absolute;
    white-space: nowrap;
    overflow: visible;
    right: 0;
    top: 29px;
    z-index: 10;
    background: #fff;
    border: 1px solid $border-color-dark;
    border-radius: 2px;

    &.open {
      border-radius: 2px 0 2px 2px;

      .date-range {
        padding: 20px;
        display: inline-block;
      }
    }

    .ranges-actions {
      display: inline-block;
      vertical-align: top;
      height: 100%;
      width: 150px;
      box-sizing: border-box;
      padding: 10px;
      font-size: 12px;

      .ranges .range {
        height: 24px;
        line-height: 24px;
        border-radius: 2px;
        padding: 0 5px;
        margin-bottom: 5px;

        &.selected,
        &:hover:not(.disabled) {
          cursor: pointer;
          background: #428bca;
          color: #FFF;
        }

        &.disabled {
          background: #CCC;
          opacity: 0.7;
        }
      }

      .actions {
        width: 150px;
        padding: 0 3px;
        margin-top: 10px;
        position: absolute;
        bottom: 10px;

        .drp_btn {
          height: 27px;
          line-height: 27px;
          cursor: pointer;
          width: 60px;
          display: inline-block;
          text-align: center;

          &.cancel {
            text-decoration: underline;
            color: #428bca;
          }

          &.apply {
            background: #428bca;
            color: #FFF;
            border-radius: 2px;
          }
        }
      }

      &.custom-open {
        border-left: 1px solid $border-color-dark;
      }
    }

    @include respond-to(xsmall) {
      position: fixed !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  &.center .picker {
    right: auto;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  &.right .picker {
    right: auto;
    left: 0;

    @include respond-to(xsmall) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.up {
    .picker-dropdown.open {
      border-radius: 0 0 2px 2px;
    }

    .picker {
      top: 1px;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);

      &.open {
        border-radius: 2px 2px 0;
      }
    }

    &.center .picker {
      -webkit-transform: translate(-50%, -100%);
      transform: translate(-50%, -100%);
    }

    &.right .picker.open {
      border-radius: 2px 2px 2px 0;
    }
  }
}

// Picker
ob-daterangepicker,
na-daterange-picker {
  .picker-dropdown {
    height: auto !important;
    //same as input
    padding: $spacing-base $spacing-base+2 !important;
    line-height: 20px !important;
    border-radius: 2px;
  }

  .pickerDrop {
    * {
      display: inline-block;
      margin-left: 7px;

      @include anim;

      &:hover {
        @include theme-color;
      }
    }
  }
}
