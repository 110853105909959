$health-success: #00B713;
$health-warning: #FC0;
$health-danger: #F60000;

$circle-size: 50px;
$circle-percentage: 75%;
$stroke-width: 4px;
$stroke-color: #00B713;

@mixin loading-state {
  background-color: #f6f7f8 !important;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) !important;
  background-repeat: no-repeat !important;
  background-size: 800px 104px !important;
  display: inline-block;
  position: relative;
  pointer-events: none;
  animation: 1s placeholderShimmer linear infinite forwards;
  // animation-duration: 1s;
  // animation-fill-mode: forwards;
  // animation-iteration-count: infinite;
  // animation-name: placeholderShimmer;
  // animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

na-health-monitor-template {
  .panel {
    border-radius: 20px !important;

    &.panel-header {
      border: 1px solid #ddd;
      border-radius: 20px !important;
      padding: 20px;
    }

    &.health-monitor--header {
      max-width: 768px;
      display: flex;
      align-items: center;

      @include respond-to(xsmall) {
        flex-wrap: wrap;

        & > * {
          margin: 15px 0;
        }
      }

      span {
        display: block;
        color: #959A9F;
        font-size: 13px;
        margin-bottom: 2px;
      }

      .health-monitor--chart {
        flex: 0 1 65px;
        max-height: $circle-size;

        // Donut chart
        &__circle {
          width: $circle-size;
          height: $circle-size;
          display: block;
          border-radius: 50%;
          position: relative;
          overflow: hidden;

          .health-monitor--chart__inner {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            display: block;
            background: #efefef;
            position: absolute;
            top: $stroke-width;
            bottom: $stroke-width;
            left: $stroke-width;
            right: $stroke-width;
            font-size: 16px;
            font-weight: bold;
            color: #000;
            text-align: center;
            line-height: 36px;
            border: 3px solid #fff;
            opacity: 1;
            transition: opacity 2s linear;

            &.chart-success {
              background: $success-pastel;
            }

            &.chart-warning {
              background: $warning-pastel;
            }

            &.chart-danger {
              background: $danger-pastel;
            }

            span {
              opacity: 1;
              color: #000;
            }
          }
        }
      }

      .health-monitor--overall {
        flex: 2 2 auto;
      }

      .health-monitor--checked {
        flex: 0 1 auto;
        padding-right: 20px;
        min-width: 180px;

        h2 {
          display: block;
        }

        &-sceleton {
          display: none;
        }
      }

      .health-monitor--runcheck {
        flex: 0 1 50px;
        position: relative;

        button {
          width: 123px;
          height: 39px;
          transition: linear 0.3s;

          b {
            @include anim();

            font-weight: 700 !important;
          }
        }

        i {
          font-size: 26px;
          position: absolute;
          top: 8px;
          left: 51px;
          color: #ccc;
          opacity: 0;
          pointer-events: none;
        }
      }

      &.loading {
        .tooltip {
          display: none !important;
        }

        .health-monitor--chart__circle {
          @include loading-state;
        }

        .health-monitor--chart__inner {
          @include loading-state;

          span {
            opacity: 0;
          }
        }

        .health-monitor--checked {
          span {
            margin: 0;
          }

          h2 {
            display: none;
          }

          &-sceleton {
            @include loading-state;

            width: 150px;
            height: 20px;
          }
        }

        .health-monitor--runcheck {
          button {
            cursor: default;

            b {
              opacity: 0;
            }
          }

          i {
            opacity: 1;
          }
        }
      }
    }
  }
}

.health-monitor--list {
  max-width: 768px;

  .health-monitor--list__item {
    border-bottom: #efefef 1px solid;
    display: flex;
    align-items: center;
    padding: 20px 0;

    .health-monitor--list__badge {
      flex: 0 1 auto;
      transition: linear 0.3s;

      &.loading {
        .fancy-label {
          @include loading-state;

          i,
          span {
            opacity: 0;
          }
        }
      }

      .fancy-label {
        width: 122px;
        height: 39px;

        i,
        span {
          transition: linear 0.3s;
          opacity: 1;
        }
      }
    }

    .health-monitor--list__title {
      flex: 1 1 auto;
      padding-left: 18px;
    }

    .health-monitor--list__fix {
      flex: 0 1 100px;
      text-align: right;

      .btn {
        text-transform: none;
        border: #ddd 1px solid;
        font-size: 16px;
        font-weight: 600;
        padding: 10px 12px !important;
        color: #000;
        transition: linear 0.3s;

        &:hover {
          color: #000;
          border: 1px solid #000 !important;
          background: #fff !important;
        }

        i {
          font-size: 14px;
          color: #000;
          margin-right: 8px;
        }
      }
    }
  }

  &.ready {

    .health-monitor--list__item {
      .fancy-label {
        opacity: 1;
      }

      &:nth-of-type(1) {

        .fancy-label span,
        .fancy-label i {
          transition-delay: 0ms;
        }
      }

      &:nth-of-type(2) {

        .fancy-label span,
        .fancy-label i {
          transition-delay: 70ms;
        }
      }

      &:nth-of-type(3) {

        .fancy-label span,
        .fancy-label i {
          transition-delay: 140ms;
        }
      }

      &:nth-of-type(4) {

        .fancy-label span,
        .fancy-label i {
          transition-delay: 210ms;
        }
      }

      &:nth-of-type(5) {

        .fancy-label span,
        .fancy-label i {
          transition-delay: 280ms;
        }
      }
    }
  }
}

.health-breakdown {
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 25px;
  width: 290px;
  background: #fff;

  .health-breakdown--list {
    .health-breakdown--list__item {
      display: flex;
      border-bottom: 1px solid #efefef;
      padding: 8px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border: 0;
      }

      .health-breakdown--title {
        flex: 3 3 auto;
        align-items: center;
        text-align: left;

        i {
          font-size: 8px;
          margin-right: 8px;
          position: relative;
          top: -2px;
        }

        h3 {
          display: inline-block;
        }
      }

      .health-breakdown--score {
        text-align: right;
        flex: 1 1 auto;
        color: #999;

        b {
          color: #000;
        }
      }

      &.status-success {
        i {
          color: $health-success;
        }
      }

      &.status-warning {
        i {
          color: $health-warning;
        }
      }

      &.status-danger {
        i {
          color: $health-danger;
        }
      }
    }
  }
}
