na-selected-floating-rate {
  .grid-header-cells {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-width: 1px;
    padding: 0 $spacing-base;

    .grid-control {

      .col-md-9,
      na-labeled-dropdown na-field-component > div .dropdown.dropdown-select > a {
        padding: 0;
      }

      na-labeled-dropdown na-field-component > div .dropdown.dropdown-select > a {
        > span {
          transform: none;
          right: 0;
        }
      }

      na-labeled-dropdown:first-child {
        label {
          text-transform: capitalize;
        }
      }
    }
  }
}

//accordion
na-rates-template na-section-collection {
  na-section {
    display: block;
    margin: $spacing-base 0 0 0;
    color: $gray-900;

    .na-section__title {
      display: flex;
      align-items: center;
      padding: 0 0 0 $spacing-base;
      height: $cell-height;
      background: $gray-0;
      border: 1px solid $gray-300;

      &:before {
        font: var(--fa-font-light);
        content: '\f068';
        font-size: $font-size-sm;
        margin-right: $spacing-base;
      }

      h2 {
        margin: 0;
        padding: 0;
        line-height: 1.5;
        font-size: $font-size;
        color: inherit;
        font-weight: 400;
      }

      &.collapsed {
        cursor: pointer;

        &:before {
          content: '\f067';
        }
      }
    }

    .na-section__content {
      padding: 0 $spacing-base $spacing-base;
      border: 1px solid $gray-300;
      border-top: 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;

      > div {
        margin-top: $spacing-base;
        -ms-flex: 0 0 30%;
        flex: 0 0 calc(33.33% - 10px);

        &:nth-child(3n+2) {
          margin: $spacing-base $spacing-base+5 0;
        }
      }
    }
  }
}
