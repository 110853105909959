.extend_date_form {
  &__calendar {
    position: relative;
    max-width: 285px;
  }

  .far {
    padding: $spacing-base 12px;
    line-height: $spacing-base*2;
    color: #000;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    &.fa-times {
      right: $spacing-base*4;
      cursor: pointer;
    }

    &.fa-calendar {
      pointer-events: none;
    }
  }

  .fa-calendar {
    background-color: $gray-lighter;
    border: 1px solid $gray;
  }

  .modal-body {
    padding: $spacing-base*2 15px;
  }
}
