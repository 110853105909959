.grid {
  width: 100%;
  height: 70vh;

  na-horizontal-grid & {
    height: auto;
    min-height: 70vh;
    margin-bottom: $spacing-base*4;
  }

  .to-date {
    padding: $spacing-base;
  }
}

.strike-through {
  text-decoration: line-through;
}


.ui-grid .dropdown-menu {
  position: fixed;
  margin: 22px;
  top: auto;
  left: 0;
  padding: 0;
}

.grid-loading {
  position: absolute;
  top: 50px;
  left: 48%;
  width: 50%;
  height: 50%;
  text-align: center;
  font-size: 1.5em;
}

.grid-not-information {
  position: absolute;
  top: 50px;
  height: auto;

  span {
    font-size: 1.5em;
  }
}

na-grid-data-icon.selected {
  color: royalblue;
}

.cursor {
  cursor: pointer;
}