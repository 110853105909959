.accommodation-page {
	.grid-control {
		margin-bottom: 10px;

		na-labeled-dropdown {
			@include respond-to(xsmall) {
				display: block;
				width: 100%;
			}

			label.col-md-3 {
				width: 130px;

				@include respond-to(xsmall) {
					width: auto;
					flex: 1;
				}
			}

			.col-md-9 {
				width: 360px;

				@include respond-to(xsmall) {
					width: auto;
				}
			}
		}
	}
}
