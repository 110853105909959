// .ui-grid-sortarrow {
//   fill: $sortArrowBackgroundColor;
//   stroke: $sortArrowBorderColor;
//   stroke-linejoin:miter;
// }

// .ui-grid-sortarrow.down {
//   -webkit-transform: rotate(180deg);
//   -moz-transform: rotate(180deg);
//   -ms-transform: rotate(180deg);
//   -o-transform: rotate(180deg);
//   transform: rotate(180deg);
// }

$sortArrowWidth: 20px;

.ui-grid-sortarrow {
  right: 5px;
  position: absolute;
  width: $sortArrowWidth;
  top: 0;
  bottom: 0;
  background-position: center;

  &.down {
    @include transform(rotate(180deg));
  }
}
