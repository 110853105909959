.naTabs {
  &.tabs-collapse {
    position: relative;
    padding-top: 50px;

    .nav.nav-tabs {
      position: absolute;
      width: fit-content;
      margin-left: auto;
      border: 0;
      top: 0;
      right: 0;
      z-index: 1;
      padding-top: 35px;

      .tab-collapse.nav-item {
        float: none;

        a {
          padding: 10px 50px 10px 15px;
          border: 0;
          color: $gray-dark;
          font-weight: normal;
          text-transform: none;
          font-size: 13px;
          line-height: 1.5;
          margin: 0;
        }
      }

      .tab-collapse.nav-item.active {
        position: absolute;
        top: 0;
        right: 0;
        height: 32px;
        width: 100%;

        a {
          border: 1px solid #ccc !important;
          border-radius: 3px;
          font-size: 1em;
          padding: 5px 40px 5px 10px;
        }

        &:after {
          content: "\e252";
          display: inline-block;
          font-family: 'Glyphicons Halflings';
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          font-size: 0.8rem;
          right: 10px;
          top: 12px;
          position: absolute;
        }
      }

      .tab-collapse.nav-item:not(.active) {
        position: relative;
        visibility: hidden;
        opacity: 0;
        background: $bg-alt;
        transition: all 0.3s linear;

        a {
          border: 0;

          &:hover {
            border: 0;
            color: $na-primary-darker;
          }
        }
      }

      &:hover {
        li.tab-collapse:not(.active) {
          display: flex;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .nav.nav-tabs {
    margin-bottom: $spacing-lg;

    .nav-item {
      border: 0;

      a {
        border-radius: 0;
        border: 0;
        border-top: 2px solid transparent;
        line-height: 2em;
        font-size: $font-size-base;
        padding: 15px 25px;
        color: #999;

        @include anim;

        &:hover {
          background: transparent;
          color: #000;
          border-top: 2px solid $gray-light;
        }

        @media (max-width: 1028px) {
          padding: 15px;
        }

        @include respond-to(xsmall) {
          padding: $spacing-base*0.5 9px;
        }
      }

      &.active {
        a {
          @include theme-border-top;

          color: #000;
          pointer-events: none;
          font-weight: 600;
        }
      }

      //Tabs Notification
      &.tab-notification {
        &:after {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          position: absolute;
          left: 50%;
          border-radius: 50%;
          bottom: -3px;
          margin-left: -3px;
          outline: 4px solid #fff;

          @include anim-reveal('zoomIn', 500ms);
        }

        &.tab-notification-error {
          &:after {
            background-color: $color-danger;
          }
        }

        &.tab-notification-alert {
          &:after {
            background-color: $color-alert;
          }
        }

        &.tab-notification-success {
          &:after {
            background-color: $color-success;
            z-index: 10;
          }
        }
      }
    }
  }

  .na-section__title {
    h3 {
      font-size: $font-size-base + 1;
      margin-top: 0;
    }
  }
}
