.field-message {
  position: relative;
  text-align: left;
  padding: 5px 0;
  color: $brand-danger;
  font-size: 13px;

  ul {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.password-validation {
  @extend .field-message;

  @include anim;

  color: $text-color;
  border: 1px solid $gray;
  background-color: #fff;
  padding: 10px !important;
  width: 272px;
  position: absolute;
  top: 0;
  left: -295px;
  font-size: 12px;
  opacity: 0;

  @include arrow-right(7px, 34px, #bbb, #fff);

  &.open {
    opacity: 1;
    left: -285px;
  }

  ul {
    li {
      color: $brand-danger;

      i {
        width: 20px;
        display: inline-block;

        &.mdi-check {
          display: none;
        }

        &.mdi-close {
          width: 23px;
        }
      }
    }
  }

  .passed {
    color: $brand-success;

    .mdi-close {
      display: none;
    }

    .mdi-check {
      display: inline-block;
    }
  }
}
