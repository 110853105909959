//
// Dropdown menus
// --------------------------------------------------

// Dropdown arrow/caret
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: $caret-width-base dashed;
  border-top: $caret-width-base solid \9; // IE8
  border-right: $caret-width-base solid transparent;
  border-left: $caret-width-base solid transparent;
}

// The dropdown wrapper (div)
.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle {
  transition: 0.3s;
  cursor: pointer;
}

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus {
  outline: 0;
}

span.dropdown {
  //display: inline-block;
  position: relative;
}

.dropdown-select {
  border-radius: $border-radius-sm;
  text-align: left;
  // margin: 0;
  display: block;
  border: 1px solid $gray;
  cursor: pointer;

  > a {
    @include noselect;

    padding: 14px 26px 14px 13px;
    display: block;
    background-color: #fff;
    font-size: 1em;
    color: $text-color !important;
    line-height: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: inherit;

    &:hover {
      text-decoration: none;
    }

    span {
      position: absolute;
      right: 13px;
      top: 15px;
      font-size: 0.8em;
    }
  }

  .list-filter &,
  .list-table & {
    margin: -5px 0;
    display: inline-block;
    border: 1px solid $gray;

    > a {
      padding: 6px 26px 6px 8px;
      font-size: 0.8em;

      span {
        top: 8px;
        right: 9px;
        font-size: 0.7em;
      }
    }

    > .dropdown-custom {
      transform: none;
      left: 0;

      @include respond-to(fromxsmall) {
        right: 0;
        left: auto;
      }
    }
  }

  &.disabled {
    a {
      background-color: #efefef;
    }
  }

  a.select-null.fa-times {
    float: right;
    padding-top: 13px;
    padding-right: 35px;
  }

  .dropdown-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__selected-dates {
      color: black;
      padding-right: 10px;
    }

    .btn + .btn {
      margin-left: 5px;
    }
  }
}

.dropdown-custom {
  padding: 0;
  margin-top: 10px;
  // width: 100%;
  min-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > .arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    left: 50%;
    margin-left: -$popover-arrow-outer-width;
    border-width: $popover-arrow-outer-width;
    border-top-width: 0;
    border-bottom-color: $popover-arrow-outer-fallback-color; // IE8 fallback
    border-bottom-color: $popover-arrow-outer-color;
    top: -$popover-arrow-outer-width;

    &:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: $popover-arrow-width;
      content: "";
      top: 1px;
      margin-left: -$popover-arrow-width;
      border-top-width: 0;
      border-bottom-color: $popover-arrow-color;
    }
  }
  
  .dropdown-search {
    position: relative;
    border-bottom: 1px solid $gray-light;

    input {
      padding: 10px 12px !important;
      font-size: 0.9em !important;
      height: auto !important;
      border-radius: 6px 6px 0 0;
      border: 0;
      border-bottom: 0;
    }

    span.btn-filterclear {
      position: absolute;
      top: 2px;
      right: 5px;
      opacity: 0.9;
      cursor: pointer;
      padding: 5px;
      display: block;
      font-size: 18px;

      i {
        color: $gray !important;
      }
    }
  }

  .dropdown-header {
    margin: 0; // reset heading margin
    padding: 8px 12px;
    background-color: $popover-title-bg;
    border-bottom: 1px solid $gray-light;
    border-radius: ($border-radius-large - 1) ($border-radius-large - 1) 0 0;
    font-weight: 700;
    font-size: 10px;
    text-transform: uppercase;

    @include theme-color;
  }

  .dropdown-footer {
    border-top: 1px solid $gray-light;

    &.select-shortcut {
      display: block;
      padding: $spacing-base;
      text-transform: uppercase !important;
      font-size: 10px;

      a {
        font-size: 10px;
        margin-left: 11px;
        font-weight: 600;

        &,
        &:focus,
        &:hover,
        &:active {
          text-decoration: none;
        }

        i {
          font-size: 12px;
          vertical-align: middle;
          margin-right: 3px;
          margin-top: -2px;
        }
      }
    }
  }
}

// Dropdown Scroll
.dropdown-scroll {
  .dropdown-body {
    max-height: 300px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;

    @include respond-to(xsmall) {
      min-width: 195px;
      max-height: 200px;
    }
  }
}

// The dropdown menu (ul)
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 25000 !important;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  padding: 0;
  margin: 2px 0 0; // override default ul
  list-style: none;
  font-size: $font-size-base;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  background-color: $dropdown-bg;
  border: 1px  solid  $dropdown-fallback-border; // IE8 fallback
  border: 1px  solid  $dropdown-border;
  border-radius: $border-radius-base;
  box-shadow: 0 0 16px  #ccc;

  &.dropdown-positioned-mobile {
    @include respond-to(xsmall) {
      left: 15px !important;
      margin: 20px;
    }
  }

  // Aligns the dropdown menu to right
  //
  // Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
  &.pull-right {
    right: 0;
    left: auto;
  }

  // Dividers (basically an hr) within the dropdown
  .divider {
    @include nav-divider($dropdown-divider-bg);
  }

  // Links within the dropdown menu
  > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: $line-height-base;
    color: $dropdown-link-color;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    text-transform: none;
  }

  // Input
  > input[type=text] {
    padding: 10px 12px;
    outline: none;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid $border-color;
  }

  input {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
  }

  // Picker
  ob-daterangepicker,
  na-daterange-picker {
    .picker-dropdown {
      width: 280px;
      border: 0;
    }
  }

  // From - To
  &.grid-from-to {
    padding: 12px;

    @include respond-to(xsmall) {
      right: 15px !important;
    }

    input {
      padding: 5px;
      width: 120px;
      border: 1px solid $border-color;
      border-radius: 2px;

      &:focus {
        outline: none;
        border: 1px solid transparent;

        @include theme-border;
      }
    }

    > div:first-child {
      display: block;
      padding-bottom: 10px;
    }

    span {
      width: 42px;
      display: inline-block;
    }
  }
}

// Hover/Focus state
.dropdown-menu > li > a {
  &:hover,
  &:focus {
    text-decoration: none;
    color: $dropdown-link-hover-color;
    background-color: $dropdown-link-hover-bg;
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: $dropdown-link-active-color;
    text-decoration: none;
    outline: 0;
    background-color: $dropdown-link-active-bg;
  }
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray

.dropdown-menu > .disabled > a {
  &,
  &:hover,
  &:focus {
    color: $dropdown-link-disabled-color;
  }

  // Nuke hover/focus effects
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none; // Remove CSS gradient

    @include reset-filter;

    cursor: $cursor-disabled;
  }
}

// Open state for the dropdown
.open {

  // Show the menu
  > .dropdown-menu {
    display: block;
  }

  // Remove the outline when :focus is triggered
  > a {
    outline: 0;
  }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
  left: auto; // Reset the default from `.dropdown-menu`
  right: 0;
}

// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
  left: 0;
  right: auto;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: $font-size-small;
  line-height: $line-height-base;
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: ($zindex-dropdown - 10);
}

// Right aligned dropdowns
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
//
// Just add .dropup after the standard .dropdown class and you're set, bro.
// TODO: abstract this so that the navbar fixed styles are not placed here?

.dropup,
.navbar-fixed-bottom .dropdown {

  // Reverse the caret
  .caret {
    border-top: 0;
    border-bottom: $caret-width-base dashed;
    border-bottom: $caret-width-base solid \9; // IE8
    content: "";
  }

  // Different positioning for bottom up menu
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
}

// Component alignment
//
// Reiterate per navbar.less and the modified component alignment there.

@media (min-width: $grid-float-breakpoint) {
  .navbar-right {
    .dropdown-menu {
      right: 0;
      left: auto;
    }

    // Necessary for overrides of the default right aligned menu.
    // Will remove come v4 in all likelihood.
    .dropdown-menu-left {
      left: 0;
      right: auto;
    }
  }
}

//Fix the width in update rate bulk
.dropdown-rate-bulk {
  width: auto !important;
  min-width: 0 !important;
}
