#system-select {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9998;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);

  @include anim;

  &.ng-hide {
    background-color: rgba(0, 0, 0, 0);

    #system-select__search {
      transform: translateY(-100px);
      opacity: 0;
    }

    .system-select {
      i {
        transform: rotate(0deg);
      }
    }

    .back-dashboard {
      transform: translateX(-100px);
      opacity: 0;
    }
  }

  .back-dashboard {
    font-size: 13px;

    @include anim;

    transform: translateX(0);
    padding: 15px 24px 15px 14px;
    position: fixed;
    top: 15px;
    background: #000;
    border-radius: $border-radius;
    left: $sidebar-small + (20px);
    color: #ccc;

    @include respond-to(fromxsmall) {
      left: 275px;
      font-size: 12px;
      padding: $spacing-base $spacing-base $spacing-base 15px;
    }

    @media (min-width: 1280px) {
      left: $sidebar-medium + (20px);
    }

    i {
      font-size: 12px;
      vertical-align: bottom;
      line-height: 19px;
      margin-right: $spacing-base;

      @include respond-to(fromxsmall) {
        position: absolute;
        left: 5px;
        font-size: 10px;
        line-height: 16px;
      }
    }

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
      opacity: 1;
      color: #fff !important;
    }
  }

  .system-select {
    @include anim;

    &:after {
      content: '';
      width: 60px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 100;
      pointer-events: none;
    }

    a {
      color: #000;

      &:hover {
        text-decoration: none;
      }
    }

    i {
      @include anim;

      transform: rotate(45deg);
    }
  }
}

#system-select__search {
  height: 94.5%;
  border-radius: 0 0 5px 0;
  transform: translateY(0);
  opacity: 1;

  @include anim-custom(all, 500ms, cubic-bezier(0.23, 1, 0.32, 1));
  @include scrollbar;

  background-color: #fff;
  max-height: calc(100% - #{$sidebar-top}) !important;
  overflow-y: auto;
  top: $sidebar-top;
  position: fixed;
  width: $sidebar-medium;

  @include respond-to(fromxsmall) {
    width: $sidebar-width-mobile;
  }

  > a {
    position: absolute;
    top: 2px;
    right: 0;
    padding: 10px;
    font-size: 15px;
    cursor: pointer;
    transform: translateY(0);
    color: #000 !important;
    opacity: 0.5;

    @include anim;

    &.active {
      transform: translateY(35px);
    }

    &:hover {
      opacity: 1;
    }

    &.deleted-filter {
      margin-right: 30px;
    }
  }

  .system-select__input {
    background-color: $bg-alt;
    border: 0;
    width: 100%;
    padding: 13px;

    &:focus {
      outline: none;
      color: #000;
    }
  }
}

#system-select__nossystem {
  position: relative;
  display: block;
  text-align: center;
  padding: 13px 13px 0 0;

  div {
    width: 100%;
    border-radius: 5px;
    padding: 25px 0 40px;
  }

  i {
    display: block;
    font-size: 38px;
    opacity: 0.3;
  }

  h3 {
    padding: 0;
    font-size: 16px;
  }
}

#system-select__filter {
  @include anim;

  height: 0;
  overflow: hidden;
  display: block;
  padding: 0 15px;
  border: 0;
  z-index: 10;
  position: relative;
  background-color: #fff;
  margin-bottom: -1px;

  a {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    padding: 14px 13px;
    color: $text-color !important;
    cursor: pointer;
  }

  .typeSystem {
    padding: 0 !important;
    margin-right: $spacing-base;
    display: inline-block;
  }

  a,
  label {
    @include anim;

    transform: translate(0, -35px);
    opacity: 0;
  }

  &.active {
    height: 47px;

    a {
      transform: translate(0, 0);
      opacity: 1;
    }

    label {
      transform: translate(0, 0);
      opacity: 1;

      &:nth-child(2) {
        transition-delay: 100ms;
      }

      &:nth-child(4) {
        transition-delay: 120ms;
      }

      &:nth-child(6) {
        transition-delay: 140ms;
      }

      &:nth-child(8) {
        transition-delay: 160ms;
      }
    }
  }

  input[type=checkbox] {
    display: none;
  }

  label {
    font-size: 12px;
    text-transform: uppercase;
    border: 1px solid #ccc;
    line-height: 1em;
    padding: 3px 4px 4px 4px;
    border-radius: 3px;
    cursor: pointer;

    @include anim;

    margin-right: 3px;
    margin-top: 13px;
  }

  input[type=checkbox]:checked + label {
    border: 1px solid $text-color;
    background-color: $text-color;
    color: #fff;
  }
}

#system-select__list {
  > li {
    h3 {
      border-top: 1px solid $border-color !important;
      margin: 0;
      padding: 13px 15px;
      font-weight: bold;
      position: relative;
      z-index: 5;
    }

    ul {
      display: block;
      margin-top: -5px;
      margin-bottom: 10px;

      li {
        display: block;

        a {
          padding: 8px 15px;
          display: block;

          @include anim;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #666;

          &:focus,
          &:active {
            text-decoration: none;
          }

          &:hover {
            background-color: $bg-alt;
            text-decoration: none;
            color: $na-tertiary;
          }
        }
      }
    }
  }
}

.system-select {
  background-color: white !important;
  padding: 0;
  height: $sidebar-top;
  font-weight: 300;
  font-size: 15px;
  text-transform: uppercase;
  transition: background 0.3s 0s ease;
  position: absolute;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: $sidebar-medium;
  border-right: 1px solid rgb(220, 220, 220);

  @include respond-to(fromxsmall) {
    width: $sidebar-width-mobile;
  }

  //swich icon
  &:after {
    content: '';
    width: 60px;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    pointer-events: none;
  }

  .system-select-button {
    @include anim(300ms, ease);

    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 0;
    height: $sidebar-top;
    border-bottom: 1px solid $gray-border;

    &:hover {
      text-decoration: none;

      i {
        transform: translateY(5px);
      }
    }

    &:active,
    &:focus {
      i {
        transform: rotate(180deg);
      }
    }
  }

  h2.title {
    color: black;
    padding: 5px 0;
    background: transparent;
    margin: 0 0 0 10px;
    font-size: $font-size-base;
    font-weight: 500;
    width: calc(100% - 90px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: initial;

    &.title-default {
      text-transform: uppercase;
    }
  }

  i {
    transform: translateY(0);

    @include anim;

    font-size: 16px;
    padding: 16px 10px;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 120;
    pointer-events: none;
    color: #111;
  }
}

#close_menu {
  background-color: white !important;
}
