// COLORPICKER START

.colorpicker-visible,
.colorpicker-visible .dropdown-menu {
  display: block !important;
}

colorpicker-saturation {
  display: block;
  width: 100px;
  height: 100px;
  background-image: url(../../images/colorpicker-icons/saturation.png);
  background-size: contain;
  cursor: crosshair;
  float: left;
}

colorpicker-saturation i {
  display: block;
  height: 7px;
  width: 7px;
  border: 1px solid #000;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  margin: -4px 0 0 -4px;
}

colorpicker-saturation i::after {
  content: '';
  display: block;
  height: 7px;
  width: 7px;
  border: 1px solid #fff;
  border-radius: 5px;
}

colorpicker-alpha,
colorpicker-hue {
  width: 15px;
  height: 100px;
  float: left;
  cursor: row-resize;
  margin-left: 4px;
  margin-bottom: 4px;
}

colorpicker-alpha i,
colorpicker-hue i {
  display: block;
  height: 2px;
  background: #000;
  border-top: 1px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: -1px;
}

.colorpicker,
colorpicker-alpha {
  display: none;
}

colorpicker-hue {
  background-image: url(../../images/colorpicker-icons/hue.png);
  background-size: contain;
}

.colorpicker-color,
colorpicker-alpha {
  background-image: url(../../images/colorpicker-icons/alpha.png);
  background-size: 10px 100%;
}

.colorpicker {
  top: 0;
  left: 0;
  z-index: 99999;
}

.colorpicker colorpicker-alpha,
.colorpicker colorpicker-hue,
.colorpicker colorpicker-saturation {
  position: relative;
}

.colorpicker input {
  width: 100px;
  font-size: 11px;
  color: #000;
  background-color: #fff;
}

.colorpicker.alpha {
  min-width: 140px;
}

.colorpicker.alpha colorpicker-alpha {
  display: block;
}

.colorpicker.dropdown {
  position: absolute;
}

.colorpicker.colorpicker-fixed-position {
  position: fixed;
}

.colorpicker .dropdown-menu::after,
.colorpicker .dropdown-menu::before {
  content: '';
  display: inline-block;
  position: absolute;
}

.colorpicker .dropdown-menu::after {
  clear: both;
  border: 6px solid transparent;
  top: -5px;
  left: 7px;
}

.colorpicker .dropdown-menu::before {
  border: 7px solid transparent;
  top: -6px;
  left: 6px;
}

.colorpicker .dropdown-menu {
  position: static;
  top: 0;
  left: 0;
  min-width: 129px;
  padding: 4px;
  margin-top: 0;
}

.colorpicker-position-top .dropdown-menu::after {
  border-top: 6px solid #fff;
  border-bottom: 0;
  top: auto;
  bottom: -5px;
}

.colorpicker-position-top .dropdown-menu::before {
  border-top: 7px solid rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  top: auto;
  bottom: -6px;
}

.colorpicker-position-right .dropdown-menu::after {
  border-right: 6px solid #fff;
  border-left: 0;
  top: 11px;
  left: -5px;
}

.colorpicker-position-right .dropdown-menu::before {
  border-right: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 0;
  top: 10px;
  left: -6px;
}

.colorpicker-position-bottom .dropdown-menu::after {
  border-bottom: 6px solid #fff;
  border-top: 0;
}

.colorpicker-position-bottom .dropdown-menu::before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-top: 0;
}

.colorpicker-position-left .dropdown-menu::after {
  border-left: 6px solid #fff;
  border-right: 0;
  top: 11px;
  left: auto;
  right: -5px;
}

.colorpicker-position-left .dropdown-menu::before {
  border-left: 7px solid rgba(0, 0, 0, 0.2);
  border-right: 0;
  top: 10px;
  left: auto;
  right: -6px;
}

colorpicker-preview {
  display: block;
  height: 10px;
  margin: 5px 0 3px;
  clear: both;
  background-position: 0 100%;
}

// COLORPICKER END
